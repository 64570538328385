import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { scurl } from '../../core/utils.js';

import {
  Row,
  Col,
} from 'reactstrap';

import isScreen from '../../core/screenHelper';
import Loading from '../../components/Loading';

import s from './Shows.module.scss';

class Shows extends React.Component {
  
  static propTypes = {
    toggleShows: PropTypes.func.isRequired,
    q: PropTypes.string,
  };

  static defaultProps = {
    q: '' 
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      columns: [],
      results: []
    };

    this.getQueryShows = this.getQueryShows.bind(this);
    this.getTopShows = this.getTopShows.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.q && (prevProps.q !== this.props.q)) {

      console.log('SHOWS COMPONENT');
      
      if(this.props.q.length > 2) {
        this.getQueryShows(this.props.q);
      } else {
        this.setState({
          isLoaded: true,
          results: []
        });
      }
    }
  }

  componentDidMount() {
    this.getTopShows();
  }

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
    this.props.toggleShows();
  }

  getQueryShows(q) {

    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/products?q=' + q,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            results: results
          });
        }
      }.bind(this));
    }); 
  }

  getTopShows() {

    this.setState({
      isLoaded: false,
      results: []
    }, function() { 

      var opts = {
        path: '/v2/products?broadway=1&active=1',
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            results: results
          }, function() {
            
            var cols = [
              {
                column: 0,
                products: []
              },
              {
                column: 1,
                products: []
              },
              {
                column: 2,
                products: []
              }
            ];
  
            var col = 0;
  
            for(var product of this.state.results.products) {
  
              cols[col].products.push(product);
  
              if(isScreen('lg') || isScreen('xl')) {
                if(col === 2) {
                  col = 0;
                } else {
                  col += 1;
                }
              } else {
                if(isScreen('md')) {
                  if(col === 1) {
                    col = 0;
                  } else {
                    col += 1;
                  }
                } else {
                  col = 0;
                }
              }
            }
  
            this.setState({
              isLoaded: true,
              columns: cols
            });
          });
        }
      }.bind(this));
    }); 

  }

  render() {
    return (
      <section className={`${s.shows} card navbar-shows`}>

        <Loading loading={!this.state.isLoaded} pad={10} />

        <div className="gutter-top-fixed-2 gutter-right-fixed-1 gutter-left-fixed-1">
          
          {this.props && this.props.q && this.props.q.length > 2 ? (

            this.state.results && this.state.results.products && this.state.results.products.length > 0 ? (
              <div>
                {this.state.results.products.map((product, i) =>
                  <div key={i}>
                    <a href="/" onClick={((e) => this.selectProduct(e, product.id))}>
                      <h6>{product.short_name || product.name}</h6>
                    </a>
                    {product.venue ? (
                      <p className="fs-mini text-muted">{product.venue.name}</p>
                    ) : null}
                    <hr/>
                  </div>
                )}
              </div>
            ) : (
              <p>No results</p>
            )

          ) : (

            this.state.columns && this.state.columns.length > 0 ? (

              <Row>

                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                  {this.state.columns[0].products.map((product, i) =>
                    <div key={i}>
                      <a href="/" onClick={((e) => this.selectProduct(e, product.id))}>
                        <h6>{product.short_name || product.name}</h6>
                      </a>
                      <hr/>
                    </div>
                   )}
                </Col>

                {this.state.columns[1].products.length > 0 ? (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    {this.state.columns[1].products.map((product, i) =>
                      <div key={i}>
                        <a href="/" onClick={((e) => this.selectProduct(e, product.id))}>
                        <h6>{product.short_name || product.name}</h6>
                        </a>
                        <hr/>
                      </div>
                    )}
                  </Col>
                ) : null}

                {this.state.columns[2].products.length > 0 ? (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    {this.state.columns[2].products.map((product, i) =>
                      <div key={i}>
                        <a href="/" onClick={((e) => this.selectProduct(e, product.id))}>
                        <h6>{product.short_name || product.name}</h6>
                        </a>
                        <hr/>
                      </div>
                    )}
                  </Col>
                ) : null}

              </Row>

             
            ) : null
          )}
          
        </div>
      </section>
    );
  }

}

export default withRouter(connect()(Shows));
