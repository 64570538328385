import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import { formatNumber, surl  } from '../../../../core/utils';

import { Row, Col, Button } from 'reactstrap';

import isScreen from '../../../../core/screenHelper';

import ProgressBar from '../../../../components/Progress';
import Displays from '../../../../components/Displays';

import Slider from 'rc-slider';

class WidgetBwTrendWeekCard extends React.Component {

  static propTypes = {
    week: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    trends: PropTypes.bool
  };

  static defaultProps = {
    print: false,
    width: 880,
    height: 150,
    chartAspectWidth: 2,
    chartAspectHeight: 1,
    trends: false
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    
    var show_count = 32;
    var show_cols = [6,6,6,6,6];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        show_count = 20
        show_cols = [12,12,12,12,12];
      } else {
        if(isScreen('md')) {
          show_count = 6
          show_cols = [4,4,6,4,4];
        }
      }
    }

    this.state = {
      show_count: show_count,
      show_cols: show_cols,
      actuals: this.props.week.actuals,
      print: this.props.print,
      expand: false,
      isLoaded: false,
      variance: this.props.week.variance
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.calculateTotals();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggle = () => { 

    //const section = document.querySelector('#block_' + this.props.week.weekending);
    //section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    this.setState({
      expand: !this.state.expand
    });
  }

  toggleDetail = () => { 

    const section = document.querySelector('#block_' + this.props.week.weekending);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    this.setState({
      expand: !this.state.expand
    });
  }

  qtyChange = (v) => {
    this.setState({
      variance: {
        ...this.state.variance,
        qty: parseFloat(v/100)
      }
    }, function() {
      this.calculateTotals();
    });
  };

  avgFaceChange = (v) => {
    this.setState({
      variance: {
        ...this.state.variance,
        avg_face: parseFloat(v/100)
      }
    }, function() {
      this.calculateTotals();
    });
  };

  marginChange = (v) => {
    this.setState({
      variance: {
        ...this.state.variance,
        margin: parseFloat(v/100)
      }
    }, function() {
      this.calculateTotals();
    });
  };

  affiliateChange = (v) => {
    this.setState({
      variance: {
        ...this.state.variance,
        affiliate: parseFloat(v/100)
      }
    }, function() {
      this.calculateTotals();
    });
  };

  adsalesChange = (v) => {
    this.setState({
      variance: {
        ...this.state.variance,
        adsales: parseFloat(v/100)
      }
    }, function() {
      this.calculateTotals();
    });
  };

  marketingChange = (v) => {
    this.setState({
      variance: {
        ...this.state.variance,
        marketing: parseFloat(v/100)
      }
    }, function() {
      this.calculateTotals();
    });
  };

  calculateTotals = () => {

    let qty = parseInt(this.state.variance.qty * this.props.week.deferred.qty.py);
    let avg_face = parseFloat(this.state.variance.avg_face * this.props.week.deferred.avg_face.py);
    let margin = parseFloat(this.state.variance.margin * this.props.week.deferred.margin.py);
    let access = parseFloat(this.state.variance.access * this.props.week.deferred.access.py);
    let affiliate = parseFloat(this.state.variance.affiliate * this.props.week.deferred.affiliate.py);
    let cc_fees = parseFloat(this.state.variance.cc_fees * this.props.week.deferred.cc_fees.py);

    let totals_face = parseFloat(qty*avg_face);
    let totals_fees = parseFloat(totals_face * margin);
    let totals_access = parseFloat(qty*access);
    let totals_cc_fees = parseFloat((totals_face + totals_fees) * cc_fees);
    let totals_affiliate = parseFloat(qty*affiliate);

    let totals_net = parseFloat(totals_fees - totals_access - totals_affiliate - totals_cc_fees);
    
    let marketing = parseInt(this.state.variance.marketing * this.props.week.deferred.marketing.py);
    let adsales = parseInt(this.state.variance.adsales * this.props.week.deferred.adsales.py);

    this.setState({
      ...this.state,
      forecast: {
        qty: qty,
        avg_face: avg_face,
        margin: margin,
        access: access,
        affiliate: affiliate,
        cc_fees: cc_fees,
        totals_net: totals_net,
        net: this.props.week.pacing.ticketing.forecast.net,
        marketing: marketing,
        adsales: adsales,
      },
      isLoaded: true
    });
  };

  save = () => {
    
    let payload = {
      forecast_date: this.props.week.forecast_date,
      weekending: this.props.week.weekending,
      variance: this.state.variance
    };

    var url = '/v2/opsis/variances/update';

    let postUrl = surl({
      path: url,
      type: 'simple'
    });

    fetch(postUrl,  {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',},
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(updatedWeek => {
      console.log('Updated Week:', updatedWeek);
      this.setState({
        expand: !this.state.expand
      });
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  render() {

    return (

      <div className="w-100">
        {this.state.isLoaded ? (
          <>
            <Row className={this.state.expand ? 'w-100 mt-3 mb-3 bg-dark text-white' : 'w-100 mt-3'} id={'block_' + this.props.week.weekending}>
              <Col xs={2} sm={2} md={2} lg={2} xl={2} className={this.state.expand ? 'mb-3' : ''}>
                <h6 className="pt-1 text-muted">WEEKENDING</h6>
                <h5 className={'text-capitalize ' + (this.state.expand ? '' : null)}>
                  {DateTime.fromISO(this.props.week.weekending).toFormat('DD').toUpperCase()}
                </h5>
                <ProgressBar completed={parseInt( ((this.props.week.pacing.lead_averages.qty+this.props.week.pacing.lead_averages.face)/2) * 100)} bgcolor="#339FFF" height={20} width="100%" />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                <h5 className="pt-3">FORECAST</h5>
                {/* <p>${formatNumber('X,', this.state.forecast.net)}</p> */}
                <h6>${formatNumber('X,', this.state.forecast.totals_net)}</h6>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
                <h5 className="pt-3">PROJECTED</h5>
                <h6 className="mb-0 pb-0">${formatNumber('X,', this.props.week.pacing.ticketing.projected.net)}&nbsp;&nbsp;<span className="fs-mini text-muted"><Displays a={this.props.week.pacing.ticketing.projected.net} b={this.state.forecast.totals_net} format="percent" /></span></h6>
                
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2} className="mt-4 text-right">
                <Button className="btn btn-default btn-xs inline-block text-inline gutter-left-fixed-2" onClick={() => this.toggle()}>
                  <i className={'fa-light ' + (this.state.expand ? 'fa-minus' : 'fa-pen')} />&nbsp;&nbsp;EDIT
                </Button>
              </Col>
            </Row>

            {this.state.expand ? (
              <>
                <Row className="w-100 mt-4 pt-4 pb-4 bg-light" id={'block_' + this.props.week.weekending}>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                    <h6 className="fw-bold">QUANTITY</h6>
                    <Row className="bg-white p-2 ml-2 mr-2">
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PY</h6>
                        <p>{formatNumber('X,', this.props.week.deferred.qty.py)}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PACE</h6>
                        <p className="mb-0 pb-0">{formatNumber('X,', this.props.week.pacing.ticketing.projected.qty)}</p>
                        <p className="mt-0 pt-2 text-muted fs-mini">{formatNumber('X.X%', ((this.props.week.pacing.ticketing.projected.qty-this.props.week.deferred.qty.py)/this.props.week.deferred.qty.py))}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">FORECAST</h6>
                        <p className="mb-0 pb-0">{formatNumber('X,', this.state.forecast.qty)}</p>
                        <p className="mt-0 pt-2 fs-mini fw-bold">{formatNumber('X.XX%', (this.state.variance.qty-1))}</p>
                      </Col>
                    </Row> 
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                    <h6 className="fw-bold">AVG PRICE</h6>
                    <Row className="bg-white p-2 ml-2 mr-2">
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PY</h6>
                        <p>{formatNumber('$X.XX', this.props.week.deferred.avg_face.py)}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PACE</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X.XX', this.props.week.pacing.ticketing.projected.avg_price)}</p>
                        <p className="mt-0 pt-2 text-muted fs-mini">{formatNumber('X.X%', ((this.props.week.pacing.ticketing.projected.avg_price-this.props.week.deferred.avg_face.py)/this.props.week.deferred.avg_face.py))}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">FORECAST</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X.XX', this.state.forecast.avg_face)}</p>
                        <p className="mt-0 pt-2 fs-mini fw-bold">{formatNumber('X.XX%', (this.state.variance.avg_face-1))}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                    <h6 className="fw-bold">MARGIN</h6>
                    <Row className="bg-white p-2 ml-2 mr-2">
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PY</h6>
                        <p>{formatNumber('X.X%', this.props.week.deferred.margin.py)}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PACE</h6>
                        <p className="mb-0 pb-0">{formatNumber('X.X%', this.props.week.pacing.ticketing.projected.margin)}</p>
                        <p className="mt-0 pt-2 text-muted fs-mini">{formatNumber('X.X%', ((this.props.week.pacing.ticketing.projected.margin-this.props.week.deferred.margin.py)/this.props.week.deferred.margin.py))}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">FORECAST</h6>
                        <p className="mb-0 pb-0">{formatNumber('X.X%', this.state.forecast.margin)}</p>
                        <p className="mt-0 pt-2 fs-mini fw-bold">{formatNumber('X.XX%', (this.state.variance.margin-1))}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                    <h6 className="fw-bold">AFFILIATE</h6>
                    <Row className="bg-white p-2 ml-2 mr-2">
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PY</h6>
                        <p>{formatNumber('$X.XX', this.props.week.deferred.affiliate.py)}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PACE</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X.XX', parseFloat(this.props.week.pacing.ticketing.projected.affiliate/this.props.week.pacing.ticketing.projected.qty))}</p>
                        <p className="mt-0 pt-2 text-muted fs-mini">{formatNumber('X.X%', ((parseFloat(this.props.week.pacing.ticketing.projected.affiliate/this.props.week.pacing.ticketing.projected.qty)-this.props.week.deferred.affiliate.py)/this.props.week.deferred.affiliate.py))}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">FORECAST</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X.XX', this.state.forecast.affiliate)}</p>
                        <p className="mt-0 pt-2 fs-mini fw-bold">{formatNumber('X.XX%', (this.state.variance.affiliate-1))}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                    <h6 className="fw-bold">SEARCH</h6>
                    <Row className="bg-white p-2 ml-2 mr-2">
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PY</h6>
                        <p>{formatNumber('$X', this.props.week.deferred.marketing.py)}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PACE</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X', parseFloat(this.props.week.pacing.static.kenshoo.projected))}</p>
                        <p className="mt-0 pt-2 text-muted fs-mini">{formatNumber('X.X%', ((this.props.week.pacing.static.kenshoo.projected-this.props.week.deferred.marketing.py)/this.props.week.deferred.marketing.py))}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">FORECAST</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X', this.state.forecast.marketing)}</p>
                        <p className="mt-0 pt-2 fs-mini fw-bold">{formatNumber('X.XX%', (this.state.variance.marketing-1))}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                    <h6 className="fw-bold">AD SALES</h6>
                    <Row className="bg-white p-2 ml-2 mr-2">
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PY</h6>
                        <p>{formatNumber('$X', this.props.week.deferred.adsales.py)}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">PACE</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X', parseFloat(this.props.week.pacing.static.adsales.projected))}</p>
                        <p className="mt-0 pt-2 text-muted fs-mini">{formatNumber('X.X%', ((this.props.week.pacing.static.adsales.projected-this.props.week.deferred.adsales.py)/this.props.week.deferred.adsales.py))}</p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <h6 className="text-muted">FORECAST</h6>
                        <p className="mb-0 pb-0">{formatNumber('$X', this.state.forecast.adsales)}</p>
                        <p className="mt-0 pt-2 fs-mini fw-bold">{formatNumber('X.XX%', (this.state.variance.adsales-1))}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            
                <Row className="w-100 mt-0 pt-0 pb-0 bg-light">
                  <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Slider
                      defaultValue={this.props.week.variance.qty*100}
                      min={70}
                      max={130}
                      onChange={this.qtyChange}
                      step={0.25}
                    />
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Slider
                      defaultValue={this.props.week.variance.avg_face*100}
                      min={70}
                      max={130}
                      onChange={this.avgFaceChange}
                      step={0.25}
                    />
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Slider
                      defaultValue={this.props.week.variance.margin*100}
                      min={70}
                      max={130}
                      onChange={this.marginChange}
                      step={0.25}
                    />
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Slider
                      defaultValue={this.props.week.variance.affiliate*100}
                      min={30}
                      max={100}
                      onChange={this.affiliateChange}
                      step={0.25}
                    />
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Slider
                      defaultValue={this.props.week.variance.marketing*100}
                      min={80}
                      max={200}
                      onChange={this.marketingChange}
                      step={0.25}
                    />
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Slider
                      defaultValue={this.props.week.variance.adsales*100}
                      min={70}
                      max={130}
                      onChange={this.adsalesChange}
                      step={0.25}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                    <div className="gutter-top-fixed-2 gutter-bottom-fixed-1">
                      <Button className="btn-success" onClick={() => this.save()}>
                        <i className="fa-light fa-save" /> &nbsp;Update Week
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}

            <hr className="mt-4 pt-0" />
          </>
        ) : null}
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwTrendWeekCard));
