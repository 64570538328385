import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class WidgetChartLegend extends React.Component {

  static propTypes = {
    years: PropTypes.number,
    lookback: PropTypes.number,
    print: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    lookback: 0,
    years: 1,
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      print: this.props.print
    }

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    return (
      <div className={this.props.className}>
        <p className="fs-mini text-muted">
          <span className="fw-bold gutter-right-fixed-2">Chart Legend:</span> 
          {this.props.lookback > 0 ? (
            <span><span style={{ background: '#999'}} className="gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>Prior {this.props.lookback} days</span>
          ): null}
          <span><span style={{ background: '#82ca9d'}} className="gutter-left-fixed-2 gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>Current Year</span>
          {this.props.years > 1 ? (
            <span><span style={{ background: '#0087a9'}} className="gutter-left-fixed-2 gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>1yr Ago</span>
          ) : null}
          {this.props.years > 2 ? (
            <span><span style={{ background: '#ffc647'}} className="gutter-left-fixed-2 gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>2yrs Ago</span>
          ) : null}
          {this.props.years > 3 ? (
            <span><span style={{ background: '#955196'}} className="gutter-left-fixed-2 gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>3yrs Year</span>
          ) : null}
          {this.props.years > 4 ? (
            <span><span style={{ background: '#dd5182'}} className="gutter-left-fixed-2 gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>4yrs Ago</span>
          ) : null}
          {this.props.years > 5 ? (
            <span><span style={{ background: '#ff6e54'}} className="gutter-left-fixed-2 gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>5yrs Ago</span>
          ) : null}
          {this.props.years > 6 ? (
            <span><span style={{ background: '#ffa600'}} className="gutter-left-fixed-2 gutter-right-fixed-1 pl-2 pr-1">&nbsp;</span>6yrs Ago</span>
          ) : null}
        </p>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetChartLegend));
