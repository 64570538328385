import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { ResponsiveContainer, Line, LineChart, Label, XAxis, YAxis, ReferenceLine, Tooltip, Legend} from 'recharts';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated, nFormatter, changeSalesMetric, displaySalesMetric } from '../../../core/utils';

class WidgetBwYears extends React.Component {

  static propTypes = {
    engagement: PropTypes.object,
    performance: PropTypes.bool,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    chart: PropTypes.bool,
    chartType: PropTypes.string,
    fixed_years: PropTypes.number,
    fees: PropTypes.bool,
    timespan: PropTypes.string,
    title: PropTypes.string,
    chart_metric: PropTypes.string,
    perf_date: PropTypes.bool,
    refunds: PropTypes.number
  };

  static defaultProps = {
    engagement: null,
    performance: false,
    chartAspectWidth: 6,
    chartAspectHeight: 1,
    print: false,
    chart: true,
    width: 900,
    height: 650,
    chartType: 'line',
    fixed_years: 6,
    timespan: 'weeks',
    fees: true,
    title: 'Transaction Date Sales',
    chart_metric: 'face',
    perf_date: false,
    refunds: -1
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      chart_metric: this.props.chart_metric,
      chart_timespan: this.props.timespan,
      refunds: this.props.refunds,
      table: {
        data: [],
        columns: [],
        styles: {}
      }
    }

    this._isMounted = false;
    this.loadSales = this.loadSales.bind(this);
    this.setChartData = this.setChartData.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.areaLabelFormat = this.areaLabelFormat.bind(this);
    this.changeChartMetric = this.changeChartMetric.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'YEARS WIDGET')) {
      this.loadSales();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSales() {
    
    console.log(this.props.filter);

    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/transactions/timespans',
        type: 'universal',
        rollup: 1
      };

      if(this.props.engagement) {
        opts.show_code = this.props.engagement.show_codes[0];
      }

      opts.timespans = 1;
      opts.years = this.props.fixed_years;
      opts.perf_date = this.props.perf_date;
      if(this.state.refunds > -1) {
        opts.refunds = this.state.refunds;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            }, function() {
              this.setChartData();
              console.log(this.props.print)
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));

    });
  
  }

  setChartData() {
    var chartData = [];
        
    console.log(this.state.results.timespans);

    if(this.state.results && this.state.results.timespans && this.state.results.timespans[this.state.chart_timespan] && this.state.results.timespans[this.state.chart_timespan].length > 0) {
      this.state.results.timespans[this.state.chart_timespan].forEach(function(block, i) {
        
        var d = {
          name: block.dates.end
        };

        block.years.forEach(function(year, y) {
          if(year[this.state.chart_metric] < 0) {
            d['y'+y] = 0;
          } else {
            d['y'+y] = year[this.state.chart_metric];
          }
        }.bind(this));

        chartData.push(d);
       
      }.bind(this));

      var reflines = [];

      var min = 0;
      var max = 1.1;

      this.setState({
        chart: {
          data: chartData,
          reflines: reflines,
          properties: {
            max: max,
            min: min
          }
        }
      }, function() {
        //console.log(this.state.chart)
      });
    }
  }

  salesSummaryDataFormat(x) {
    if(this.props.chart_metric === 'qty') {
      return nFormatter(x);
    } else {
      return '$' + nFormatter(x);
    }
  };

  salesSummaryDateFormat(x) {
    if(this.state.chart_timespan === 'days') {
      return moment(x).format('M/DD');
    } else {
      if(this.state.chart_timespan === 'weeks') {
        return moment(x).format('M/DD');
      } else {
        return moment(x).format('MMM');
      }
    }
  };

  areaLabelFormat(x) {
    return x
  };

  changeChartMetric = () => { 
    var change_display = changeSalesMetric(this.state.chart_metric);
    this.setState({
      chart_metric: change_display
    }, function() {
      this.setChartData();
    });
  };

  render() {

    var title = <h5><span className="text-danger">{this.props.title}</span> <small className="text-muted">Compares <button className="header-link" onClick={() => this.changeChartMetric()}>{displaySalesMetric(this.state.chart_metric)}</button> by week for previous 5 years.
    {this.props.filter && this.props.filter.limit && this.props.filter.limit.engagements.length === 1 ? (
      <span> Limited to <strong><u>{this.props.filter.limit.engagements[0].show}</u></strong>.</span>
    ) : null}</small></h5>;

    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} pad={10} />

          {this.state.isLoaded && this.state.chart && this.state.chart.data ? (
            <>

              {this.props.print ? (
                <>
                  {this.props.chart && this.state.chart.data.length > 1 ? (
                    <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                      <defs>
                        <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                        </linearGradient>
                        <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                        </linearGradient>
                      </defs>
                      <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                      <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[dataMin => this.state.chart.properties.min, dataMax => (dataMax * this.state.chart.properties.max)]} />
                      {this.state.chart.reflines.length > 0 ? (
                        this.state.chart.reflines.map((refline, i) =>
                          <ReferenceLine key={i} x={refline.x} isFront={true} stroke="lightgray">
                            <Label value={refline.label} offset={refline.offset} position="top" fill="black" style={{fontSize: '0.8rem' }} />
                          </ReferenceLine>
                        )
                      ) : null }

                      <Line name="Current Year" type="monotone" dataKey="y0" stroke="#82ca9d" strokeWidth={2} />
                      {this.props.fixed_years >= 2 ? (
                      <Line name="1 YR Ago" type="monotone" dataKey="y1" stroke="#777" dot={{ stroke: '#ccc', strokeWidth: 1 }} />
                      ) : null }
                      {this.props.fixed_years >= 3 ? (
                      <Line name="2 YRS Ago" type="monotone" dataKey="y2" stroke="#0087a9" dot={{ stroke: '#0087a9', strokeWidth: 1 }} />
                      ) : null }
                      {this.props.fixed_years >= 4 ? (
                      <Line name="3 YRS AGO" type="monotone" dataKey="y3" stroke="#ffc647" dot={{ stroke: '#ffc647', strokeWidth: 1 }} />
                      ) : null }
                      {this.props.fixed_years >= 5 ? (
                        <Line name="4 YRS Ago" type="monotone" dataKey="y4" stroke="#754c7f" dot={{ stroke: '#754c7f', strokeWidth: 1 }} />
                      ) : null }
                      {this.props.fixed_years >= 6 ? (
                        <Line name="5 YRS Ago" type="monotone" dataKey="y5" stroke="#db3920" dot={{ stroke: '#db3920', strokeWidth: 1 }} />
                      ) : null }


                      <Legend verticalAlign="top" height={36} align="right" iconType="square" />
        
                    </LineChart>
                  ) : null}
                </>
              ) : (
                <ResponsiveContainer width='100%' aspect={this.props.chartAspectWidth/this.props.chartAspectHeight} >
                  <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                    <defs>
                      <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                      </linearGradient>
                      <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                      </linearGradient>
                    </defs>
                    <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                    <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[this.state.chart.properties.min, dataMax => (dataMax * this.state.chart.properties.max)]} />
                    <Tooltip />
                    {this.state.chart.reflines.length > 0 ? (
                      <ReferenceLine x={this.state.chart.reflines[0].x} stroke="gray" label={this.state.chart.reflines[0].label}/>
                    ) : null }
                    {this.state.chart.data[0].py2 ? (
                      <Line name="2 Years Prior" type="monotone" dataKey="py2" stroke="#000" />
                    ) : null }
                    {this.state.chart.data[0].year2 ? (
                      <Line name="Prior Year" type="monotone" dataKey="py" stroke="#8884d8" />
                    ) : null }

                    <Line name="This Year" type="monotone" dataKey="cy" fill="#82ca9d" />

                    {this.state.chart.data[0].year2 ? (
                      <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                    ) : null }

                  </LineChart>
                </ResponsiveContainer>
              )}
            </>
          ): null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwYears));
