import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl, surl } from '../../../core/utils.js';
import { Button, Row, Col } from 'reactstrap';

import WeekCard from './cards/Week.js';

import { DateTime } from 'luxon';

class WidgetOpsisVariances extends React.Component {

  static propTypes = {
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    var download = surl({
      path: '/v2/excel/opsis?annual_format=1',
      type: 'universal'
    });

    this.state = {
      isLoaded: false,
      isError: false,
      showPriorWeeks: false,
      showFutureWeeks: false,
      download: download,
    }

    this._isMounted = false;
    this.loadVariances = this.loadVariances.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadVariances();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  togglePrior = () => { 
    this.setState({
      showPriorWeeks: !this.state.showPriorWeeks
    });
  }

  toggleFuture= () => { 
    this.setState({
      showFutureWeeks: !this.state.showFutureWeeks
    });
  }

  loadVariances() {
    
    this.setState({
      isLoaded: false,
      results: null
    }, function() {

      var opts = {
        path: '/v2/opsis/variances',
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  fire = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var url = '/v2/opsis/fire/annual';

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.loadVariances();
        }
      }.bind(this));

    });
  }

  render() {

    return (

      <div>
        {this.state ? (
          <>
            <Widget title={
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <h5><span className="text-danger">Forecast Variances</span> <small className="text-muted">{this.state.isLoaded ? (this.state.results.weeks[0].forecast_date) : null}</small></h5>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Row className="gutter-top-fixed-2">
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Button className="btn-success text-white" onClick={() => this.fire()}>
                          <i className="fa-light fa-bolt" /> Run Forecast
                        </Button>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <a href={this.state.download} target="_blank" rel="noopener noreferrer">
                          <Button className="btn-primary text-white">
                            <i className="fa-light fa-download" /> Download
                          </Button>
                        </a>
                      </Col>
                    </Row>
                </Col>
              </Row>
            }>

              <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

              {this.state.isLoaded ? (
                <div className="gutter-top-fixed-2 gutter-bottom-fixed-2">
                  
                  <hr/>

                  {this.state.results.weeks.map((week, i) =>
                    <div key={i} className="w-100">
                      {!week.actuals ? (
                        (DateTime.fromISO(week.weekending) <= DateTime.now().plus({week: 18})) ? (
                          <WeekCard key={i} print={this.props.print} trends={this.props.trends} week={week} />
                        ) : (
                          this.state.showFutureWeeks ? (
                            <WeekCard key={i} print={this.props.print} trends={this.props.trends} week={week} />
                          ) : null
                        )
                      ) : (
                        this.state.showPriorWeeks ? (
                          <WeekCard key={i} print={this.props.print} trends={this.props.trends} week={week} />
                        ) : null
                      )}
                    </div>
                  )}

                  {!this.state.showFutureWeeks ? (
                    <div className="text-right gutter-right-fixed-4 d-print-none">
                      <Button className="btn-primary text-white" onClick={() => this.toggleFuture()}>
                        <i className="fa-light fa-arrow-rotate-right" /> SHOW FUTURE WEEKS
                      </Button>
                    </div>
                  ) : null}

                </div>
              ) : null}

            </Widget>
          </>
      ) : null}
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisVariances));
