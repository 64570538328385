import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import s from './Menu.module.scss';

import { Button, Row, Col } from 'reactstrap';

import { scurl } from '../../core/utils.js';

import isScreen from '../../core/screenHelper';

import Cookies from 'js-cookie';

class Menu extends React.Component {
  
  static propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    page: PropTypes.bool,
    autoFocus: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    user: PropTypes.object.isRequired
  };

  static defaultProps = {
    page: false,
    autoFocus: false,
  };

  constructor(props) {
      
    super(props);

    this.state = {
      verticalHeight: 500,
      isSet: false
    };

    this.selectPage = this.selectPage.bind(this);
    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {

    let pages = this.pages();
    
    this.setState({
      pages: pages,
      isSet: true
    });
  };

  doLogout() {    
    scurl({
      path: '/users/deauth',
      type: 'simple'
    }, function(err, results) {
      if(err) {
        console.log(err);
        console.log('error removing token');
        Cookies.remove("sh_token");
        localStorage.removeItem("sh_state");
        this.props.history.push('/#/login');
      } else {
        console.log(results);
        Cookies.remove("sh_token");
        localStorage.removeItem("sh_state");
        this.props.history.push('/#/login');
      }
    }.bind(this));
    
  }

  selectPage = (e, slug) => {
    e.preventDefault();
    this.props.history.push('/app/' + slug);
    this.props.toggleMenu();
  }

  pages() {

    let pages = [];

    if(this.props.user && this.props.user.permissions && this.props.user.permissions.pages) {

      if(this.props.user.permissions.pages.dashboard.allow) {
        switch(this.props.user.permissions.pages.dashboard.version) {
          case 1:
            pages.push({
              url: "dashboard",
              icon: "fa fa-home",
              name: "Dashboard",
              description: "Holistic business performance overview."
            });
            break;
          case 2:
            pages.push({
              url: "dashboard",
              icon: "fa fa-home",
              name: "Affiliate Dashboard",
              description: "Affiliate home base."
            });
            break;
          case 3:
            pages.push({
              url: "dashboard",
              icon: "fa fa-home",
              name: "Dashboard",
              description: "Overview of ticket sales."
            });
            break;
          case 4:
            pages.push({
              url: "dashboard",
              icon: "fa fa-home",
              name: "Finance Dashboard",
              description: "Reporting for weekly flash."
            });
            break;
          default:
              break;
        }
      }

      if(this.props.user.permissions.pages.transactions.allow) {
        pages.push({
          url: "transactions",
          icon: "fa fa-dollar",
          name: "Transactions",
          description: "Ticket sales totals and various breakdowns."
        });
      }

      if(this.props.user.permissions.pages.search.allow) {
        pages.push({
          url: "search",
          icon: "fa fa-magnet",
          name: "Marketing",
          description: "Paid search and social media marketing results."
        });
      }

      if(this.props.user.permissions.pages.opsis.allow) {
        pages.push({
          url: "opsis",
          icon: "fa fa-crystal-ball",
          name: "Opsis",
          description: "Forecast and budget tracking."
        });
      }

      if(this.props.user.permissions.pages.adsales.allow) {
        pages.push({
          url: "adsales",
          icon: "fa fa-crystal-ball",
          name: "Ad Sales",
          description: "Weekly revenue charts and spend by show."
        });
      }

      if(this.props.user.permissions.pages.industry.allow) {
        pages.push({
          url: "industry",
          icon: "fa fa-crystal-ball",
          name: "Industry",
          description: "Weekly grosses compared to Broadway.com sales."
        });
      }

      if(this.props.user.permissions.pages.search.allow) {
        pages.push({
          url: "known",
          icon: "fa fa-magnet",
          name: "Known Dashboard",
          description: "Dashboard used by Known team"
        });
      }

      if(this.props.user.permissions.pages.conversions.allow) {
        pages.push({
          url: "conversions",
          icon: "fa fa-crystal-ball",
          name: "Conversions",
          description: "Website funnels and analytics."
        });
      }

      if(this.props.user.permissions.pages.content.allow) {
        pages.push({
          url: "content",
          icon: "fa fa-crystal-ball",
          name: "Content",
          description: "Entries and views by page."
        });
      }

      if(this.props.user.permissions.pages.cms.allow) {
        pages.push({
          url: "cms",
          icon: "fa fa-crystal-ball",
          name: "CMS",
          description: "Content formatted for CMS."
        });
      }

      if(this.props.user.admin) {
        pages.push({
          url: "print",
          icon: "fa fa-print",
          name: "Print Reports",
          description: "Excel downloads and weekly reports"
        });
      }

    }

    return pages;
  }

  render() {

    return (
      <section className={`${s.menu} card navbar-menu`}>
        <div>
          <div className="gutter-top-fixed-2 gutter-left-fixed-2 gutter-right-fixed-2 gutter-bottom-fixed-2">
            {this.state.isSet ? (
              this.state.pages.map((page, i) =>
                <div key={i} >
                  <button className="header-link" onClick={((e) => this.selectPage(e, page.url))}><strong>{page.name}</strong></button>
                  {isScreen('md') || isScreen('lg') || isScreen('xl') ? (
                    <p className="small">{page.description}</p>
                  ) : null}
                  {parseInt(i) < (this.state.pages.length - 1) ? (
                    <hr/>
                  ): null}
                </div>
              )
            ): null}
          </div>
        </div>
        <footer className={[s.cardFooter, 'text-sm', 'card-footer'].join(' ')}>

          <Row>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                <span className="fs-mini"><i className="la la-user" /> {this.props.user.first}&nbsp;{this.props.user.last}</span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                <div className="float-right">
                  {this.props.user.admin ? (
                    <a href="/#/app/admin" className="gutter-right-fixed-2 header-link">
                      <span className="header-link"><i className="fa-light fa-cog" /></span>
                    </a>
                  ) : null}
                  <Button
                    color="link"
                    className="btn-xs py-0"
                    onClick={() => this.doLogout()}
                    id="logout-btn"
                  >
                    <span>Logout</span>
                  </Button>
                </div>
              </Col>
          </Row>

          
          
        </footer>
      </section>
    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default withRouter(connect(mapStateToProps)(Menu));
