import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import WidgetBwArchives from '../../widgets/bw/Archives';
import WidgetBwGrossArticles from '../../widgets/bw/GrossArticles';
import WidgetStations from '../../widgets/cms/Stations';

import isScreen from '../../core/screenHelper';

class Cms extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs() {

    let tabs = [];

    let allowed = [];
    
    if(this.props.user.permissions.pages.cms.tabs.guide) {
      tabs.push({
        id: 0,
        icon: 'fa-file-pen',
        name: 'Grosses Blocks'
      });
      allowed.push(0);
    }
    if(this.props.user.permissions.pages.cms.tabs.show) {
      tabs.push({
        id: 1,
        icon: 'fa-tv',
        name: 'Broadway Show Stations'
      });
      allowed.push(1);
    }
    if(this.props.user.permissions.pages.cms.tabs.legacy) {
      tabs.push({
        id: 2,
        icon: 'fa-file',
        name: 'Legacy Articles'
      });
      allowed.push(2);
      tabs.push({
        id: 3,
        icon: 'fa-camera',
        name: 'Legacy Galleries'
      });
      allowed.push(3);
    }

    let selected = -1;
    if(allowed.length === 1) {
      selected = allowed[0];
    }
    
    this.setState({
      tabs: tabs,
      tabSelected: selected
    });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  render() {
  
    return (
      <div>

        <Helmet>
          <title>SH4: CMS Tools</title>
        </Helmet>

        <div className="menuContainer lg">
          <Nav className="mt-4">
            <NavItem className="nopad-bottom nomargin-bottom">
              
                <h5 className="text-danger">CMS Components</h5>

                {this.state.tabs ? (
                  <ButtonGroup>
                    {this.state.tabs.map((tab, i) =>
                      <Button key={i}
                        color={this.state.tabSelected === tab.id ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(tab.id)}
                        active={this.state.tabSelected === tab.id}
                      ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === tab.id ? 'text-default' : 'text-danger'].join(' ')} />
                      <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                    )}
                  </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetBwGrossArticles />
            </div>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetStations user={this.props.user} />
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
              <WidgetBwArchives print={false} type="articles" />
            </div>
          ): null}

          {this.state.tabSelected === 3 ? (
            <div>
              <WidgetBwArchives print={false} type="galleries" />
            </div>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Cms));
