import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// import {
//   Row,
//   Col
// } from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

import DatapointCard from './cards/Datapoint.js';

class WidgetBwMargins extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    fixed_years: PropTypes.number,
    perf_date: PropTypes.bool
  };

  static defaultProps = {
    print: false,
    chart: true,
    fixed_years: 4,
    perf_date: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      fixed_years: this.props.fixed_years,
      compare_year: 3,
      perf_date: this.props.perf_date
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
    this.sortData = this.sortData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'MARGINS WIDGET')) {
      this.loadData();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/blender/margins',
        type: 'universal',
        years: 4
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(this.state.perf_date) {
        opts.perf_date = 1;
      }

      opts.refunds = 0;

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              results: results,
            }, function() {
              this.sortData();
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  sortData() {
    this.setState({
      isLoaded: false,
      datapoints: []
    }, function() {
      
      var datapoints = [];

      datapoints.push({
        name: 'Blended Service Fee %',
        current: parseFloat(this.state.results.years[0].detail.margin * 100).toFixed(1) + '%',
        prior: parseFloat(this.state.results.years[this.state.compare_year].detail.margin * 100).toFixed(1) + '%',
        impact: parseFloat(this.state.results.years[0].detail.average_fee) - parseFloat(this.state.results.years[0].detail.average_price * this.state.results.years[this.state.compare_year].detail.margin),
        notes: 'Prior margin % with current avg ticket price. Ignores conv %.',
        key: 'margin'
      });

      datapoints.push({
        name: 'Average Face Value',
        current: '$' + parseFloat(this.state.results.years[0].detail.average_price).toFixed(2),
        prior: '$' + parseFloat(this.state.results.years[this.state.compare_year].detail.average_price).toFixed(2),
        impact: parseFloat(this.state.results.years[0].detail.average_price * this.state.results.years[0].detail.margin) - parseFloat(this.state.results.years[this.state.compare_year].detail.average_price * this.state.results.years[0].detail.margin),
        notes: 'Prior avg price with current margin %.',
        key: 'average_price'
      });

      datapoints.push({
        name: 'Average Service Fee',
        current: '$' + parseFloat(this.state.results.years[0].detail.average_fee).toFixed(2),
        prior: '$' + parseFloat(this.state.results.years[this.state.compare_year].detail.average_fee).toFixed(2),
        impact: parseFloat(this.state.results.years[0].detail.average_fee) - parseFloat(this.state.results.years[this.state.compare_year].detail.average_fee),
        notes: 'Impact of average ticket price and average margin %.',
        key: 'average_fee'
      });

      datapoints.push({
        name: 'Average Access Fee',
        current: '$' + parseFloat(this.state.results.years[0].detail.average_access_fee).toFixed(2),
        prior: '$' + parseFloat(this.state.results.years[this.state.compare_year].detail.average_access_fee).toFixed(2),
        impact: parseFloat(this.state.results.years[this.state.compare_year].detail.average_access_fee) - parseFloat(this.state.results.years[0].detail.average_access_fee),
        notes: 'Total access fees divided by total quantity.',
        key: 'average_access_fee'
      });

      datapoints.push({
        name: 'Average Search Cost',
        current: '$' + parseFloat(this.state.results.years[0].detail.average_search).toFixed(2),
        prior: '$' + parseFloat(this.state.results.years[this.state.compare_year].detail.average_search).toFixed(2),
        impact: parseFloat(this.state.results.years[this.state.compare_year].detail.average_search) - parseFloat(this.state.results.years[0].detail.average_search),
        notes: 'Total search spend divided bv total quantity of tickets.',
        key: 'average_search'
      });

      datapoints.push({
        name: 'Average Affiliate Commission',
        current: '$' + parseFloat(this.state.results.years[0].detail.average_affiliate_commission).toFixed(2),
        prior: '$' + parseFloat(this.state.results.years[this.state.compare_year].detail.average_affiliate_commission).toFixed(2),
        impact: parseFloat(this.state.results.years[this.state.compare_year].detail.average_affiliate_commission) - parseFloat(this.state.results.years[0].detail.average_affiliate_commission),
        notes: 'Total affiliate commissions divided bv total quantity of tickets.',
        key: 'average_affiliate_commission'
      });

      var ins_cy = parseFloat(this.state.results.years[0].detail.average_insurance-this.state.results.years[0].detail.average_insurance_loss);
      if(ins_cy < 0) {
        ins_cy = parseFloat(0).toFixed(2);
      }

      datapoints.push({
        name: 'Average Insurance Fee Less Spoilage',
        current: '$' + ins_cy,
        prior: '$' + parseFloat(this.state.results.years[this.state.compare_year].detail.average_insurance-this.state.results.years[this.state.compare_year].detail.average_insurance_loss).toFixed(2),
        impact:  - parseFloat(this.state.results.years[this.state.compare_year].detail.average_insurance-this.state.results.years[this.state.compare_year].detail.average_insurance_loss),
        notes: 'Revenue less spoilage, divided bv total quantity of tickets.',
        key: 'average_insurance'
      });

      datapoints.push({
        name: 'Net Margin',
        current: '$' + parseFloat(this.state.results.years[0].detail.average_net).toFixed(2),
        prior: '$' + parseFloat(this.state.results.years[this.state.compare_year].detail.average_net).toFixed(2),
        impact: parseFloat(this.state.results.years[0].detail.average_net) - parseFloat(this.state.results.years[this.state.compare_year].detail.average_net),
        notes: 'Service Fee less Access, CC, Search, Affiliate, Insurance',
        key: 'net_net'
      });

      this.setState({
        datapoints: datapoints,
        isLoaded: true,
      });

    })
  }

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.loadData();
    });
  }

  render() {

    var title = <h5><span className="text-danger">Margin Analysis</span> <small className="text-muted">Current results versus prior years, by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>. Excludes refunds.</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">
            
              {this.state.datapoints.map((datapoint, i) =>
                <>
                  <DatapointCard metric_key={datapoint.key} print={this.props.print} metric_name={datapoint.name} cy_value={datapoint.current} prior_value={datapoint.prior} impact={datapoint.impact} notes={datapoint.notes} weeks={this.state.results.weeks} />
                  <div className="page-divide"></div>
                </>
              )}

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwMargins));
