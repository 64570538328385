import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Navbar,
  Nav,
  Dropdown,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  InputGroup,
  Input,
  Form,
  FormGroup,
} from 'reactstrap';

import Dates from '../Dates';
import Shows from '../Shows';
import Menu from '../Menu';

import s from './Header.module.scss'; // eslint-disable-line css-modules/no-unused-class

class Header extends React.Component {
  
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      showsOpen: false,
      searchQuery: ''
    };

    this.toggleDates = this.toggleDates.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleShows = this.toggleShows.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  toggleDates() {
    this.setState({
      datesOpen: !this.state.datesOpen,
    });
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  toggleShows() {
    this.setState({
      showsOpen: !this.state.showsOpen,
    });
  }

  onKeyPressed(e) {
    this.setState({
      searchQuery: e.target.value,
      showsOpen: true
    });
  }

  render() {
    return (
      <Navbar className={`${s.root} d-print-none`}>
        <Nav>

          <NavItem>
            <NavLink href="/" className={`${s.logo} gutter-left-fixed-1`}>
              <h2>
                <span className="text-primary">SH</span><span className="text-white">4</span>
              </h2>
            </NavLink>
          </NavItem>

          {this.props.user.permissions && this.props.user.permissions.components && this.props.user.permissions.components.shows ? (
            <Dropdown nav isOpen={this.state.showsOpen} toggle={this.toggleShows} id="basic-nav-dropdown" className={`${s.showsMenu} d-sm-down-none`}>
              <DropdownToggle nav>
                <Form className="ml-5" inline="true">
                  <FormGroup>
                    <InputGroup className="input-group-no-border">
               
                      <div className="input-group-append">
                        <span className="input-group-text"><i className="fa-light fa-search fa-lg" /></span>
                      </div>
     
                      <Input href="showquery" placeholder=" Find Shows" onChange={this.onKeyPressed} />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </DropdownToggle>
              <DropdownMenu end className={`${s.showsWrapper} py-0 animated animated-fast fadeInUp`}>
                <Shows q={this.state.searchQuery} toggleShows={this.toggleShows} />
              </DropdownMenu>
            </Dropdown>
          ) : null}

          {this.props.user.permissions && this.props.user.permissions.components && this.props.user.permissions.components.dates ? (
            <Dropdown nav isOpen={this.state.datesOpen} toggle={this.toggleDates} id="basic-nav-dropdown" className={`${s.notificationsMenu} gutter-left-fixed-2`}>
              <DropdownToggle nav>
                {this.props && this.props.filter && this.props.filter.dates && this.props.filter.dates.display ? (
                  <h6 className="mt-1">
                    <i className="fa-light fa-calendar fa-xl" />&nbsp;&nbsp;{this.props.filter.dates.display}
                  </h6>
                ) : (
                  <h6 className="mt-1">
                    <i className="fa-light fa-calendar fa-xl" />&nbsp;&nbsp;Choose Dates
                  </h6>
                )}
              </DropdownToggle>
              <DropdownMenu end className={`${s.notificationsWrapper} py-0 animated animated-fast fadeInUp`}>
                <Dates toggleDates={this.toggleDates} />
              </DropdownMenu>            
            </Dropdown>
          ) : null}
          
        </Nav>
        <Nav className="ml-auto gutter-right-fixed-1">
          {this.props.user.permissions && this.props.user.permissions.components && this.props.user.permissions.components.menu ? (
            <Dropdown nav isOpen={this.state.menuOpen} toggle={this.toggleMenu} id="basic-nav-dropdown" className={`${s.mainMenu} gutter-left-fixed-4`}>
              <DropdownToggle nav>
                <h6 className="mt-1">
                  <i className="fa-light fa-bars fa-xl" /><span className="d-sm-down-none gutter-left-fixed-1 gutter-right-fixed-1">Menu</span>
                </h6>
              </DropdownToggle>
              <DropdownMenu className={`${s.mainMenuWrapper} py-0 animated animated-fast fadeInUp`}>
                <Menu toggleMenu={this.toggleMenu} user={this.props.user} />
              </DropdownMenu>            
            </Dropdown>
          ) : null}
        </Nav>

      </Navbar>
    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(Header);

