import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { nFormatter } from '../../../../core/utils';
import Poster from '../../../../components/Poster';

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid} from 'recharts';

class WidgetProductCard extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    sales_metric: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    print: false,
    width: 300,
    height: 100,
    sales_metric: 'face',
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      chart: {
        data: []
      }
    }

    this.setChart = this.setChart.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {
    if(this.props.product.trend) {
      this.setChart();
    }
  }

  setChart() {

    var chartData = [];

    this.props.product.trend.forEach(function(block, i) {
      
      var d = {
        week: block.dates.end,
        sales: block.sales.fees
      };

      if(block.sales.fees > 0) {
        chartData.push(d);
      }

    });
    
    var aboveMax = 1.1;
    this.setState({
      chart: {
        data: chartData,
        properties: {
          aboveMax: aboveMax
        }
      }
    });
  }

  salesSummaryDataFormat(x) {
    return '$' + nFormatter(x);
  };

  salesSummaryDateFormat(x) {
    return moment(x).format('M/DD');
  };

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        <div className={['card gutter-top-fixed-2 gutter-bottom-fixed-2 no-break',this.props.className].join('')}>
          <div className="card-body nopad nomargin no-break">

            {this.props.product.product ? (
              <div className="nopad nomargin no-break">
                
                <div className="d-flex nopad nomargin no-break">
                  <div className="nopad nomargin">
                    {!this.props.print ? (
                      <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))}>
                        <Poster id={this.props.product.product.poster_id} w={75} className="border-right img-poster nopad nomargin" />
                      </a>
                    ) : (
                      <Poster id={this.props.product.product.poster_id} w={75} className="border-right img-poster nopad nomargin" />
                    )}
                  </div>
                  <div className="flex-grow text-center nopad nomargin">
                      
                      {!this.props.print ? (
                        <h6 className="mb-3">
                          <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))} className="fs-mini text-muted">
                            {this.props.product.product.short_name ? (
                              <span>{this.props.product.product.short_name}</span>
                            ) : (
                              this.props.product.product.name && (this.props.product.product.name.length > 18) ? (
                                <span>{this.props.product.product.name.slice(0,18)}...</span>
                              ) : (
                                <span>{this.props.product.product.name}</span>
                              )
                            )}
                          </a>
                        </h6>
                      ) : (
                        <span className="fs-mini text-muted">
                          {this.props.product.product.short_name ? (
                            <span>{this.props.product.product.short_name}</span>
                          ) : (
                            this.props.product.product.name && (this.props.product.product.name.length > 18) ? (
                              <span>{this.props.product.product.name.slice(0,18)}...</span>
                            ) : (
                              <span>{this.props.product.product.name}</span>
                            )
                          )}
                        </span>
                      )} 

                      

                      {this.props.product.prior && this.props.product.prior[0] ? (
                        <div className="pt-2">
                          <h4 className="text-center">{parseInt(this.props.product.share[this.props.sales_metric]*100)}%</h4>
                          <p className="pt-2 pb-0 mb-0 fs-mini text-muted text-center">Previous Year</p>
                          <h6 className="font-weight-bold">{parseInt(this.props.product.prior[0].share[this.props.sales_metric]*100)}%</h6>
                        </div>
                      ) : (
                        <h4 className="pt-4 text-center">{parseInt(this.props.product.share[this.props.sales_metric]*100)}%</h4>
                      )}
                      
                  </div>
                </div>
                
                {this.props.print ? (
                  this.state.chart && this.state.chart.data && this.state.chart.data.length > 1 ? (
                    <div className="gutter-top-fixed-2">
                      <LineChart data={this.state.chart.data} margin={{top: 0, right: 0, left: 0, bottom: 0}} height={this.props.height} width={this.props.width} >
                        <defs>
                          <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                          </linearGradient>
                        </defs>
                        <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 9}} dataKey="week" padding={{left: 10, right: 10}} />
                        <YAxis tick={{fontSize: 9}} tickSize={5} tickFormatter={this.salesSummaryDataFormat} dataKey="sales" domain={[0, dataMax => (dataMax * this.state.chart.properties.aboveMax)]} />
                        <Line name="sales" type="monotone" dataKey="sales" stroke="#82ca9d" />
                      </LineChart>
                    </div>
                  ) : null
                ) : (
                  <ResponsiveContainer width='100%' aspect={this.props.chartAspectWidth/this.props.chartAspectHeight} >
                    <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                      <defs>
                        <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                        </linearGradient>
                      </defs>
                      <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 10, right: 10}} />
                      <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[dataMin => (dataMin * 0.9), dataMax => (dataMax * this.state.chart.properties.aboveMax)]} />
                      <CartesianGrid strokeDasharray="3 3"/>
                      <Tooltip />
                      <Line name="Trend" type="monotone" dataKey="cy" stroke="#82ca9d" />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              
              </div>
            ) : null}

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetProductCard));
