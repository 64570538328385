import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Button
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import Years from '../../../components/Years';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';
import ArticleCard from './cards/Article.js';
import GalleryCard from './cards/Gallery.js';

class WidgetArchives extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    type: PropTypes.string
  };

  static defaultProps = {
    print: false,
    type: 'articles'
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      display: 100,
      statusless: true
    }

    this._isMounted = false;
    this.loadContent = this.loadContent.bind(this);
    this.viewMore = this.viewMore.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadContent();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'ARCHIVES WIDGET')) {
      this.loadContent();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadContent() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/cms/' + this.props.type,
        type: 'universal',
        statusless: this.state.statusless
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('unmount handled');
        }
      }.bind(this));
    });  
  }

  viewMore = () => {    
    this.setState({
      display: this.state.display + 100
    });
  }

  render() {

    var title = <h5><span className="text-danger">{this.props.type === 'articles' ? (<span>Articles</span>) : (<span>Galleries</span>)}</span> {this.state.isLoaded ? (<small className="text-muted">From {moment(this.state.results.filter.dates.start, 'YYYY-MM-DD').format('MMM DD, YYYY')} to {moment(this.state.results.filter.dates.end, 'YYYY-MM-DD').format('MMM DD, YYYY')}.</small> ) : null}</h5>

    return (

      <div>

        {this.props.type === 'articles' ? (
          <Years start={2011} end={2017} />
        ) : (
          <Years start={2002} end={2017} />
        )}

        <Widget title={title} className="widget-thin mt-3">

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-4">

                {this.state.results[this.props.type].length === 0 ? (
                  
                  <div className="p-4 text-center">
                    <h5>No Items Within Date Range</h5>
                  </div>

                ) : (
                  <div>

                    <div className="p-4 text-center">
                      <h5>{this.state.results[this.props.type].length} Items</h5>
                    </div>

                    {this.state.results[this.props.type].map((content, i) =>
                      i < this.state.display ? (
                        <div key={i}>
                          {this.props.type === 'articles' ? (
                            <ArticleCard article={content} />
                          ) : (
                            <GalleryCard gallery={content} />
                          )}
                        </div>
                      ) : null
                    )}

                    {this.state.display < this.state.results[this.props.type].length ? (
                      <div className="text-center">
                        <Button className="btn-success" onClick={() => this.viewMore()}>View More</Button>
                      </div>
                    ) : null}
   
                  </div>
                )}

              </div>

          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetArchives));

