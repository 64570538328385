import React from 'react';
import PropTypes from 'prop-types';

class Loading extends React.Component {
  
  static propTypes = {
    loading: PropTypes.bool,
    pad: PropTypes.number,
    error: PropTypes.object,
    className: PropTypes.string
  };

  static defaultProps = {
    loading: false,
    pad: 4,
    error: null,
    className: ''
  }
  
  render() {
    return (
      <div className="nomargin nopad">
        {this.props.loading && !this.props.error ? (
          <div className={["gutter-top-fixed-", this.props.pad, " ", "gutter-bottom-fixed-", this.props.pad, " ", this.props.className].join("")}>
            <p className="fw-normal">
              <i className="fa-light fa-atom-simple fa-spin"></i>&nbsp;Loading....
            </p>
          </div>
        ) : null}
        {this.props.error ? (
          <div className={["gutter-top-fixed-", this.props.pad, " ", "gutter-bottom-fixed-", this.props.pad].join("")}>
            <p className="fw-normal text-danger">
              <i className="fa fa-exclamation"></i>&nbsp;{this.props.error.message || 'An unknown error has occured.'}
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Loading;

