import React, { PureComponent }   from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon/build/node/luxon';

import { formatNumber } from '../../core/utils';

import { ResponsiveContainer } from 'recharts';

import isScreen from '../../core/screenHelper';

class ChartMagic extends PureComponent {
  render() {
    const { chart, print, aspect } = this.props;

    if(print) {
      return (
        <div>{chart}</div>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" aspect={aspect} padding={{top: 0, right: 0, left: 0, bottom: 0}} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
          {chart}
        </ResponsiveContainer>
      );
    }
  }
}

class Chart extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    chart: PropTypes.object,
    chart_width: PropTypes.number,
    chart_height: PropTypes.number,
    chart_aspect_width: PropTypes.number,
    chart_aspect_height: PropTypes.number,
    className: PropTypes.string
  };

  static defaultProps = {
    print: false,
    chart_width: 350,
    chart_height: 170,
    chart_aspect_width: 3,
    chart_aspect_height: 1.2,
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      print: this.props.print,
      chart_width: this.props.chart_width,
      chart_height: this.props.chart_height,
      chart_aspect_width: this.props.chart_aspect_width,
      chart_aspect_height: this.props.chart_aspect_height,
    }

    this._isMounted = false;
    this.chartDataFormat = this.chartDataFormat.bind(this);
    this.chartDateFormat = this.chartDateFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setChartAspect();
    // this.setChart();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(this.props.timespan !== prevProps.timespan) {
      this.setChart();
    }
  }

  handleResize() {
    if(this._isMounted) {
      //this.setChartAspect();
    }
  }

  chartDataFormat(value) {
    return formatNumber(this.props.format,value);
  };

  chartDateFormat(x) {
    return DateTime.fromISO(x).toFormat('MM-dd');
  };

  setChartAspect() {
    if(this.props.print) {
      this.setState({
        ...this.state,
        chart_width: 450,
        chart_height: 170,
        chart_aspect_width: 2,
        chart_aspect_height: 2
      });
    } else {
      if((isScreen('xs') || isScreen('sm'))) {
        this.setState({
          ...this.state,
          chart_width: 350,
          chart_height: 150,
          chart_aspect_width: 2,
          chart_aspect_height: 1
        });
      } else {
        if(isScreen('md')) {
          this.setState({
            ...this.state,
            chart_width: 350,
            chart_height: 150,
            chart_aspect_width: 2,
            chart_aspect_height: 2
          });
        } else {
          if(isScreen('lg')) {
            this.setState({
              ...this.state,
              chart_width: 350,
              chart_height: 150,
              chart_aspect_width: this.props.chart_aspect_width,
              chart_aspect_height: this.props.chart_aspect_height
            });
          } else {
            this.setState({
              ...this.state,
              chart_width: 350,
              chart_height: 150,
              chart_aspect_width: this.props.chart_aspect_width,
              chart_aspect_height: this.props.chart_aspect_height
            });
          }
        }
      }
    }
  }

  setChart() {

    var chartData = [];

    this.props.results.timespans[this.props.timespan].forEach(function(block, i) {
      
      var d = {
        name: block.dates.end
      }

      if(this.props.parent) {
        d.cy = block.years[0][this.props.parent][this.props.field];
        d.py = block.years[1][this.props.parent][this.props.field];
      } else {
        d.cy = block.years[0][this.props.field];
        d.py = block.years[1][this.props.field];
      }
      
      chartData.push(d);

    }.bind(this));

    let max = 1;
    let min = 1;

    this.setState({
      ...this.state,
      chart: {
        data: chartData,
        properties: {
          max: max,
          min: min
        }
      }
    });
  }

  render() {

    return (
      <div className={this.props.className}>
        {this.props.chart ? (
          <ChartMagic
            chart ={this.props.chart}
            print={this.props.print}
            aspect={this.state.chart_aspect_width/this.state.chart_aspect_height}
          />
        ) : null}
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Chart));
