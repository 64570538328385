import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Row,
  Col
} from 'reactstrap';

import { ResponsiveContainer, Line, LineChart, XAxis, YAxis, Legend} from 'recharts';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import Displays from '../../../components/Displays';
import { scurl, isWeeks } from '../../../core/utils.js';
import { filterUpdated, defaultTimespan, displaySalesMetric, subSort  } from '../../../core/utils';
import isScreen from '../../../core/screenHelper';
import ProductCard from './cards/ProductCard.js';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value } = this.props;

    return (
      <text x={x} y={y} dx={0} dy={-8} fill='#777' fontSize={10} textAnchor="middle">
        {parseFloat(value*100).toFixed(0) + '%'}
      </text>
    );
  }
}

class ChartMagic extends PureComponent {
  render() {
    const { chart, print, aspect } = this.props;

    if(print) {
      return (
        <div>{chart}</div>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" aspect={aspect}>
          {chart}
        </ResponsiveContainer>
      );
    }
  }
}
class WidgetBwShare extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    product_id: PropTypes.number,
    timespan: PropTypes.string,
    sales_metric: PropTypes.string,
    chart_metric: PropTypes.string,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    fixed_years: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string
  };

  static defaultProps = {
    print: false,
    timespan: 'week',
    sales_metric: 'face',
    chart_metric: 'face',
    chartAspectWidth: 5,
    chartAspectHeight: 1,
    chart: true,
    width: 1050,
    height: 500,
    fixed_years: 5
  };

  constructor(props) {
    super(props);

    var start = this.props.start;
    var end = this.props.end;

    if(!start || !end) {
      start = this.props.filter.dates.start;
      end = this.props.filter.dates.end;
    }

    this.state = {
      isLoaded: false,
      isError: false,
      display: 12,
      sales_metric: this.props.sales_metric,
      chart_metric: this.props.chart_metric,
      chart_timespan: defaultTimespan(this.props.filter),
      fixed_years: this.props.fixed_years,
      chartAspectWidth: this.props.chartAspectWidth,
      chartAspectHeight: this.props.chartAspectHeight,
      start: start,
      end: end,
      print: this.props.print
    }

    this._isMounted = false;
    this.loadShare = this.loadShare.bind(this);

    this.setShareChartData = this.setShareChartData.bind(this);
    this.shareSummaryDataFormat = this.shareSummaryDataFormat.bind(this);
    this.shareSummaryDateFormat = this.shareSummaryDateFormat.bind(this);

    this.setSalesChartData = this.setSalesChartData.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);

    this.setChartAspect = this.setChartAspect.bind(this);
    this.areaLabelFormat = this.areaLabelFormat.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadShare();
    this.setChartAspect();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SHARE WIDGET')) {
      if(!this.props.product_id) {
        if(isWeeks(this.props.filter)) {
          this.setState({
            start: this.props.filter.dates.start,
            end: this.props.filter.dates.end,
            isLoaded: false,
            results: [],
            shareChart: null,
            salesChart: null,
            error: null
          }, function() {
            this.loadShare();
          });
        } else {
          this.setState({
            isLoaded: false,
            results: [],
            shareChart: null,
            salesChart: null,
            error: {
              status: -99,
              message: 'Date range not in weeks'
            }
          });
        }
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadShare() {

    var tempFilter = {
      dates: {
        start: this.state.start,
        end: this.state.end
      }
    };

    if(isWeeks(tempFilter)) {
      this.setState({
        isLoaded: false,
        results: [],
        shareChart: null,
        salesChart: null
      }, function() {

        var path = '/industry/shows';

        var opts = {
          path: path,
          type: 'universal'
        };

        opts.univ_start = this.state.start;
        opts.univ_end = this.state.end;

        opts.years = this.state.fixed_years;

        if(this.props.product_id) {
          opts.product_id = this.props.product_id;
        }

        scurl(opts, function(err, results) {
          if(this._isMounted) {
            if(err) {
              this.setState({
                error: {
                  json: err,
                  status: err.status || -99,
                  message: err.message || 'Error occured'
                }
              });
            } else {

              if( (results.dates[0].totals.sales.qty > 0) && (results.dates[0].totals.grosses.qty > 0)) {

                this.setState({
                  isLoaded: true,
                  results: results,
                }, function() {
                  this.setShareChartData();
                  if(this.state.fixed_years > 2) {
                    this.setSalesChartData();
                  }
                });
              } else {
                this.setState({
                  error: {
                    status: -99,
                    message: 'No data matches query'
                  }
                });
              }
            }
          } else {
            console.log('handled unmount');
          }
        }.bind(this));
      });
    } else {
      this.setState({
        error: {
          status: -99,
          message: 'Dates must be valid weeks'
        }
      });
    }

  }

  setShareChartData() {
    var shareChartData = [];
        
    if(this.state.results && this.state.results.dates && this.state.results.dates.length > 0) {

      this.state.results.dates[0].weeks.forEach(function(block, i) {
        
        if(block.share[this.state.chart_metric] > 0) {
          var d = {
            name: block.key,
            year1: block.share[this.state.chart_metric]
          };

          if(this.state.results.dates[1] && this.state.results.dates[1].weeks[i] && this.state.results.dates[1].weeks[i].share && this.state.results.dates[1].weeks[i].share[this.state.chart_metric] > -1) {
            d.year2 = this.state.results.dates[1].weeks[i].share[this.state.chart_metric];
          }

          if(this.state.results.dates[2] && this.state.results.dates[2].weeks[i] && this.state.results.dates[2].weeks[i].share && this.state.results.dates[2].weeks[i].share[this.state.chart_metric] > -1) {
            d.year3 = this.state.results.dates[2].weeks[i].share[this.state.chart_metric];
          }

          if(this.state.results.dates[3] && this.state.results.dates[3].weeks[i] && this.state.results.dates[3].weeks[i].share && this.state.results.dates[3].weeks[i].share[this.state.chart_metric] > -1) {
            d.year4 = this.state.results.dates[3].weeks[i].share[this.state.chart_metric];
          }

          if(this.state.results.dates[4] && this.state.results.dates[4].weeks[i] && this.state.results.dates[4].weeks[i].share && this.state.results.dates[4].weeks[i].share[this.state.chart_metric] > -1) {
            d.year5 = this.state.results.dates[4].weeks[i].share[this.state.chart_metric];
          }

          if(this.state.results.dates[5] && this.state.results.dates[5].weeks[i] && this.state.results.dates[5].weeks[i].share && this.state.results.dates[5].weeks[i].share[this.state.chart_metric] > -1) {
            d.year6 = this.state.results.dates[5].weeks[i].share[this.state.chart_metric];
          }

          shareChartData.push(d);
        }
       
      }.bind(this));

      var min = 0.8;
      var max = 1.15;

      this.setState({
        shareChart: {
          data: shareChartData,
          properties: {
            max: max,
            min: min
          }
        }
      }, function() {
        //console.log(this.state.chart)
      });
    }
  }

  setSalesChartData() {
    var salesChartData = [];
        
    if(this.state.results && this.state.results.dates && this.state.results.dates.length > 0) {

      this.state.results.dates[0].weeks.forEach(function(block, i) {

        if(block.share[this.state.chart_metric] > 0) {
        
          var d = {
            name: block.key,
            industry: ( (block.grosses[this.state.chart_metric] - this.state.results.dates[3].weeks[i].grosses[this.state.chart_metric]) / this.state.results.dates[3].weeks[i].grosses[this.state.chart_metric] ),
            bcom: ( (block.sales[this.state.chart_metric] - this.state.results.dates[3].weeks[i].sales[this.state.chart_metric]) / this.state.results.dates[3].weeks[i].sales[this.state.chart_metric] ),
            breakeven: 0
          };
          salesChartData.push(d);
        }
       
      }.bind(this));

      var min = 1;
      var max = 1.15;

      this.setState({
        salesChart: {
          data: salesChartData,
          properties: {
            max: max,
            min: min
          }
        }
      }, function() {
        //console.log(this.state.chart)
      });
    }
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: this.props.chartAspectWidth,
        chartAspectHeight: this.props.chartAspectHeight
      });
    }
  }

  shareSummaryDataFormat(x) {
    if(this.state.metric === 'qty') {
      return parseInt(x*100)+ '%';
    } else {
      return parseInt(x*100) + '%';
    }
  };

  shareSummaryDateFormat(x) {
    if(this.state.chart_timespan === 'day') {
      return moment(x).format('M/DD');
    } else {
      if(this.state.chart_timespan === 'week') {
        return moment(x).format('M/DD');
      } else {
        return moment(x).format('MMM');
      }
    }
  };

  salesSummaryDataFormat(x) {
    if(this.state.metric === 'qty') {
      return parseInt(x*100)+ '%';
    } else {
      return parseInt(x*100) + '%';
    }
  };

  salesSummaryDateFormat(x) {
    if(this.state.chart_timespan === 'day') {
      return moment(x).format('M/DD');
    } else {
      if(this.state.chart_timespan === 'week') {
        return moment(x).format('M/DD');
      } else {
        return moment(x).format('MMM');
      }
    }
  };

  areaLabelFormat(x) {
    return x
  };

  setPrint = () => { 
    this.setState({
      print: !this.state.print
    }, function() {
      //this.loadShare();
    });
  }

  changeSalesMetric = () => { 
    var change_display = '';

    if(this.state.sales_metric === 'qty') {
      change_display = 'face';
    } else {
      change_display = 'qty';
    }

    subSort('share.' + change_display,this.state.results.dates[0].shows,1);

    this.setState({
      sales_metric: change_display,
      chart_metric: change_display,
      results: this.state.results
    }, function() {
      this.setShareChartData();
      this.setSalesChartData();
    });
  };

  render() {

    var title = <h5><span className="text-danger">Industry Comparison</span> <small className="text-muted">Broadway.com {displaySalesMetric(this.state.sales_metric)} (equivalent to grosses) as a percentage of industry totals. Excludes Off Broadway and Radio City.</small></h5>;
    if(this.props.engagement) {
      title = <h5><span className="text-danger">{this.props.engagement.show.name} - Grosses Comparison</span> <small className="text-muted">Figures under YTD metrics represent 2019-2020 figures.</small></h5>;
    }
    
    return (

      <div>
{/* 
        <Widget className="d-print-none mb-1 bg-gray">
          <Row>
            <Col xs={4} sm={2} md={2} lg={2} xl={2}>
              <p className="fs-mini font-weight-bold">Options</p>
            </Col>
            <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-center">
              <p className="fs-mini mb-0 pb-0">Sales Metric</p>
              <button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button>
            </Col>
            <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-center">
              <p className="fs-mini mb-0 pb-0">Format</p>
              <button className="header-link" onClick={() => this.setPrint()}>{this.state.print ? (<span>Print</span>) : ((<span>Display</span>))}</button>
            </Col>
          </Row>
        </Widget> */}

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (   
            <div className="gutter-top-fixed-2">

              <Row>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                  <p className="fs-mini text-muted text-center">Current Year</p>
                  <h4 className="text-center">{parseFloat(this.state.results.dates[0].totals.share[this.state.sales_metric]*100).toFixed(1)}%</h4>
                </Col>
                {this.state.results.dates[1] && this.state.results.dates[1].totals.share[this.state.sales_metric] > 0 ? (
                  <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                    <p className="fs-mini text-muted text-center">Prior Year</p>
                    <h4 className="text-center">{parseFloat(this.state.results.dates[1].totals.share[this.state.sales_metric]*100).toFixed(1)}%</h4>
                  </Col>
                ) : null}
                {this.state.results.dates[4] && this.state.results.dates[4].totals.share[this.state.sales_metric] > 0 ? (
                  <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                    <p className="fs-mini text-muted text-center">Pre-Covid</p>
                    <h4 className="text-center">{parseFloat(this.state.results.dates[4].totals.share[this.state.sales_metric]*100).toFixed(1)}%</h4>
                  </Col>
                ) : null}
              </Row>

              {this.state.isLoaded && this.state.shareChart && this.state.shareChart.data ? (
                <div>
                  <h6 className="gutter-top-fixed-4">{displaySalesMetric(this.state.sales_metric)} market share by week, compared to prior years.</h6>
                  {this.props.chart && this.state.shareChart.data.length > 0 ? (
                    <ChartMagic
                      chart = {
                        <LineChart data={this.state.shareChart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                          <defs>
                            <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                            </linearGradient>
                            <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                            </linearGradient>
                          </defs>
                          <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                          <YAxis tick={{fontSize: 10}} tickSize={8} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.shareChart.properties.min), dataMax => (dataMax * this.state.shareChart.properties.max) ]} />

                          <Line name="CY" type="monotone" dataKey="year1" stroke="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1 }} strokeWidth={2} label={<CustomizedLabel />} />

                          {this.state.shareChart.data[this.state.shareChart.data.length-1].year2 ? (
                            <Line name="1YR" type="monotone" dataKey="year2" stroke="#ccc" dot={{ stroke: '#ccc', strokeWidth: 1 }} label={<CustomizedLabel />} />
                          ) : null }
                          {this.state.shareChart.data[0].year3 ? (
                            <Line name="2YR" type="monotone" dataKey="year3" stroke="#0087a9" dot={{ stroke: '#0087a9', strokeWidth: 1 }} label={<CustomizedLabel />} />
                          ) : null }
                          {this.state.shareChart.data[0].year4 ? (
                            <Line name="3YR" type="monotone" dataKey="year4" stroke="#ffc647" dot={{ stroke: '#ffc647', strokeWidth: 1 }} label={<CustomizedLabel />} />
                          ) : null }
                          {this.state.shareChart.data[0].year5 ? (
                            <Line name="4YR" type="monotone" dataKey="year5" stroke="#754c7f" dot={{ stroke: '#754c7f', strokeWidth: 1 }} label={<CustomizedLabel />} />
                          ) : null }
                          {this.state.shareChart.data[0].year6 ? (
                            <Line name="5YR" type="monotone" dataKey="year6" stroke="#db3920" dot={{ stroke: '#db3920', strokeWidth: 1 }} label={<CustomizedLabel />} />
                          ) : null }

                          {this.state.shareChart.data[0].year3 ? (
                            <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                          ) : null }
                        </LineChart>
                      }
                       print={this.state.print}
                       aspect={this.state.chartAspectWidth/this.state.chartAspectHeight}
                    />
                  ) : null}
                </div>
              ): null}

              {this.state.isLoaded && this.state.salesChart && this.state.salesChart.data && (1===2) ? (
                <div>
                  <div className="gutter-top-fixed-4"></div>
                  <div className="page-divide"></div>
                  <h5><span className="text-danger">Versus 2018-2019 Season</span> <small className="text-muted">Total {displaySalesMetric(this.state.sales_metric)} compared to same time period in 2018-2019. Excludes Off Broadway and Radio City.</small></h5>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className="gutter-top-fixed-2">
                      <p className="fs-mini text-muted text-center">Broadway.com</p>
                      <h4 className="text-center"><Displays a={this.state.results.dates[0].totals.sales[this.state.sales_metric]} b={this.state.results.dates[3].totals.sales[this.state.sales_metric]} format="percent" /></h4>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className="gutter-top-fixed-2">
                      <p className="fs-mini text-muted text-center">Industry</p>
                      <h4 className="text-center"><Displays a={this.state.results.dates[0].totals.grosses[this.state.sales_metric]} b={this.state.results.dates[3].totals.grosses[this.state.sales_metric]} format="percent" /></h4>
                    </Col>
                  </Row>
                  <h6 className="gutter-top-fixed-3"><span className="text-primary font-weight-bold">{this.props.engagement ? (<span>{this.props.engagement.show.name}</span>) : (<span>Industry</span>)}</span> and <span className="text-success font-weight-bold">Broadway.com</span> {displaySalesMetric(this.state.sales_metric)} +/- versus 2018-2019 season, by week. Horizontal black line represents flat versus 2018-2019 (0%).</h6>
                  {this.props.chart && this.state.salesChart.data.length > 0 ? (
                    this.state.print ? (
                      <LineChart data={this.state.salesChart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                        <defs>
                          <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                          </linearGradient>
                          <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                          </linearGradient>
                        </defs>
                        <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                        <YAxis tick={{fontSize: 10}} tickSize={8} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.salesChart.properties.min), dataMax => (dataMax * this.state.salesChart.properties.max) ]} />

                        <Line name="breakeven" type="monotone" dataKey="breakeven" stroke="#4e4e4e" strokeWidth={1} dot={false} />
                        <Line name="Industry" type="monotone" dataKey="industry" stroke="#0087a9" dot={{ stroke: '#0275d8', strokeWidth: 1 }} />
                        <Line name="Broadway.com" type="monotone" dataKey="bcom" stroke="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1 }} />
                      </LineChart>
                    ) : (
                      <ResponsiveContainer width='100%' aspect={this.state.chartAspectWidth/this.state.chartAspectHeight} >
                        <LineChart data={this.state.salesChart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                          <defs>
                            <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                            </linearGradient>
                            <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                            </linearGradient>
                          </defs>
                          <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                          <YAxis tick={{fontSize: 10}} tickSize={8} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.salesChart.properties.min), dataMax => (dataMax * this.state.salesChart.properties.max) ]} />

                          <Line name="breakeven" type="monotone" dataKey="breakeven" stroke="#ccc" strokeWidth={1} dot={false} />
                          <Line name="Industry" type="monotone" dataKey="industry" stroke="#0087a9" dot={{ stroke: '#0275d8', strokeWidth: 1 }} strokeWidth={1} label={<CustomizedLabel />} />
                          <Line name="Broadway.com" type="monotone" dataKey="bcom" stroke="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1 }} strokeWidth={1} label={<CustomizedLabel />} />
                        </LineChart>
                      </ResponsiveContainer>
                    )
                  ) : null}
                </div>
              ): null}

              <div className="gutter-top-fixed-4"></div>
              <div className="page-divide"></div>

              <h5><span className="text-danger">Market Share By Show</span> <small className="text-muted">Share of {displaySalesMetric(this.state.sales_metric)} by show. Comparisons to prior year's season where applicable.</small></h5>

              <Row className="gutter-top-fixed-2">
                {this.state.results.dates[0].shows.map((product, i) =>
                  i < 15 ? (
                    <Col xs={12} sm={4} md={4} lg={4} xl={4} key={i}>
                      <ProductCard print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                    </Col>
                  ) : null
                )}
              </Row>

              <Row className="gutter-top-fixed-2">
                {this.state.results.dates[0].shows.map((product, i) =>
                  (i >= 15) && (i < 30) ? (
                    <Col xs={12} sm={4} md={4} lg={4} xl={4} key={i}>
                      <ProductCard print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                    </Col>
                  ) : null
                )}
              </Row>

              <Row className="gutter-top-fixed-2">
                {this.state.results.dates[0].shows.map((product, i) =>
                  (i >= 30) && (i < 45) ? (
                    <Col xs={12} sm={4} md={4} lg={4} xl={4} key={i}>
                      <ProductCard print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                    </Col>
                  ) : null
                )}
              </Row>

              <div className="page-divide"></div>

              <Row className="gutter-top-fixed-2 ">
                {this.state.results.dates[0].shows.map((product, i) =>
                  (i >= 45) ? (
                    <Col xs={12} sm={4} md={4} lg={4} xl={4} key={i}>
                      <ProductCard print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                    </Col>
                  ) : null
                )}
              </Row>



            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwShare));
