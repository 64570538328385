import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Displays from '../../../../components/Displays';
import Poster from '../../../../components/Poster';

class WidgetAdvertiser extends React.Component {

  static propTypes = {
    advertiser: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  render() {

    return (

      <div>

        <div className="card gutter-bottom-fixed-4">
          <div className="card-body nopad nomargin">

            {this.props.advertiser ? (
              <div className="d-flex">
                
                {this.props.advertiser.product && this.props.advertiser.product.poster_id ? (
                  <Poster id={this.props.advertiser.product.poster_id} w={75} className="border-right img-poster" />
                ) : <Poster w={75} className="border-right img-poster" /> }
                
                <div className="flex-grow text-center">
                  <div className="text-center">
                    
                    <h6 className="gutter-top-fixed-1 gutter-bottom-fixed-2">
                      <span>{this.props.advertiser.product.short_name || this.props.advertiser.product.name}</span>
                    </h6>

                    <div>
                      <h4 className="text-center">$<Displays a={parseInt(this.props.advertiser.spend)} format="comma" /></h4>
                      {this.props.advertiser.sales ? (
                        <p className="fs-mini text-muted text-center">$<Displays a={parseInt(this.props.advertiser.sales.face)} format="comma" /> ({parseFloat((this.props.advertiser.spend/this.props.advertiser.sales.face)*100).toFixed(1)}%)</p>
                      ) : (
                        <p className="fs-mini text-muted text-center">--</p>
                      )} 
                    </div>

                  </div>
                </div>
              </div>
            ) : null }

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdvertiser));
