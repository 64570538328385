import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductCard from './cards/Product.js';

import {
  Row,
  Col
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

class WidgetAdminProducts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      print: false
    }

    this._isMounted = false;
    this.loadProducts = this.loadProducts.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadProducts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'PRODUCTS WIDGET')) {
      this.loadProducts();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadProducts() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/products',
        type: 'universal'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });

  }

  setPrint = () => { 
    this.setState({
      print: !this.state.print
    }, function() {
      //this.loadShare();
    });
  }

  render() {

    var title = <h5><span className="text-danger">Products</span> <small className="text-muted">Show editor.</small></h5>;
    return (

      <div>

        <Widget className="d-print-none mt-0 mb-1 bg-gray">
          <Row>
            <Col xs={4} sm={2} md={2} lg={2} xl={2}>
              <p className="fs-mini font-weight-bold">Options</p>
            </Col>
            <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-center">
              <p className="fs-mini mb-0 pb-0">Filter</p>
              <button className="header-link">All Shows</button>
            </Col>
            <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-center">
              <p className="fs-mini mb-0 pb-0">Format</p>
              <button className="header-link" onClick={() => this.setPrint()}>{this.state.print ? (<span>Print</span>) : ((<span>Display</span>))}</button>
            </Col>
          </Row>
        </Widget>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">

              {this.state.results.products.map((product, i) =>
               <ProductCard product={product} key={i} />
              )}
             
            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdminProducts));
