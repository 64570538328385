import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';

import { Row, Col } from 'reactstrap';

import { scurl, subSort } from '../../../core/utils.js';

import Loading from '../../../components/Loading';

import isScreen from '../../../core/screenHelper';

import UserCard from './cards/User.js';

class WidgetAdminUsers extends React.Component {

  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  }

  constructor(props) {
    super(props); 


    this.state = {
      isLoaded: false
    };

    this._isMounted = false;

    this.getUsers = this.getUsers.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  getUsers() {

    var opts = {
      path: '/v2/admin/users',
      type: 'simple'
    };

    //console.log(opts);

    scurl(opts, function(err, results) {
      if(this._isMounted) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {

          if(results) {

            subSort('first',results.users,0);

            
            this.setState({
              isLoaded: true,
              results: results,
            });
          } else {
            this.setState({
              error: {
                json: 'No data returned',
                status: -99,
                message: 'Error occured: No data returned'
              }
            });
          }
        }
      } else {
        console.log('handled unmount');
      }
    }.bind(this));
  }

  render() {
    return (

      <Widget title={
        <div className="d-flex justify-content-between flex-wrap">
          <h5><span>Users</span><small className="text-muted pl-2">Permission modeling.</small></h5>
        </div>
      }>
        
        <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
        
        {this.state.isLoaded ? (

          <div className="gutter-top-fixed-2 gutter-bottom-fixed-2">

            <Row className="gutter-top-fixed-2">
              <Col xs={12} sm={6} md={6} lg={4} xl={3} className="">
                <br/>NAME
              </Col>
              <Col xs={12} sm={2} md={2} lg={3} xl={3} className="text-center">
                <br/>TOKENS
              </Col>
              <Col xs={12} sm={2} md={2} lg={3} xl={3} className="text-center">
                <br/>AFFILIATE
              </Col>
              <Col xs={12} sm={2} md={2} lg={2} xl={3} className="text-center">
                <br/>LOGINS
              </Col>
            </Row>

            <hr/>

            {this.state.results.users.map((user, i) =>
              <div key={i}>
                <UserCard key={i} print={this.props.print} user={user} />
              </div>
            )}
          
          </div>
          
        ) : null}

        <span>
          {isScreen('xs') ? "xs" : null}
          {isScreen('sm') ? "sm" : null}
          {isScreen('md') ? "md" : null}
          {isScreen('lg') ? "lg" : null}
          {isScreen('xl') ? "xl" : null}
        </span>

      </Widget>
  )}

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdminUsers));
