import React, { useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';

import { formatNumber } from '../../../../core/utils';

import Displays from '../../../../components/Displays';
import Poster from '../../../../components/Poster';

const SemCampaign = (props) => {

  const [expand, setExpand] = useState(false);

  const handleExpand = function() {
    setExpand(!expand);
    const section = document.querySelector('#block_' + props.campaign.slug);
    if(section) {
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }

  return (
    <div>
      <Row className={expand ? 'bg-dark text-white p-3' : (props.campaign.years[0].per_order.search_profit < 0) ? '' : 'gutter-top-fixed-3'} id={'block_' + props.campaign.slug}>
        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
          <Row className="">
            <Col xs={3} sm={3} md={3} lg={2} xl={2} className="d-print-none">
              <h6>
                <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => handleExpand(props.campaign.slug)}>
                  <i className={'fa-light ' + (expand ? 'fa-minus' : 'fa-plus')} />
                </Button>
              </h6>
            </Col>
            <Col xs={9} sm={9} md={9} lg={10} xl={10}>
              {props.campaign.show ? (
                <Row>
                  <Col xs={12} sm={3} md={3} lg={3} xl={2}>
                    {props.campaign.show && props.campaign.show.poster_id ? (
                      <Poster id={props.campaign.show.poster_id} w={75} className="img-fluid" />
                    ) : (
                      <Poster w={75} />
                    )}
                  </Col>
                  <Col xs={12} sm={9} md={9} lg={9} xl={10}>
                    <h6 className="">{props.campaign.show.name}</h6>
                    <p className={'text-upper ' + (expand ? ' fw-bold' : '')}>
                      
                      {props.campaign.breadcrumbs.geo ? (
                        props.campaign.breadcrumbs.geo === 'us' ? (
                          <span className="text-muted">Domestic</span>
                        ) : (
                          props.campaign.breadcrumbs.geo === 'ny' ? (
                            <span className="text-muted">New York</span>
                          ) : (
                            <span className="text-muted">--</span>
                          )
                        )
                      ) : (
                        <span>{props.campaign.name}</span>
                      )}
                      <p className="mt-1 text-muted">Spend: {formatNumber('$X', props.campaign.years[0].spend, false)}</p>
                    </p>
                    
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className={'text-capitalize ' + (expand ? ' fw-bold' : '')}>
                      {props.campaign.name}
                    </p>
                    <p className="m-0 p-0 gutter-top-fixed-1 text-muted">Spend: {formatNumber('$X', props.campaign.years[0].spend, false)}</p>
                  </Col>
                </Row>
              )}
              
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>{formatNumber('$X.XX', props.campaign.years[0].per_order.spend, false)}</h5>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>{formatNumber('$X.XX', props.campaign.years[0].per_order.search_fees, false)}</h5>
          <p className="m-0 p-0 gutter-top-fixed-1 text-muted">ROAS {formatNumber('X.X', props.campaign.years[0].service_fee_roi, false)}x</p>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>{formatNumber('$X.XX', props.campaign.years[0].per_order.net, false)}</h5>
          <p className="m-0 p-0 gutter-top-fixed-1 text-muted">ROAS {formatNumber('X.X', props.campaign.years[0].net_roi, false)}x</p>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>{formatNumber('$X.XX', props.campaign.years[0].per_order.search_profit, false)}</h5>        
        </Col>
      </Row>

      {expand ? (
          <div className="w-100">
            <Row className="gutter-top-fixed-4">
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Search Metrics</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                  <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="33%">Metric</th>
                          <th className="text-center" width="33%">Value</th>
                          <th className="text-center" width="33%">PY +/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Impressions</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.campaign.years[0].impressions, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].impressions} b={props.campaign.years[1].impressions} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Click Thru Rate</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X.X%', props.campaign.years[0].ctr, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].ctr} b={props.campaign.years[1].ctr} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Cost Per Click</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X.XX', props.campaign.years[0].cpc, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].cpc} b={props.campaign.years[1].cpc} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Funnel Metrics</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="33%">Metric</th>
                          <th className="text-center" width="33%">Value</th>
                          <th className="text-center" width="33%">PY +/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Starts</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.campaign.years[0].clicks, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].clicks} b={props.campaign.years[1].clicks} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Conversion %</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X.X%', props.campaign.years[0].conversion_percentage, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].conversion_percentage} b={props.campaign.years[1].conversion_percentage} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Orders</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.campaign.years[0].orders, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].orders} b={props.campaign.years[1].orders} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                </Table>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Revenue</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="33%">Metric</th>
                          <th className="text-center" width="33%">Value</th>
                          <th className="text-center" width="33%">PY +/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Service Fees</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X', props.campaign.years[0].service_fees, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].service_fees} b={props.campaign.years[1].service_fees} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>After Access + CC Fees</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X', props.campaign.years[0].net, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].net} b={props.campaign.years[1].net} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>After Search Spend</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X', props.campaign.years[0].profit, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.campaign.years[1] ? (
                            <h6><Displays a={props.campaign.years[0].profit} b={props.campaign.years[1].profit} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}

        <hr/>

    </div>
  );
};

export default SemCampaign;