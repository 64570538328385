import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';

import { Row, Col } from 'reactstrap';

import { scurl, subSort } from '../../../core/utils.js';

import Loading from '../../../components/Loading';

import StationCard from './cards/Station.js';

class WidgetCmsStations extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  }

  constructor(props) {
    super(props); 


    this.state = {
      isLoaded: false
    };

    this._isMounted = false;

    this.getStations = this.getStations.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getStations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  getStations() {

    var opts = {
      path: '/v2/geo/stations',
      type: 'simple'
    };

    //console.log(opts);

    scurl(opts, function(err, results) {
      if(this._isMounted) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {

          if(results) {

            subSort('order',results.stations,0);
  
            this.setState({
              isLoaded: true,
              results: results,
            });
          } else {
            this.setState({
              error: {
                json: 'No data returned',
                status: -99,
                message: 'Error occured: No data returned'
              }
            });
          }
        }
      } else {
        console.log('handled unmount');
      }
    }.bind(this));
  }

  render() {
    return (

      <Widget title={
        <div className="d-flex justify-content-between flex-wrap">
          <h5><span>Broadway Show Stations</span><small className="text-muted pl-2">Manage information returned when local station is found.</small></h5>
        </div>
      }>
        
        <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
        
        {this.state.isLoaded ? (

          <div className="gutter-top-fixed-2 gutter-bottom-fixed-2">

            <Row className="gutter-top-fixed-2">
              <Col xs={12} sm={6} md={6} lg={4} xl={3} className="">
                <br/>MARKET
              </Col>
              <Col xs={12} sm={2} md={2} lg={3} xl={3}>
                <br/>NAME
              </Col>
              <Col xs={12} sm={2} md={2} lg={3} xl={3} className="text-center">
                <br/>
              </Col>
              <Col xs={12} sm={2} md={2} lg={2} xl={3} className="text-center">
                <br/>
              </Col>
            </Row>

            <hr/>

            {this.state.results.stations.map((station, i) =>
              <div key={i}>
                <StationCard key={i} print={this.props.print} station={station} user={this.props.user} />
              </div>
            )}
          
          </div>
          
        ) : null}

      </Widget>
  )}

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetCmsStations));
