import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay } from '../../../core/utils';

import DataPointCard from '../../cards/DataPoint.js';
import ChartLegend from '../../cards/Legend.js';

import DashCam from '../../universal/DashCam/DashCam.js';

class WidgetContentSessions extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 3,
    lookback: 30,
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    this.state = {
      isLoaded: false,
      isError: false,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      dateRangeDisplay: drd,
      col_lg: 6
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SESSIONS WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.skai_filter !== this.props.skai_filter) {
        this.loadData();
      }
      if(prevProps.print !== this.props.print) {
        this.setState({
          col_lg: 12
        });
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/ga/sessions',
        type: 'universal',
        years: this.props.fixed_years,
        lookback: this.state.lookback
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                this.setDashCam();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  setDashCam() {

    let dashCamData = {
      results: this.state.results,
      headline: {
        title: 'visits',
        value: this.state.results.totals[0].total,
        format: 'X,',
        yoy: parseFloat((this.state.results.totals[0].total-this.state.results.totals[1].total)/this.state.results.totals[1].total)
      },
      panels: [
        {
          title: 'Paid Search',
          value: this.state.results.totals[0].cpc,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].cpc-this.state.results.totals[1].cpc)/this.state.results.totals[1].cpc)
        },
        {
          title: 'Organic',
          value: this.state.results.totals[0].organic,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].organic-this.state.results.totals[1].organic)/this.state.results.totals[1].organic)
        },
        {
          title: 'Direct',
          value: this.state.results.totals[0].direct,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].direct-this.state.results.totals[1].direct)/this.state.results.totals[1].direct)
        },
        {
          title: 'Social',
          value: this.state.results.totals[0].social,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].social-this.state.results.totals[1].social)/this.state.results.totals[1].social)
        },
        {
          title: 'Email',
          value: this.state.results.totals[0].email,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].email-this.state.results.totals[1].email)/this.state.results.totals[1].email)
        },
        {
          title: 'Other',
          value: this.state.results.totals[0].other_referrals,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].other_referrals-this.state.results.totals[1].other_referrals)/this.state.results.totals[1].other_referrals)
        }
      ]
    };

    this.setState({
      ...this.state,
      dashCamData: dashCamData
    });
  };

  render() {

    var title = <h5>Title</h5>
    this.props.dashboard ? (
      title = <h5>
        <span>Traffic</span>
        <small className="text-muted pl-2">excludes checkout</small>
      </h5>
    ) : (
      title = <h5><span className="text-danger">Website Sessions</span> <small className="text-muted">Unique starts on Broadway.com by source (excludes affiliates and checkout pages). Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>
    )

    return (

      this.props.dashboard ? (

        <Widget 
          title={
            <h5>
              <span>Traffic</span>
              <small className="text-muted pl-2">vs prior year</small>
            </h5>
          } 
          className="mb-0 h-100" 
          bodyClass={`mt p-4`}
        >
          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
          <DashCam 
            isLoaded={this.state.isLoaded} 
            print={this.props.print} 
            results={this.state.results} 
            title="Traffic" 
            description="versus prev year" 
            lookback={this.state.lookback} 
            payload={this.state.dashCamData}
            more={{url: 'content', tab: 0}}
          />
        </Widget>

        // <Widget title={title} className="mb-0 h-100" bodyClass={`mt p-4`}>

        //   <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

        //   {this.state.isLoaded ? (
        //     <>
        //       <h4 className="fw-semi-bold mb-lg px-4"><Displays a={this.state.results.totals[0].total} format="comma" /> <span className="fw-normal text-muted fs-mini">sessions</span></h4>
        //       <div className="d-flex border-top">
        //         <div className="w-50 border-right py-3 px-4">
        //           <div className="d-flex align-items-start h3">
        //             <h6>{parseFloat((this.state.results.totals[0].cpc/this.state.results.totals[0].total)*100).toFixed(1)}%</h6>
        //             <i
        //               className={`arrowAlign la la-arrow-right ms-2 text-${((this.state.results.totals[0].cpc/this.state.results.totals[0].total)>(this.state.results.totals[1].cpc/this.state.results.totals[1].total)) ? 'success' : 'danger'}
        //               rotate-${((this.state.results.totals[0].cpc/this.state.results.totals[0].total)>(this.state.results.totals[1].cpc/this.state.results.totals[1].total)) ? '315' : '45'}`}
        //             />
        //           </div>
        //           <p className="text-muted mb-0 me-2"><small>CPC</small></p>
        //         </div>
        //         <div className="w-50 py-3 pl-4">
        //           <div className="d-flex align-items-start h3">
        //             <h6>{parseFloat((this.state.results.totals[0].organic/this.state.results.totals[0].total)*100).toFixed(1)}%</h6>
        //             <i
        //               className={`arrowAlign la la-arrow-right me-2 text-${((this.state.results.totals[0].organic/this.state.results.totals[0].total)>(this.state.results.totals[1].organic/this.state.results.totals[1].total)) ? 'success' : 'danger'}
        //               rotate-${((this.state.results.totals[0].organic/this.state.results.totals[0].total)>(this.state.results.totals[1].organic/this.state.results.totals[1].total)) ? '315' : '45'}`}
        //             />
        //           </div>
        //           <p className="text-muted mb-0 me-2"><small>Organic</small></p>
        //         </div>
        //       </div>
        //       <div className="d-flex border-top">
        //         <div className="w-50 border-right py-3 px-4">
        //           <div className="d-flex align-items-start h3">
        //             <h6>{parseFloat((this.state.results.totals[0].direct/this.state.results.totals[0].total)*100).toFixed(1)}%</h6>
        //             <i
        //               className={`arrowAlign la la-arrow-right ms-2 text-${((this.state.results.totals[0].direct/this.state.results.totals[0].total)>(this.state.results.totals[1].direct/this.state.results.totals[1].total)) ? 'success' : 'danger'}
        //               rotate-${((this.state.results.totals[0].direct/this.state.results.totals[0].total)>(this.state.results.totals[1].direct/this.state.results.totals[1].total)) ? '315' : '45'}`}
        //             />
        //           </div>
        //           <p className="text-muted mb-0 me-2"><small>Direct</small></p>
        //         </div>
        //         <div className="w-50 py-3 pl-4">
        //           <div className="d-flex align-items-start h3">
        //             <h6>{parseFloat((this.state.results.totals[0].social/this.state.results.totals[0].total)*100).toFixed(1)}%</h6>
        //             <i
        //               className={`arrowAlign la la-arrow-right me-2 text-${((this.state.results.totals[0].social/this.state.results.totals[0].total)>(this.state.results.totals[1].social/this.state.results.totals[1].total)) ? 'success' : 'danger'}
        //               rotate-${((this.state.results.totals[0].social/this.state.results.totals[0].total)>(this.state.results.totals[1].social/this.state.results.totals[1].total)) ? '315' : '45'}`}
        //             />
        //           </div>
        //           <p className="text-muted mb-0 me-2"><small>Social</small></p>
        //         </div>
        //       </div>
        //       <div className="d-flex border-top">
        //         <div className="w-50 border-right py-3 px-4">
        //           <div className="d-flex align-items-start h3">
        //             <h6>{parseFloat((this.state.results.totals[0].email/this.state.results.totals[0].total)*100).toFixed(1)}%</h6>
        //             <i
        //               className={`arrowAlign la la-arrow-right ms-2 text-${((this.state.results.totals[0].email/this.state.results.totals[0].total)>(this.state.results.totals[1].email/this.state.results.totals[1].total)) ? 'success' : 'danger'}
        //               rotate-${((this.state.results.totals[0].email/this.state.results.totals[0].total)>(this.state.results.totals[1].email/this.state.results.totals[1].total)) ? '315' : '45'}`}
        //             />
        //           </div>
        //           <p className="text-muted mb-0 me-2"><small>Email</small></p>
        //         </div>
        //         <div className="w-50 py-3 pl-4">
        //           <div className="d-flex align-items-start h3">
        //             <h6>{parseFloat((this.state.results.totals[0].other_referrals/this.state.results.totals[0].total)*100).toFixed(1)}%</h6>
        //             <i
        //               className={`arrowAlign la la-arrow-right me-2 text-${((this.state.results.totals[0].other_referrals/this.state.results.totals[0].total)>(this.state.results.totals[1].other_referrals/this.state.results.totals[1].total)) ? 'success' : 'danger'}
        //               rotate-${((this.state.results.totals[0].other_referrals/this.state.results.totals[0].total)>(this.state.results.totals[1].other_referrals/this.state.results.totals[1].total)) ? '315' : '45'}`}
        //             />
        //           </div>
        //           <p className="text-muted mb-0 me-2"><small>Referrers</small></p>
        //         </div>
        //       </div>
        //     </>
        //   ) : null}

        //   <More url="/app/content" tab={0} />
          
        // </Widget>

      ) : (

        <div className="page-divide">

          <Widget title={title}>

            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

            {this.state.isLoaded ? (
              <>

                <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" lookback={this.state.lookback} />

                <Row className="gutter-top-fixed-2 gutter-bottom-fixed-2">
                  <DataPointCard print={this.props.print} results={this.state.results} title="Total" field="total" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="CPC" field="cpc" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Organic" field="organic" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Direct" field="direct" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Social" field="social" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Email" field="email" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="3rd Party Sites" field="other_referrals" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                </Row>
              </>
            ) : null}
          </Widget>


        </div>

      )
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetContentSessions));
