import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';

class ComponentMore extends React.Component {

  static propTypes = {
    url: PropTypes.string,
    tab: PropTypes.number,
    className: PropTypes.string,
    perf_date: PropTypes.bool
  };

  static defaultProps = {
    url: '/app',
    tab: 0,
    className: '',
    perf_date: false
  };

  render() {

    return (

      <div className={cx('text-right', this.props.className)}>
        <Link to={{ pathname: this.props.url, state: {tab: this.props.tab, perf_date: this.props.perf_date} }} className="more-link">
          View Detail &#8594;
        </Link>
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(ComponentMore));
