import moment from 'moment';

import { 
	SET_FILTER,
	UPDATE_FILTER
} from '../actions/filter';

const initialState = {
	dates: {
		type: 1,
		id: 10,
		display: 'Last 7 Days',
		start: '',
		end: ''
	},
	limit: {
		engagements: [],
		owners: [],
		sources: [],
		devices: [],
		countries: [],
		experiments: [],
		mediums: [],
		refunds: false,
		chargeback: false
	},
	exclude: {
		engagements: [],
		owners: [],
		sources: [],
		devices: [],
		countries: [],
		mediums: [],
		refunds: false,
	},
	refresh: false,
	updated: moment().format('YYYY-MM-DD HH:mm:ss'),
	loaded: false
};

export default function runtime(state = initialState, action) {
  switch (action.type) {

		case SET_FILTER:
			state = initialState;
			return Object.assign({}, state, {
				...state
			});

		case UPDATE_FILTER:
			return Object.assign({}, state, {
				...state,
				dates: action.filter.dates,
				limit: action.filter.limit,
				exclude: action.filter.exclude,
				refresh: true,
				updated: moment().format('YYYY-MM-DD HH:mm:ss')
			});

		default:
			return state;
  }
}
