import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon/build/node/luxon';

import cx from 'classnames';
import s from './Bar.module.scss';

import { formatNumber, objectEquals } from '../../../core/utils';

import Chart from '../../charts/Chart';

import { XAxis, YAxis, BarChart, Bar, Cell, LabelList } from 'recharts';

import isScreen from '../../../core/screenHelper';
import { themeColors } from '../../../core/colors';

class UniversalBar extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    data: PropTypes.object,
    chart_width: PropTypes.number,
    chart_height: PropTypes.number,
    chart_aspect_width: PropTypes.number,
    chart_aspect_height: PropTypes.number,
    labels: PropTypes.bool,
    grid: PropTypes.bool,
    format: PropTypes.string
  };

  static defaultProps = {
    print: false,
    chart_width: 350,
    chart_height: 170,
    chart_aspect_width: 1.6,
    chart_aspect_height: 1,
    labels: true,
    grid: true,
    format: 'X,'
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      print: this.props.print,
      chart_width: this.props.chart_width,
      chart_height: this.props.chart_height,
      chart_aspect_width: this.props.chart_aspect_width,
      chart_aspect_height: this.props.chart_aspect_height,
      labels: this.props.labels,
      grid: this.props.grid,
      format: this.props.format
    }

    this._isMounted = false;
    this.chartDataFormat = this.chartDataFormat.bind(this);
    this.chartDateFormat = this.chartDateFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setChartAspect();
    this.setChart();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(!objectEquals(prevProps,this.props)) {
      this.setChart();
    }
  }

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  chartDataFormat(value) {
    return formatNumber(this.props.format,value);
  };

  chartDateFormat(x) {
    return DateTime.fromISO(x).toFormat('MM-dd');
  };

  setChartAspect() {

    if(this.props.print) {
      this.setState({
        ...this.state,
        chart_width: 450,
        chart_height: 170,
        chart_aspect_width: 2,
        chart_aspect_height: 2
      });
    } else {
      if((isScreen('xs') || isScreen('sm'))) {
        this.setState({
          ...this.state,
          chart_width: 350,
          chart_height: 150,
          chart_aspect_width: 2,
          chart_aspect_height: 1,
          chart_font_size: 10
        });
      } else {
        if((isScreen('md') || isScreen('lg'))) {
          this.setState({
            ...this.state,
            chart_width: 350,
            chart_height: 150,
            chart_aspect_width: 2,
            chart_aspect_height: 2,
            chart_font_size: 10
          });
        } else {
          this.setState({
            ...this.state,
            chart_width: 350,
            chart_height: 150,
            chart_aspect_width: this.props.chart_aspect_width,
            chart_aspect_height: this.props.chart_aspect_height,
            chart_font_size: 12
          });
        }
      }
    }
  }

  setChart() {

    let lookback = false;

    var chartData = [];

    if(this.props.data.totals[0].lookback) {
      var lb = {
        name: 'Prior ' + this.props.data.dates[0].lookback.days  + ' Days',
      }

      if(this.props.parent) {
        lb.val = this.props.data.totals[0].lookback[this.props.parent][this.props.field];
      } else {
        lb.val = this.props.data.totals[0].lookback[this.props.field];
      }
      
      chartData.push(lb);

      lookback = true;
    }

    this.props.data.totals.forEach(function(block, i) {
      
      var d = {
        name: this.props.data.dates[i].year,
      }

      if(this.props.parent) {
        d.val = block[this.props.parent][this.props.field];
      } else {
        d.val = block[this.props.field];
      }

      //NO NEGATIVES
      if(d.val < 0) {
        d.val = 0;
      }
      
      chartData.push(d);

    }.bind(this));

    let max = 1;
    let min = 0.5;

    let colors = [];
    if(lookback) {
      colors.push(themeColors.lookback);
    }
    colors = colors.concat(themeColors.blue);
    
    this.setState({
      ...this.state,
      isLoaded: true,
      chart: {
        data: chartData,
        properties: {
          max: max,
          min: min
        },
        colors: colors
      }
    });
  }

  render() {

    return (
      <div className={cx(s.root, this.props.className)}>
        {this.state.isLoaded? (
          <Chart
            chart ={
              <BarChart width={this.state.chart_width} height={this.state.chart_height} data={this.state.chart.data} margin={{ left: 10, right: 42}} padding={{top: 0, right: 0, left: 0, bottom: 0}} layout="vertical">
                <XAxis type="number" axisLine={false} domain={[dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max)]} hide />
                <YAxis 
                  orientation="right"
                  type="category" 
                  dataKey="val" 
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={val => this.chartDataFormat(val)}
                  mirror
                  tick={{
                    transform: `translate(${50}, 0)`,
                    fontSize: this.state.chart_font_size
                  }} />
                <Bar dataKey="val">
                  <LabelList
                    dataKey="name"
                    position="insideLeft"
                    angle={0}
                    offset={25}
                    fill="#999"
                    tick={{
                      fontSize: 10
                    }}
                  />
                  {this.state.chart.data.map((entry, i) => (
                    <Cell key={`cell-${i}`} fill={this.state.chart.colors[i]} />
                  ))}
                </Bar>
              </BarChart>
            }
            print={this.state.print}
            chart_aspect_width={this.state.chart_aspect_width}
            chart_aspect_height={this.state.chart_aspect_height}
          />
        ) : null}
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(UniversalBar));
