import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';

import { filterUpdated } from '../../core/utils';

import { updateFilter } from '../../actions/filter';

class ComponentYears extends React.Component {
  
  static propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
  };

  static defaultProps = {
    start: 2000,
    end: 2018,
  };

  constructor(props) {
      
    super(props);

    this.state = {
      years: []
    };

    this.setYears = this.setYears.bind(this);
  }

  componentDidMount() {
    this.setYears();
  }

  changeYear = (year) => { 
    
    var before = Object.assign({}, this.props.filter);

    var start = moment(year,'YYYY').startOf('year').format('YYYY-MM-DD');
    var end = moment(year,'YYYY').endOf('year').format('YYYY-MM-DD');
    var dates = {
      id: 333,
      start: start,
      end: end,
      display: year.toString()
    }

    before.dates = dates;
    this.props.dispatch(updateFilter(before));
  };

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'YEARS COMPONENT')) {
      this.setYears();
    }
  }

  setYears() {
    var years = [];
    var y = this.props.start;
    while(y <= this.props.end) {

      var year = {
        year: y,
        start: moment(y,'YYYY').startOf('year').format('YYYY-MM-DD'),
        end: moment(y,'YYYY').endOf('year').format('YYYY-MM-DD')
      };

      if( (this.props.filter.dates.start === year.start) &&  (this.props.filter.dates.end === year.end) ) {
        year.active = true;
      } else {
        year.active = false;
      }

      years.push(year);
      y++;
    }

    this.setState({
      years: years
    });
  };

  render() {

    return (
      <section className="card">

        <div className="text-center p-4">
          <h6>YEARS</h6>

          {this.state && this.state.years ? (
            this.state.years.map((year, i) =>
              <span key={i} className="nopad nomargin">
                {year.active ? (
                  <span>{year.year}</span>
                ) : (
                  <button className="header-link" onClick={() => this.changeYear(year.year)}>{year.year}</button>
                )}
                {(i < this.state.years.length - 1) ? (
                  <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                ) : null }
              </span>
            )
          ) : null }
        </div>

      </section>
    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(ComponentYears);
