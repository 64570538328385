import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from 'moment';

import { Row, Col } from 'reactstrap';

import isScreen from '../../../../core/screenHelper';

import { nFormatter } from '../../../../core/utils';

import { ResponsiveContainer, Line, LineChart, XAxis, YAxis, Legend} from 'recharts';
class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value} = this.props;

    if(value < 1) {
      return (
        <text x={x} y={y} dx={0} dy={-8} fill='#777' fontSize={10} textAnchor="middle">
          {parseFloat(value*100).toFixed(1) + '%'}
        </text>
      );
    } else {
      if(value > 1000) {
        return (
          <text x={x} y={y} dx={0} dy={-8} fill='#777' fontSize={10} textAnchor="middle">
            ${nFormatter(value)}
          </text>
        );
      } else {
        return (
          <text x={x} y={y} dx={0} dy={-8} fill='#777' fontSize={10} textAnchor="middle">
            ${parseFloat(value).toFixed(0)}
          </text>
        );
      }
    }


  }
}
class ChartMagic extends PureComponent {
  render() {
    const { chart, print, aspect } = this.props;

    if(print) {
      return (
        <div>{chart}</div>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" aspect={aspect}>
          {chart}
        </ResponsiveContainer>
      );
    }
  }
}

class MarginCard extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    metric_key: PropTypes.string,
    metric_name: PropTypes.string,
    cy_value: PropTypes.string,
    prior_value: PropTypes.string,
    impact: PropTypes.number,
    notes: PropTypes.string,
    weeks: PropTypes.array
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      chartAspectWidth: 5,
      chartAspectHeight: 1,
    }

    this._isMounted = false;
    this.setChartAspect = this.setChartAspect.bind(this);
    this.setChart = this.setChart.bind(this);
    this.chartDataFormat = this.chartDataFormat.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setChartAspect();
    this.setChart();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: 5,
        chartAspectHeight: 1
      });
    }
  }

  setChart() {
    var chartData = [];

    if(this.props.weeks && this.props.weeks.length > 0) {

      this.props.weeks.forEach(function(block, i) {
        
        var d = {
          name: block.key,
          years: []
        };

        for(var year of block.years) {

          d.years.push(year[this.props.metric_key]);

          // switch(this.props.metric_key) {
          //   case 'margin':
          //     d.years.push(year.margin)
          //     break;
          //   case 'average_price':
          //     d.years.push(year.average_price)
          //     break;
          //   case 'average_fee':
          //     d.years.push(year.average_fee)
          //     break;
          //   default:
          //     break;
          // }
        }

        d.year1 = d.years[0];
        d.year2 = d.years[1];
        //d.year3 = d.years[2];
        d.year4 = d.years[3];

        chartData.push(d);
 
      }.bind(this));

      var min = 0.95;
      var max = 1.05;

      //console.log(chartData);

      this.setState({
        chart: {
          data: chartData,
          properties: {
            max: max,
            min: min,
            height: 500,
            width: 1000
          }
        }
      });
    }
  }

  chartDataFormat(x) {

    var display = x;

    switch(this.props.metric_key) {
      case 'margin':
        display = parseFloat(x*100).toFixed(1) + '%';
        break;
      default:
        display = parseFloat(x).toFixed(2);
        break;
    }

    return display;
  };

  chartDateFormat(x) {
    return moment(x).format('M/DD');
  };

  render() {

    return (

      <div>


          <div className="">
            <h6 className="">{this.props.metric_name}</h6>
            <Row className="gutter-top-fixed-2">
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <p className="fs-mini text-muted text-center">Current Year</p>
                  <h5 className="text-center text-success">{this.props.cy_value}</h5>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <p className="fs-mini text-muted text-center">2019</p>
                  <h5 className="text-center text-muted fw-normal">{this.props.prior_value}</h5>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <p className="fs-mini text-muted text-center">Margin Impact</p>
                  <h5 className="text-center text-muted fw-normal">${parseFloat(this.props.impact).toFixed(2)}</h5>
                </Col>
            </Row>

            {this.state.chart && this.state.chart.data.length > 0 ? (
              <div>
                <ChartMagic
                  chart = {
                    <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.state.chart.properties.height} width={this.state.chart.properties.width} >
                      <defs>
                        <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                        </linearGradient>
                        <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                        </linearGradient>
                      </defs>
                      <XAxis tickFormatter={this.chartDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                      <YAxis tick={{fontSize: 10}} tickSize={8} tickFormatter={this.chartDataFormat} domain={[ dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max) ]} />

                      <Line name="21-22" type="monotone" dataKey="year1" stroke="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1 }} strokeWidth={2} metric_key={this.props.metric_key} label={<CustomizedLabel />} />
                      
                      {this.state.chart.data[this.state.chart.data.length-1].year2 ? (
                        <Line name="20-21" type="monotone" dataKey="year2" stroke="#ccc" dot={{ stroke: '#ccc', strokeWidth: 1 }} />
                      ) : null }
        
                      {this.state.chart.data[0].year3 ? (
                        <Line name="19-20" type="monotone" dataKey="year3" stroke="#0087a9" dot={{ stroke: '#0087a9', strokeWidth: 1 }} label={<CustomizedLabel />} />
                      ) : null }
                      {this.state.chart.data[0].year4 ? (
                        <Line name="18-19" type="monotone" dataKey="year4" stroke="#ffc647" dot={{ stroke: '#ffc647', strokeWidth: 1 }} />
                      ) : null }
                      {this.state.chart.data[0].year5 ? (
                        <Line name="17-18" type="monotone" dataKey="year5" stroke="#754c7f" dot={{ stroke: '#754c7f', strokeWidth: 1 }} />
                      ) : null }
                      {this.state.chart.data[0].year6 ? (
                        <Line name="16-17" type="monotone" dataKey="year6" stroke="#db3920" dot={{ stroke: '#db3920', strokeWidth: 1 }} />
                      ) : null }

                      {this.state.chart.data[0].year2 ? (
                        <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                      ) : null }
                    </LineChart>
                  }
                    print={this.props.print}
                    aspect={this.state.chartAspectWidth/this.state.chartAspectHeight}
                />
              </div>
            ) : null}
            
            <p className="gutter-top-fixed-2 fs-xs text-muted">{this.props.notes}</p>

          </div>
        </div>
                          

      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(MarginCard));
