import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CoreDashboard from '../../pages/dashboard';
import CoreDashboardAffiliate from '../../pages/dashboardAffiliate';

import CoreProduct from '../../pages/product';
import CoreProductAffiliate from '../../pages/productAffiliate';

import CoreTransactions from '../../pages/transactions';
import CoreSkeptic from '../../pages/skeptic';
import CoreSem from '../../pages/sem';
import CoreAdsales from '../../pages/adsales';
import CorePrint from '../../pages/print';
import CoreIndustry from '../../pages/industry';
import CoreConversions from '../../pages/conversions';
import CoreGa from '../../pages/ga';
import CoreContent from '../../pages/content';
import CoreOpsis from '../../pages/opsis/home';
import CoreOpsisAdmin from '../../pages/opsis/admin';
import CoreCms from '../../pages/cms';
import CoreFinance from '../../pages/finance';
import CoreKnown from '../../pages/known';

import CoreAdmin from '../../pages/admin';
import CoreExperiments from '../../pages/experiments';
import CoreProducts from '../../pages/products';

// import CoreOpsisVenue from '../../pages/opsis/venue';
// import CoreOpsisWeek from '../../pages/opsis/week';


import { scurl, dateShortcut } from '../../core/utils.js';

import { updateFilter } from '../../actions/filter';

import Header from '../Header';
import Filter from '../Filter';
import FilterThin from '../FilterThin';
import s from './Layout.module.scss';

import Cookies from 'js-cookie';
class Layout extends React.Component {
  
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.loadUser = this.loadUser.bind(this);
    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {
    this.loadUser();

    var before = Object.assign({}, this.props.filter);

    if(before && before.dates && (before.dates.id !== 99)) {
      var set = {
        id: this.props.filter.dates.id
      };
  
      var dates = dateShortcut(set);
      before.dates = dates;
      this.props.dispatch(updateFilter(before));
    }
    
  }

  loadUser() {
    this.setState({
      isLoaded: false
    }, function() {
      scurl({
        path: '/v2/users/token',
        type: 'simple'
      }, function(err, results) {
        if(err) {
          this.doLogout()
        } else {
          if(results.user.permissions && results.user.permissions.pages) {
            this.setState({
              isLoaded: true,
              user: results.user
            });
          } else {
            this.doLogout();
          }
        }
      }.bind(this));
    });
  }

  doLogout() {    
    Cookies.remove("sh_token");
    localStorage.removeItem("sh_state");
    this.props.history.push('/#/login');
  }

  render() {

    const CustomRoute = (props) => {
      if(this.state.user.permissions.pages[props.page].allow) {
        return <Route {...props} />
      } else {
        return false;
      }  
    }

    const CustomDashboard = (props) => {
      if(this.state.user.permissions.pages.dashboard.allow) {
        switch(this.state.user.permissions.pages.dashboard.page) {
          case 'dashboard':
            return <Route {...props} render={() => <CoreDashboard user={this.state.user} />} />
          case 'affiliate':
            return <Route {...props} render={() => <CoreDashboardAffiliate user={this.state.user} />} />
          case 'transactions':
            return <Route {...props} render={() => <CoreTransactions user={this.state.user} />} />
          case 'finance':
            return <Route {...props} render={() => <CoreFinance user={this.state.user} />} />
          case 'cms':
            return <Route {...props} render={() => <CoreCms user={this.state.user} />} />
          case 'known':
            return <Route {...props} render={() => <CoreKnown user={this.state.user} />} />
          default:
            return false;
        }
      } else {
        return false;
      }  
    }

    const CustomProduct = (props) => {
      if(this.state.user.permissions.pages.product.allow) {
        switch(this.state.user.permissions.pages.product.version) {
          case 1:
            return <Route {...props} render={() => <CoreProduct user={this.state.user} />} />
          case 2:
            return <Route {...props} render={() => <CoreProductAffiliate user={this.state.user} />} />
          default:
            return false;
        }
      } else {
        return false;
      }  
    }

    return (
      <div className={s.root} >
        {this.state.isLoaded && this.state.user ? (
          <div className={s.wrap}>
            <Header user={this.state.user} />
            {this.state.user.permissions && this.state.user.permissions.filter && this.state.user.permissions.filter.allow && this.state.user.permissions.filter.filters ? (
              this.state.user.permissions.filter.version === 2 ? (
                <Filter user={this.state.user} />
              ) : (
                <FilterThin user={this.state.user} />
              )
            ) : null }

              <main className={s.content}>
                <TransitionGroup>
                  <CSSTransition
                    key={this.props.location.pathname}
                    classNames="fade"
                    timeout={200}
                  >
                    <Switch>

                      <Route path="/app" exact render={() => <Redirect to="/app/dashboard" />} />

                      <CustomDashboard exact path='/app/dashboard' />
                      <CustomDashboard exact path='/app/dashboard/:tab' />

                      <CustomProduct exact path='/app/products/:id' />
                      <CustomProduct exact path='/app/products/:id/:tab' />

                      <CustomRoute exact path='/app/transactions' page='transactions' render={() => <CoreTransactions user={this.state.user} />} />
                      <CustomRoute exact path='/app/transactions/:tab' page='transactions' render={() => <CoreTransactions user={this.state.user} />} />

                      <CustomRoute exact path='/app/search' page='search' render={() => <CoreSem user={this.state.user} />} />
                      <CustomRoute exact path='/app/search/:tab' page='search' render={() => <CoreSem user={this.state.user} />} />

                      <CustomRoute exact path='/app/known' page='search' render={() => <CoreKnown user={this.state.user} />} />
                      <CustomRoute exact path='/app/known/:tab' page='search' render={() => <CoreKnown user={this.state.user} />} />

                      <CustomRoute exact path='/app/skeptic' page='search' render={() => <CoreSkeptic user={this.state.user} />} />
                      <CustomRoute exact path='/app/adsales' page='adsales' render={() => <CoreAdsales user={this.state.user} />} />
                      <CustomRoute exact path='/app/industry' page='industry' render={() => <CoreIndustry user={this.state.user} />} />
                      <CustomRoute exact path='/app/cms' page='cms' render={() => <CoreCms user={this.state.user} />} />
                      <CustomRoute exact path='/app/content' page='content' render={() => <CoreContent user={this.state.user} />} />
                      <CustomRoute exact path='/app/opsis' page='opsis' render={() => <CoreOpsis user={this.state.user} />} />
                      <CustomRoute exact path='/app/conversions' page='conversions' render={() => <CoreConversions user={this.state.user} />} />
                      <CustomRoute exact path='/app/ga' page='conversions' render={() => <CoreGa user={this.state.user} />} />
                      <CustomRoute exact path='/app/finance' page='finance' render={() => <CoreFinance user={this.state.user} />} />

                      <CustomRoute exact path='/app/admin' page='admin' render={() => <CoreAdmin user={this.state.user} />} />
                      <CustomRoute exact path='/app/print' page='admin' render={() => <CorePrint user={this.state.user} />} />
                      <CustomRoute exact path='/app/opsis/admin' page='admin' render={() => <CoreOpsisAdmin user={this.state.user} />} />
                      <CustomRoute exact path='/app/experiments' page='admin' render={() => <CoreExperiments user={this.state.user} />} />
                      <CustomRoute exact path='/app/products' page='admin' render={() => <CoreProducts user={this.state.user} />} />

                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </main>
       
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(connect()(Layout));
