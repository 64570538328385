import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay } from '../../../core/utils';

import DataPointCard from '../../cards/DataPoint.js';
import ChartLegend from '../../cards/Legend.js';
class WidgetFunnel extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    skai_filter: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    timespan: 'days',
    fixed_years: 2,
    lookback: 30,
    skai_filter: ''
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    this.state = {
      isLoaded: false,
      isError: false,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      dateRangeDisplay: drd
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'CONVERSIONS WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.skai_filter !== this.props.skai_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    this.setFilterText();
    
    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/mixpanel/funnels/timespans',
        type: 'universal',
        years: this.props.fixed_years,
        lookback: this.state.lookback
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(this.props.skai_filter.length > 0) {
        opts.skai_filter = this.props.skai_filter;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                //console.log(this.state.results)
                //this.setChartData();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  setFilterText() {
    this.setState({
      ...this.state,
      filterText: this.props.skai_filter
    });
  };

  render() {

    var title_kpis = <h5><span className="text-danger">KPIs</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>Funnel data from {this.state.dateRangeDisplay} .&nbsp;</span>) : null}Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>;
    var title_funnels = <h5><span className="text-danger">Funnels</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>Funnel data from {this.state.dateRangeDisplay} .&nbsp;</span>) : null}Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>;
    
    return (

      <div className="page-divide">

        <Widget title={title_kpis}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <>

              <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" lookback={this.state.lookback} />

              <Row className="gutter-bottom-fixed-2">
                <DataPointCard print={this.props.print} results={this.state.results} title="Starts" lookback={this.state.lookback} format="XM" parent="starts" field="count" timespan={this.state.chart_timespan} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Conversion %" lookback={this.state.lookback} format="X.XX%" parent="starts" field="conversion_percentage" timespan={this.state.chart_timespan} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Face" lookback={this.state.lookback} format="$X.XX" parent="orders" field="average_face" timespan={this.state.chart_timespan} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Margin" lookback={this.state.lookback} format="X.X%" parent="orders" field="margin" timespan={this.state.chart_timespan} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg All-In" lookback={this.state.lookback} format="$X.XX" parent="orders" field="average_price" timespan={this.state.chart_timespan} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Serv Fees" lookback={this.state.lookback} format="$XM" parent="transactions" field="fees" timespan={this.state.chart_timespan} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Fees Per Session" lookback={this.state.lookback} format="$X.XX" parent="orders" field="fees_per_session" timespan={this.state.chart_timespan} />
              </Row>
            </>
          ) : null}
        </Widget>

        <Widget title={title_funnels} className="gutter-top-fixed-2 d-none">

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <>

              <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" lookback={this.state.lookback} />

              <Row className="gutter-bottom-fixed-2">
                {/* <FunnelCard print={this.props.print} results={this.state.results} title="Starts" lookback={this.state.lookback} format="XM" parent="starts" field="count" timespan={this.state.chart_timespan} /> */}
              </Row>
            </>
          ) : null}
          </Widget>


      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetFunnel));
