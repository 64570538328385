import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Poster from '../../../../components/Poster';
import { scurl, compare, checkExists } from '../../../../core/utils.js';

import {
  Row,
  Col,
  Input,
  InputGroup
} from 'reactstrap';

class WidgetAdminProduct extends React.Component {

  static propTypes = {
    product: PropTypes.object
  };

  static defaultProps = {
    product: null
  };

  constructor(props) {
    super(props);

    var product = this.props.product;
    if(!checkExists(product.short_name)) {
      product.short_name = '';
    }
    if(!checkExists(product.grosses_name)) {
      product.grosses_name = '';
    }

    this.state = {
      isLoaded: true,
      product: product,
      edit: false,
      dirty: false
    };

    this._isMounted = false;
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  save = () => { 
    this.setState({
      isLoaded: false
    }, function() {

      if(!compare(this.state.product, this.props.product)) {
        var valid = true;

        var url = '/products/' + this.state.product.id  + '/update?';
        url += 'id=' + this.state.product.id;
        url += '&short_name=' + this.state.product.short_name;
        url += '&grosses_name=' + this.state.product.short_name;

        if(valid) {

          var opts = {
            path: url,
            type: 'simple'
          };

          scurl(opts, function(err, results) {
            if(err) {
              this.setState({
                error: {
                  json: err,
                  status: err.status || -99,
                  message: err.message || 'Error occured'
                }
              });
            } else {
              this.setState({
                edit: false
              });
            }
          }.bind(this));
        } else {
          console.log('Not valid');
          console.log(url);
        }
      } else {
        console.log('Objects are the same');
      }
    });
  }

  edit = () => { 
    this.setState({
      edit: true,
      dirty: false
    });
  }

  handleUpdate = (e) => { 
    this.setState({
      product: { 
        ...this.state.product, 
       [e.target.id]: e.target.value
      }
    }, function() {
      this.setState({
        dirty: !compare(this.state.product, this.props.product)
      });
    });
  }

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        <div className="card gutter-bottom-fixed-4">
          <div className="card-body nopad nomargin">

            {this.props.product ? (

                <div className="d-flex nopad nomargin no-break">
                  <div className="nopad nomargin">
                    {!this.props.print ? (
                      <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.id))}>
                        <Poster id={this.props.product.poster_id} w={75} className="border-right img-poster nopad nomargin" />
                      </a>
                    ) : (
                      <Poster id={this.props.product.poster_id} w={75} className="border-right img-poster nopad nomargin" />
                    )}
                  </div>
                  <div className="flex-grow pl-4">

                    <Row>
                      <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                        <p className="font-weight-bold pb-0 mb-0">{this.props.product.name}</p>
                        <p className="pt-0 mt-0 fs-mini">
                          {this.props.product.venue ? (
                            <span>{this.props.product.venue.name}</span>
                          ) : (
                            <span>No Venue</span>
                          )}
                          {this.props.product.broadway ? (
                            <span className="pl-2"><i className="fa fa-road"></i></span>
                          ) : null}
                        </p>
                        
                        {this.props.product.owner ? (
                          <p className="pt-0 fs-mini">{this.props.product.owner}</p>
                        ) : null}

                        {this.state.edit ? (
                          <Row>
                            <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                              <p className="fs-mini mb-0 pb-0">Short Name</p>
                              <InputGroup className="fs-mini mt-0 pt-0">
                                <Input id="short_name" bsSize="16" type="text" value={this.state.product.short_name} onChange={(e) => this.handleUpdate(e)} />
                              </InputGroup>
                            </Col>
                            {this.state.product.broadway ? (
                              <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                                <p className="fs-mini mb-0 pb-0">Grosses Name</p>
                                <InputGroup className="fs-mini mt-0 pt-0">
                                  <Input id="grosses_name" bsSize="16" type="text" value={this.state.product.grosses_name} onChange={(e) => this.handleUpdate(e)} />
                                </InputGroup>
                              </Col>
                            ) : null}
                          </Row>
                        ) : null}

                      </Col>
                      <Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-right">
                        {this.state.edit ? (
                          <button className={this.state.dirty ? 'btn-success fs-mini' : 'btn-default fs-mini'} onClick={() => this.save()} disabled={!this.state.dirty}>SAVE</button>
                        ) : (
                          <button className="header-link fs-mini mr-2" onClick={() => this.edit()}>EDIT</button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>

            ) : null}

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdminProduct));
