import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

class WidgetGrossArticles extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    type: PropTypes.string
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    }

    this._isMounted = false;
    this.loadContent = this.loadContent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadContent();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'GROSS ARTICLES WIDGET')) {
      this.loadContent();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadContent() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/industry/guide',
        type: 'universal'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: false,
              results: results,
            }, function() {
              this.formHtml(results);
            });
          }
        } else {
          console.log('unmount handled');
        }
      }.bind(this));
    });  
  }

  formHtml(json) {

    var html = '<table>';
    html += '<thead>';
    html += '<tr>';
    html += '<th width="5%"></th>';
    html += '<th width="95%">SHOW</th>';
    html += '</tr>';
		html += '</thead>';
		html += '<tbody>';

    var overall_html = html;
    var musical_html = html;
    var play_html = html;
    var kids_html = html;
    var popular_html = html;

    var overall_count = 1;
    var musical_count = 1;
    var play_count = 1;
    var kids_count = 1;

    for(var show of json.shows) {

			overall_html += this.showBlock(show, overall_count);
      if(overall_count < 11) {
        popular_html += this.showBlock(show, overall_count);
      }
      overall_count += 1;

      if(show.product && show.product.type === 'musical') {
        musical_html += this.showBlock(show, musical_count);
        musical_count += 1;
      }

      if(show.product && show.product.type === 'play') {
        play_html += this.showBlock(show, play_count);
        play_count += 1;
      }

      if(show.product && show.product.kids) {
        kids_html += this.showBlock(show, kids_count);
        kids_count += 1;
      }

    }

    var closing = '</tbody></table>';
    overall_html += closing;
    musical_html += closing;
    play_html += closing;
    kids_html += closing;

    this.setState({
      isLoaded: true,
      html: {
        overall: overall_html,
        musical: musical_html,
        play: play_html,
        kids: kids_html,
        popular: popular_html
      }
    });
  }

  showBlock(block, count) {
    var html = '<tr>';
    html += '<td>' + count + '</td>';
    html += '<td>';
    if(block.product && block.product.slug) {
      html += '<a href="https://www.broadway.com/shows/' + block.product.slug + '">';
      html += block.product.name;
      html += '</a>';
    } else {
      html += block.name;
    }
    html += '</td>';
    html += '</tr>';

    return html;
  }

  render() {

    var title = <h5><span className="text-danger">Gross Article Content</span></h5>

    return (

      <div>

        <Widget title={title} className="widget-thin mt-3">

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-4">
              
              <div>
                <p>Best Sellers - Overall:</p>
                <textarea id="best-sellers-overall" name="best-sellers-overall" rows="30" cols="150" defaultValue={this.state.html.overall}></textarea>
              </div>

              <div className="gutter-top-fixed-2">
                <p>Best Sellers - Musicals:</p>
                <textarea id="best-sellers-musical" name="best-sellers-musical" rows="20" cols="150" defaultValue={this.state.html.musical}></textarea>
              </div>

              <div className="gutter-top-fixed-2">
                <p>Best Sellers - Plays:</p>
                <textarea id="best-sellers-play" name="best-sellers-play" rows="8" cols="150" defaultValue={this.state.html.play}></textarea>
              </div>

              <div className="gutter-top-fixed-2">
                <p>Best Sellers - Kids:</p>
                <textarea id="best-sellers-kids" name="best-sellers-kids" rows="8" cols="150" defaultValue={this.state.html.kids}></textarea>
              </div>

              <div className="gutter-top-fixed-2">
                <p>What to See - Top Ten Shows:</p>
                <textarea id="best-sellers-overall" name="best-sellers-overall" rows="12" cols="150" defaultValue={this.state.html.popular}></textarea>
              </div>


            </div>

          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetGrossArticles));

