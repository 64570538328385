import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { JsonEditor } from 'json-edit-react'
import Editor from 'react-simple-wysiwyg';
import { surl } from '../../../../core/utils.js';

const Station = (props) => {
  
  const [station, setStation] = useState(props.station);

  useEffect(() => {
    if (!station) {
      console.log('run something here');
    }
  }, [station])

  const [expand, setExpand] = useState(false);

  const save = (saveStation) => { 

    saveStation = saveStation || station;

    var url = '/v2/geo/stations/update';

    let postUrl = surl({
      path: url,
      type: 'simple'
    });

    fetch(postUrl,  {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',},
      body: JSON.stringify(saveStation),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(updatedStation => {
      console.log('Updated Station:', updatedStation);
      setStation(saveStation);
      setUpdated(false);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const setStationData = async (e) => { 
    save(e);
  }

  const [airtimes, setAirtimes] = useState(station.airtimes);
  const setAirtimesData = (e) => {
    setAirtimes(e.target.value);
    station.airtimes = e.target.value;
    setStation(station);
    setUpdated(true);
  }

  const [updated, setUpdated] = useState(false);

  const duplicate = () => { 

      var url = '/v2/geo/stations/duplicate';

      let postUrl = surl({
        path: url,
        type: 'simple'
      });

      fetch(postUrl,  {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',},
        body: JSON.stringify(station),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  return (
    <div>
      <Row className={expand ? 'mt-3 bg-dark text-white' : 'mt-3'} id={'block_' + station._id}>
        <Col xs={12} sm={6} md={6} lg={4} xl={3} className="gutter-top-fixed-2">
          <Row className="m-0 p-0">
            <Col xs={3} sm={3} md={3} lg={2} xl={2} className="d-print-none">
              <h6>
                <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => setExpand(!expand)}>
                  <i className={'fa-light ' + (expand ? 'fa-minus' : 'fa-edit')} />
                </Button>
              </h6>
            </Col>
            <Col xs={9} sm={9} md={9} lg={10} xl={10}>
              <h5 className={'text-capitalize ' + (expand ? ' fw-bold' : '')}>
                {station.station.location}
              </h5>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={3} className="gutter-top-fixed-2">
          <h5 className={'text-capitalize ' + (expand ? ' fw-bold' : '')}>
            {station.station.name}
          </h5>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={3} className="gutter-top-fixed-2 text-center">
    
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={3} className="gutter-top-fixed-2 text-center">

        </Col>
      </Row>

      {expand ? (
          <div className="gutter-top-fixed-2">

            {props.user.admin ? (
              <div className="text-right">
                <Button className="btn-light float-right" onClick={() => duplicate()}>
                  <i className="fa-light fa-copy" />
                </Button>
              </div>
            ) : null}

            <Row>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <h4>Edit Station</h4>
                <JsonEditor
                  data={ station }
                  setData={(e) => setStationData(e)}
                  // onUpdate={(e) => setStationData(e)}
                  className="w-100 gutter-top-fixed-2"
                  theme={[
                    'default'
                  ]}
                  collapse={false}
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <h4>Edit Airtimes</h4>
                <Editor value={airtimes} onChange={setAirtimesData} />
                <div className="gutter-top-fixed-2">
                  <Button className={updated ? 'btn-success' : 'btn-light'} onClick={() => save()} disabled={!updated}>
                    <i className="fa-light fa-save" /> &nbsp;Update Airtimes
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}

        <hr/>

    </div>
  );
};

export default Station;