import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

class WidgetAdminBlocklist extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
    }

    this._isMounted = false;
    this.loadLogs = this.loadLogs.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadLogs();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'BLOCKLIST WIDGET')) {
      this.loadLogs();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadLogs() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/blocklist',
        type: 'universal'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });

  }

  render() {

    var title = <h5><span className="text-danger">Blocklist</span> <small className="text-muted">List of tokens under temporary ban.</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">

              <Row>
                  {this.state.results.map((token, i) =>
                    <Col xs={12} sm={6} md={6} lg={4} xl={3} key={i} >
                        {token}
                    </Col>
                  )}
              </Row>
             
            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdminBlocklist));
