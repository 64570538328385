import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Table
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import Displays from '../../../components/Displays';
import { scurl, comma } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';
class WidgetBwMixpanelTest extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    timespan: PropTypes.string,
    chart_metric: PropTypes.string,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    fixed_years: PropTypes.number,
    experiment: PropTypes.object
  };

  static defaultProps = {
    print: false,
    timespan: 'day',
    chart_metric: 'overall_conv_ratio',
    chartAspectWidth: 4,
    chartAspectHeight: 1,
    chart: true,
    width: 1050,
    height: 200,
    fixed_years: 1
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      print: this.props.print,
      experiment: this.props.experiment
    }

    this._isMounted = false;
    this.loadFunnels = this.loadFunnels.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadFunnels();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'MIXPANEL TEST WIDGET')) {
      this.loadFunnels();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadFunnels() {
    if(this.state.experiment) {
      this.setState({
        isLoaded: false,
        results: []
      }, function() {

        console.log(this.props.experiment);

        var opts = {
          path: '/mixpanel/experiments/' + this.props.experiment.id.split('_')[0],
          type: 'universal',
          timespans: 1
        };

        if(this.props.product_id) {
          opts.product_id = this.props.product_id;
        }

        scurl(opts, function(err, results) {
          if(this._isMounted) {
            if(err) {
              this.setState({
                error: {
                  json: err,
                  status: err.status || -99,
                  message: err.message || 'Error occured'
                }
              });
            } else {

              if(results[0] && results[0].experiment && results[0].experiment.totals && results[0].experiment.totals.overall && results[0].experiment.totals.overall.view_options > 0) {
                this.setState({
                  isLoaded: true,
                  results: results,
                });
              } else {
                this.setState({
                  error: {
                    json: 'No data returned',
                    status: -99,
                    message: 'Error occured: No data returned'
                  }
                });
              }
            }
          } else {
            console.log('handled unmount');
          }
        }.bind(this));
      }.bind(this));
    }
  }

  render() {

    var title = <h5><span className="text-danger">{this.props.experiment.label}</span><br/><small className="text-muted">Figures from Mixpanel API and Mixpanel Confirmation Page events.</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div>

              <p className="gutter-top-fixed-4 fw-bold text-capitalize">Overall Performance Against Current Checkout Experience</p>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th width="25%" className="fs-mini">Experience</th>
                    <th width="25%" className="text-center fs-mini">Conv %</th>
                    <th width="25%" className="text-center fs-mini">Avg Face</th>
                    <th width="25%" className="text-center fs-mini">Proj. Fees</th>
                  </tr>
                </thead>
                <tbody> 
                    <tr>
                      <td>
                        <h6>Current Checkout</h6>
                      </td>
                      <td className="text-center">
                        <h6>{parseFloat(this.state.results[0].experiment.totals.outsiders.overall_conv_ratio*100).toFixed(2)}%</h6>
                      </td>
                      <td className="text-center">
                        <h6>${parseFloat(this.state.results[0].transactions.outsiders.average_price).toFixed(2)}</h6>
                      </td>
                      <td className="text-center">
                        <h6>${comma(this.state.results[0].experiment.totals.outsiders.projection.fees)}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>Experiment Variant(s)</h6>
                      </td>
                      <td className="text-center">
                        <h6>{parseFloat(this.state.results[0].experiment.totals.variants.overall_conv_ratio*100).toFixed(2)}%</h6>
                        <span className="fs-mini"><Displays a={this.state.results[0].experiment.totals.variants.overall_conv_ratio} b={this.state.results[0].experiment.totals.outsiders.overall_conv_ratio} format="percent" fixed={1} /></span>
                      </td>
                      <td className="text-center">
                        <h6>${parseFloat(this.state.results[0].transactions.variants.average_price).toFixed(2)}</h6>
                        <span className="fs-mini"><Displays a={this.state.results[0].transactions.variants.average_price} b={this.state.results[0].transactions.outsiders.average_price} format="percent" fixed={1} /></span>
                      </td>
                      <td className="text-center">
                        <h6>${comma(this.state.results[0].experiment.totals.variants.projection.fees)}</h6>
                        <span className="fs-mini"><Displays a={this.state.results[0].experiment.totals.variants.projection.fees} b={this.state.results[0].experiment.totals.outsiders.projection.fees} format="percent" fixed={1} /></span>
                      </td>
                    </tr>
                </tbody>
              </Table>

              <p className="gutter-top-fixed-4 fw-bold text-capitalize">Device Specific Comparisons to Current Checkout</p>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th width="17%" className="fs-mini">Device</th>
                    <th width="17%" className="text-center fs-mini">Selected<br/>Seats</th>
                    <th width="17%" className="text-center fs-mini">Created<br/>Cart</th>
                    <th width="17%" className="text-center fs-mini">Purchased<br/>Created Cart</th>
                    <th width="17%" className="text-center fs-mini">Overall<br/>Conversion %</th>
                    <th width="17%" className="text-center fs-mini">Average<br/>Ticket Price</th>
                  </tr>
                </thead>
                <tbody> 
                    {this.state.results.map((block, i) =>
                      i > -1 ? (
                      <tr key={i}>
                        <td>
                          <h6 className="text-capitalize">
                            {block.type === 'overall' ? ('All Devices') : (block.type) }
                          </h6>
                          <span className="fs-mini text-muted">{comma(block.experiment.totals.variants.view_options)} starts</span>
                        </td>
                        <td className="text-center">
                          <h6>{parseFloat(block.experiment.totals.variants.view_seats_ratio*100).toFixed(1)}%</h6>
                          <span className="fs-mini"><Displays a={block.experiment.totals.variants.view_seats_ratio} b={block.experiment.totals.outsiders.view_seats_ratio} format="percent" fixed={1} /></span>
                        </td>
                        <td className="text-center">
                          <h6>{parseFloat(block.experiment.totals.variants.view_options_to_add_seats_ratio*100).toFixed(1)}%</h6>
                          <span className="fs-mini"><Displays a={block.experiment.totals.variants.view_options_to_add_seats_ratio} b={block.experiment.totals.outsiders.view_options_to_add_seats_ratio} format="percent" fixed={1} /></span>
                        </td>
                        <td className="text-center">
                          <h6>{parseFloat(block.experiment.totals.variants.add_seats_to_conv_ratio*100).toFixed(1)}%</h6>
                          <span className="fs-mini"><Displays a={block.experiment.totals.variants.add_seats_to_conv_ratio} b={block.experiment.totals.outsiders.add_seats_to_conv_ratio} format="percent" fixed={1} /></span>
                        </td>
                        <td className="text-center">
                          <h6>{parseFloat(block.experiment.totals.variants.overall_conv_ratio*100).toFixed(1)}%</h6>
                          <span className="fs-mini"><Displays a={block.experiment.totals.variants.overall_conv_ratio} b={block.experiment.totals.outsiders.overall_conv_ratio} format="percent" fixed={1} /></span>
                        </td>
                        <td className="text-center">
                          <h6>${parseFloat(block.transactions.variants.average_price).toFixed(2)}</h6>
                          <span className="fs-mini"><Displays a={block.transactions.variants.average_price} b={block.transactions.outsiders.average_price} format="percent" fixed={1} /></span>
                        </td>
                      </tr>
                      ) : null
                    )}
                </tbody>
              </Table>

              <div className="page-divide"></div>

              <p className="gutter-top-fixed-4 fw-bold text-capitalize">Data Source Reconciliation</p>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th width="20%" className="fs-mini">Data Source</th>
                    <th width="20%" className="text-center fs-mini">View Options</th>
                    <th width="20%" className="text-center fs-mini">View Seats</th>
                    <th width="20%" className="text-center fs-mini">Add Seats</th>
                    <th width="20%" className="text-center fs-mini">Confirmation</th>
                  </tr>
                </thead>
                <tbody> 
                  <tr>
                    <td>
                      <h6 className="text-capitalize">Optimizely</h6>
                      <span className="fs-mini text-muted">&nbsp;</span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].optimizely.variants.view_options)}</h6>
                      <span className="fs-mini">&nbsp;</span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].optimizely.variants.view_seats)}</h6>
                      <span className="fs-mini">&nbsp;</span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].optimizely.variants.add_seats)}</h6>
                      <span className="fs-mini">&nbsp;</span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].optimizely.variants.completions)}</h6>
                      <span className="fs-mini">&nbsp;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="text-capitalize">Mixpanel</h6>
                      <span className="fs-mini text-muted">&nbsp;</span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].experiment.totals.variants.view_options)}</h6>
                      <span className="fs-mini"><Displays a={this.state.results[0].experiment.totals.variants.view_options} b={this.state.results[0].optimizely.variants.view_options} format="percent" fixed={1} /></span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].experiment.totals.variants.view_seats)}</h6>
                      <span className="fs-mini"><Displays a={this.state.results[0].experiment.totals.variants.view_seats} b={this.state.results[0].optimizely.variants.view_seats} format="percent" fixed={1} /></span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].experiment.totals.variants.add_seats)}</h6>
                      <span className="fs-mini"><Displays a={this.state.results[0].experiment.totals.variants.add_seats} b={this.state.results[0].optimizely.variants.add_seats} format="percent" fixed={1} /></span>
                    </td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].experiment.totals.variants.completions)}</h6>
                      <span className="fs-mini"><Displays a={this.state.results[0].experiment.totals.variants.completions} b={this.state.results[0].optimizely.variants.completions} format="percent" fixed={1} /></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="text-capitalize">Mixpanel Confirmations</h6>
                      <span className="fs-mini text-muted">&nbsp;</span>
                    </td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].reconciled.completions.mixpanel_transactions)}</h6>
                      <span className="fs-mini"><Displays a={this.state.results[0].reconciled.completions.mixpanel_transactions} b={this.state.results[0].optimizely.variants.completions} format="percent" fixed={1} /></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="text-capitalize">Optimizely Parquet</h6>
                      <span className="fs-mini text-muted">&nbsp;</span>
                    </td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">
                      <h6>{comma(this.state.results[0].reconciled.completions.parquet)}</h6>
                      <span className="fs-mini"><Displays a={this.state.results[0].reconciled.completions.parquet} b={this.state.results[0].optimizely.variants.completions} format="percent" fixed={1} /></span>
                    </td>
                  </tr>
                </tbody>
              </Table>

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwMixpanelTest));
