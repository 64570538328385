import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row, 
  Col
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import More from '../../../components/More';
import Displays from '../../../components/Displays';

import Select from 'react-select';

import { scurl, formatNumber } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay } from '../../../core/utils';

import DataPointCard from '../../cards/DataPoint.js';
import ChartLegend from '../../cards/Legend.js';

class WidgetSalesGeo extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    perf_date: PropTypes.bool,
    sales_metric: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 6,
    lookback: 30,
    perf_date: false,
    sales_metric: 'face'
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    let col_lg = 6;
    if(this.props.dashboard) {
      col_lg = 12;
    }

    let perf_trans_options = [
      { value: 0, label: 'Trans Date' },
      { value: 1, label: 'Perf Date' },
    ]

    let perf_trans_selected = perf_trans_options[0];
    if(this.props.perf_date) {
      perf_trans_selected = perf_trans_options[1];
    }

    this.state = {
      isLoaded: false,
      isError: false,
      perf_date: this.props.perf_date,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      dateRangeDisplay: drd,
      col_lg: col_lg,
      sales_metric: this.props.sales_metric,
      format: '$XM',
      perf_trans_options: perf_trans_options,
      perf_trans_selected: perf_trans_selected
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SALES TIMESPANS WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.skai_filter !== this.props.skai_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/transactions/geo',
        type: 'universal',
        years: this.props.fixed_years,
        lookback: this.state.lookback
      };

      if(this.state.perf_date) {
        opts.perf_date = true;
      }

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                //this.setDashCam();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changePerfTrans = (event) => { 
    let perf_date = false;
    let selected = this.state.perf_trans_options[0];
    if(event.value === 1) {
      selected = this.state.perf_trans_options[1];
      perf_date = true;
    }
    this.setState({
      perf_trans_selected: selected,
      perf_date: perf_date
    }, function() {
      this.loadData()
    });
  };

  render() {

    let changePT = (event) => { 
      this.changePerfTrans(event);
    };

    return (

      this.props.dashboard ? (

        <Widget
          className="mb-0 h-100"
          bodyClass={`mt p-2`}
          title={
            <div className="d-flex justify-content-between flex-wrap">
              <h5 className="pt-1"><span>Sale Geos</span></h5>
              {this.state ? (
                <Select
                  value={this.state.perf_trans_selected}
                  onChange={(event) => changePT(event) }
                  options={this.state.perf_trans_options}
                  placeholder='Filter results...'
                  isMulti={false}
                  className="form-control-sm p-0 m-0"
                /> 
              ) : null}
            </div>
          }
        >

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} className="text-center" />

          {this.state.isLoaded ? (
            <div>
              <div className="d-flex flex-wrap mt-4 text-muted ml-2">
                <h6><i className="fa fa-subway fa-lg"></i></h6>
                <h6 className="ml-2">NYC Metro</h6>
              </div>
              <div className="d-flex border-top">
                <div className="w-50 border-right py-2 px-3">
                  <p className="text-muted"><small>FACE</small></p>
                  <Row className="gutter-top-fixed-1">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h6>{formatNumber('$XM', this.state.results.nyc.totals[0].face)}</h6>
                    </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                        <h6 className="text-muted fs-mini"><Displays a={this.state.results.nyc.totals[0].face} b={this.state.results.nyc.totals[1].face} format="percent" /></h6>
                      </Col>
                  </Row>
                </div>
                <div className="w-50 py-2 pl-3">
                  <p className="text-muted"><small>% OF TOTAL</small></p>
                  <Row className="gutter-top-fixed-1">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h6>{formatNumber('X.X%', (this.state.results.nyc.totals[0].face/this.state.results.totals.totals[0].face))}</h6>
                    </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                        <h6 className="text-muted fs-mini"><Displays a={(this.state.results.nyc.totals[0].face/this.state.results.totals.totals[0].face)} b={(this.state.results.nyc.totals[1].face/this.state.results.totals.totals[1].face)} format="percent" /></h6>
                      </Col>
                  </Row>
                </div>
              </div>

              <div className="d-flex flex-wrap mt-4 text-muted ml-2">
                <h6><i className="fa fa-plane fa-lg"></i></h6>
                <h6 className="ml-2">Domestic</h6>
              </div>
              <div className="d-flex border-top">
                <div className="w-50 border-right py-2 px-3">
                  <p className="text-muted"><small>FACE</small></p>
                  <Row className="gutter-top-fixed-1">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h6>{formatNumber('$XM', this.state.results.domestic.totals[0].face)}</h6>
                    </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                        <h6 className="text-muted fs-mini"><Displays a={this.state.results.domestic.totals[0].face} b={this.state.results.domestic.totals[1].face} format="percent" /></h6>
                      </Col>
                  </Row>
                </div>
                <div className="w-50 py-2 pl-3">
                  <p className="text-muted"><small>% OF TOTAL</small></p>
                  <Row className="gutter-top-fixed-1">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h6>{formatNumber('X.X%', (this.state.results.domestic.totals[0].face/this.state.results.totals.totals[0].face))}</h6>
                    </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                        <h6 className="text-muted fs-mini"><Displays a={(this.state.results.domestic.totals[0].face/this.state.results.totals.totals[0].face)} b={(this.state.results.domestic.totals[1].face/this.state.results.totals.totals[1].face)} format="percent" /></h6>
                      </Col>
                  </Row>
                </div>
              </div>

              <div className="d-flex flex-wrap mt-4 text-muted ml-2">
                <h6><i className="fa fa-passport fa-lg"></i></h6>
                <h6 className="ml-2">International</h6>
              </div>
              <div className="d-flex border-top">
                <div className="w-50 border-right py-2 px-3">
                  <p className="text-muted"><small>FACE</small></p>
                  <Row className="gutter-top-fixed-1">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h6>{formatNumber('$XM', this.state.results.international.totals[0].face)}</h6>
                    </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                        <h6 className="text-muted fs-mini"><Displays a={this.state.results.international.totals[0].face} b={this.state.results.international.totals[1].face} format="percent" /></h6>
                      </Col>
                  </Row>
                </div>
                <div className="w-50 py-2 pl-3">
                  <p className="text-muted"><small>% OF TOTAL</small></p>
                  <Row className="gutter-top-fixed-1">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h6>{formatNumber('X.X%', (this.state.results.international.totals[0].face/this.state.results.totals.totals[0].face))}</h6>
                    </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                        <h6 className="text-muted fs-mini"><Displays a={(this.state.results.international.totals[0].face/this.state.results.totals.totals[0].face)} b={(this.state.results.international.totals[1].face/this.state.results.totals.totals[1].face)} format="percent" /></h6>
                      </Col>
                  </Row>
                </div>
              </div>

            </div>
          ) : null}

          <More className="gutter-right-fixed-2" url="/app/transactions" tab={0} perf_date={this.state.perf_date} />
          
        </Widget>

      ) : (
        <Widget title={
          <div className="d-flex justify-content-between flex-wrap">
            <h5><span className="text-danger">Sales KPIs</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>from {this.state.dateRangeDisplay} .&nbsp;</span>) : null}Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>
            {this.state ? (
              <Select
                value={this.state.perf_trans_selected}
                onChange={(event) => changePT(event) }
                options={this.state.perf_trans_options}
                placeholder='Filter results...'
                isMulti={false}
                className="form-control-sm"
              /> 
            ) : null}
          </div>
        }>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <>

              <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" lookback={this.state.lookback} />

              <Row className="gutter-top-fixed-2 gutter-bottom-fixed-2">
                <DataPointCard print={this.props.print} results={this.state.results} title="Face Value" field="face" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Service Fees" field="fees" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Price" lookback={this.state.lookback} format="$X.XX" field="average_price" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Margin" lookback={this.state.lookback} format="X.X%" field="margin" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Affiliate Fees" field="affiliate_commission" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Access Fee" field="access_fees_per_ticket" lookback={this.state.lookback} format="$X.XX" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
              </Row>

            </>
          ) : null}
        </Widget>
      )
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetSalesGeo));
