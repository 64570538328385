import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import WidgetSkeptic from '../../widgets/search/Skeptic';

import WidgetSpacer from '../../widgets/utils/Spacer';

import Select from 'react-select';

import isScreen from '../../core/screenHelper';

class Skeptic extends React.Component {

  constructor(props) {
    super(props);

    let items = [
      { value: '', label: '----' },
      { value: 'channel:Google', label: 'Channel: Google' },
      { value: 'channel:Microsoft', label: 'Channel: Microsoft' },
      { value: '', label: '----' },
      { value: 'type:Generic', label: 'Type: Generic' },
      { value: 'type:Shows', label: 'Type: Shows' },
      { value: 'type:International', label: 'Type: International' },
      { value: 'type:- US', label: 'Type: Domestic' },
      { value: 'type:- NY', label: 'Type: NYC' },
    ];

    let skai_filter = {
      items: items,
      isLoaded: true
    }

    this.state = {
      isLoaded: false,
      skai_filter: skai_filter,
      skai_filter_string: ''
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(){

      var tabSelected = 0;
      var tabs = [
        {
          id: 0,
          icon: 'fa-circle-dollar-to-slot',
          name: 'KPI Summary'
        },
        // {
        //   id: 1,
        //   icon: 'fa-print',
        //   name: 'Print'
        // },
        // {
        //   id: 2,
        //   icon: 'fa-stars',
        //   name: 'Campaigns'
        // }
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  handleFilterChange = selectedOption => {

    var hasChanged = true;

    let skai_filter_string = '';
    for(var [i,selected] of selectedOption.entries()) {
      if(i > 0) {
        skai_filter_string += ',';
      }
      skai_filter_string += selected.value;
    }

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      skai_filter: {
        ...this.state.skai_filter,
        selected: selectedOption
      },
      skai_filter_string: skai_filter_string
    });
  };

  render() {
  
    return (
      <div>

        <Helmet>
          <title>SH4: Skeptic</title>
        </Helmet>

        <div className="menuContainer lg">

          <Row>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <Nav className="mt-4">
                <NavItem className="nopad-bottom nomargin-bottom">
                  
                    <h5 className="text-danger">Search Marketing</h5>

                    {this.state.tabs ? (
                      <ButtonGroup>
                        {this.state.tabs.map((tab, i) =>
                          <Button key={i}
                            color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                            active={this.state.tabSelected === i}
                          ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                          <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                        )}
                      </ButtonGroup>
                    ) : null}
                </NavItem>
              </Nav>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="gutter-top-fixed-2 d-md-down-none">
              <Row>
                <Col xs={12} sm={12} md={8} lg={8} xl={5}>
                  <h6>FILTER <span className="text-muted fs-mini">only one per category</span></h6>  
                  <Select
                    value={this.state.skai_filter.selected}
                    onChange={this.handleFilterChange}
                    options={this.state.skai_filter.items}
                    placeholder='Filter results...'
                    isMulti={true}
                  /> 
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetSkeptic print={false} skai_filter={this.state.skai_filter_string} />
            </div>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetSkeptic print={true} skai_filter={this.state.skai_filter_string} />
              <WidgetSpacer print={true} />
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
  
            </div>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Skeptic));
