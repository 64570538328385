import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import More from '../../../components/More';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, datespan, dateRangeDisplay } from '../../../core/utils';

import { Row, Col, Table } from 'reactstrap';

import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

import { themeColors } from '../../../core/colors';

class WidgetRepeats extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 1,
    lookback: 30,
    className: ''
  };

  constructor(props) {
    super(props);

    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    let chart_aspect_width = 5;
    let chart_aspect_height = 1;

    if(this.props.dashboard) {
      chart_aspect_width = 2.25;
      chart_aspect_height = 1;
    }

    this.state = {
      isLoaded: false,
      isError: false,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      dateRangeDisplay: drd,
      chart_aspect_width: chart_aspect_width,
      chart_aspect_height: chart_aspect_height,
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
    this.onPieEnter = this.onPieEnter.bind(this);
    this.renderActiveShape = this.renderActiveShape.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'REPEATS WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.lead_groups !== this.props.lead_groups) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/transactions/repeats',
        type: 'universal',
        years: this.props.fixed_years,
        // lookback: this.state.lookback
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                //console.log(this.state.results)
                this.setChartData();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  };

  setChartData() {
    
    if(this.state.results && this.state.results.customers) {

      //BAR CHART
      let bar_sales = [];

      let datapoint = {
        name: 'Customer Types',
        new: this.state.results.customers.new,
        repeat: this.state.results.customers.repeat,
        multi: this.state.results.customers.multi
      };

      bar_sales.push(datapoint);

      var bar_min = 0.85;
      var bar_max = 1.15;

      this.setState({
        bar: {
          data: [
            { name: 'Repeats', value: this.state.results.customers.repeat },
            { name: 'Multi', value: this.state.results.customers.multi },
            { name: 'New to File', value: this.state.results.customers.new }
          ],
          activeIndex: 0,
          colors: themeColors.default
        },
        bar_sales: {
          data: bar_sales,
          properties: {
            max: bar_max,
            min: bar_min
          }
        }
      });
    }
  }

  onPieEnter = (_, index) => {
    this.setState({
      bar: {
        ...this.state.bar,
        activeIndex: index
      }
    });
  };

  renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text> */}
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#333">
          {`${(percent * 100).toFixed(1)}%`}
        </text>
      </g>
    );
  };

  renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  render() {

    return (

      this.props.dashboard ? (

        <Widget 
          title={
            <h5>
              <span>Repeat/Multi </span>
              <small className="text-muted pl-2">buyers</small>
            </h5>
          } 
          className="mb-0 h-100" 
          bodyClass={`mt p-4`}
        >

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            this.state.isLoaded && this.state.bar && this.state.bar.data ? (
              <>
                
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <h4 className="fw-semi-bold gutter-top-fixed-1 gutter-bottom-fixed-2">
                      {parseFloat((this.state.results.customers.repeat/this.state.results.customers.total)*100).toFixed(1)}%
                      <p className="fw-normal text-muted fs-mini">Repeat</p>
                    </h4>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <h4 className="fw-semi-bold gutter-top-fixed-1 gutter-bottom-fixed-2">
                      {parseFloat((this.state.results.customers.multi/this.state.results.customers.total)*100).toFixed(1)}%
                      <p className="fw-normal text-muted fs-mini">Multi</p></h4>
                  </Col>
                </Row>
                

                <div className="border-top">
                  <ResponsiveContainer width="100%" aspect={3/2} margin={{ top: 0, left: 0, right: 0, bottom: 0 }} padding={{ top: 0, left: 0, right: 0, bottom: 0 }} >
                    <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} padding={{ top: 0, left: 0, right: 0, bottom: 0 }} >
                      <Pie
                        activeIndex={this.state.bar.activeIndex}
                        data={this.state.bar.data}
                        labelLine={false}
                        label={this.renderCustomizedLabel}
                        innerRadius="30%"
                        outerRadius="80%"
                        fill={this.state.bar.colors[0]}
                        dataKey="value"
                      >
                        {this.state.bar.data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={this.state.bar.colors[index % this.state.bar.colors.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </>
            ) : null
          ) : null}

          <More url="/app/transactions/4" />
          
        </Widget>

      ) : (
        <div className={this.props.className}>

          <Widget title={
            <h5>
              <span className="text-danger">Customer Types </span> 
              <small className="text-muted">{this.state.dateRangeDisplay ? (<span>Transaction date sales data for {this.state.dateRangeDisplay} .&nbsp;</span>) : null}</small>
            </h5>
          }>

            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
                <>
                  {/* <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" /> */}

                  {this.state.isLoaded && this.state.bar && this.state.bar.data ? (
                      <>

                        <Row>
                          <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Repeat Total</p>
                            <h4 className="text-center">{parseFloat((this.state.results.customers.repeat/this.state.results.customers.total)*100).toFixed(1)}%</h4>
                            <p className="fs-mini text-muted text-center">of total</p>
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Repeat Tri-State</p>
                            <h4 className="text-center">{parseFloat((this.state.results.tristate.repeat/this.state.results.customers.repeat)*100).toFixed(1)}%</h4>
                            <p className="fs-mini text-muted text-center">of repeats</p>
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Multi Purchase</p>
                            <h4 className="text-center">{parseFloat((this.state.results.customers.multi/this.state.results.customers.total)*100).toFixed(1)}%</h4>
                            <p className="fs-mini text-muted text-center">of total</p>
                          </Col>
                        </Row>

                        <p className="gutter-top-fixed-4 mb-0 pb-0">Repeat Purchasers ({this.state.results.detail.customers.length})</p>
                        <Table className="mt-0 pt-0" striped={false}>
                          <thead>
                            <tr className="mt-0 pt-0">
                              <th className="mt-0 pt-0" width="30%">&nbsp;</th>
                              <th className="mt-0 pt-0" width="70%">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody> 
                              {this.state.results.detail.customers.map((customer, i) =>
                                customer.repeat ? (
                                  <tr key={i}>
                                    <td>
                                      <Table className="gutter-top-fixed-0" borderless={true} striped={false}>
                                        <tbody>
                                          <tr>
                                            <td width="25%"><span>ID:</span></td>
                                            <td width="75%"><span>{customer.id}</span></td>
                                          </tr>
                                          <tr>
                                            <td width="25%"><span>DMA:</span></td>
                                            <td width="75%"><span>{customer.dma}</span></td>
                                          </tr>
                                          <tr>
                                            <td width="25%"><span>POSTAL:</span></td>
                                            <td width="75%"><span>{customer.postal_code}</span></td>
                                          </tr>
                                          <tr>
                                            <td width="25%"><span>AGE:</span></td>
                                            <td width="75%"><span>{customer.first_performance}</span></td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </td>
                                    <td>
                                      <Table className="gutter-top-fixed-0" striped={false}>
                                        <thead>
                                          <tr>
                                            <th width="33%">Show</th>
                                            <th width="33%">Trans</th>
                                            <th width="33%">Perf</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {customer.transactions.map((transaction, x) =>
                                            <tr key={x}>
                                              <td><span>{transaction.show_name}</span></td>
                                              <td><span>{transaction.transaction_date}</span></td>
                                              <td><span>{transaction.performance_date}</span></td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </td>
                                  </tr>
                                ) : null
                              )}
                          </tbody>
                        </Table>


                      </>
                  ) : null}

                </>
              </Widget>

        </div>

      )
    )
  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetRepeats));
