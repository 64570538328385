import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import Displays from '../../../../components/Displays';

import { nFormatter } from '../../../../core/utils';
import Poster from '../../../../components/Poster';

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid} from 'recharts';

class WidgetProductCard extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    print: false,
    width: 300,
    height: 100,
  };

  constructor(props) {
    super(props);

    this.state = {
      chart: {
        data: []
      }
    }

    this.setChart = this.setChart.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {
    if(this.props.product.trend) {
      this.setChart();
    }
  }

  setChart() {

    var chartData = [];

    this.props.product.trend.forEach(function(block, i) {
      
      var d = {
        week: block.dates.end,
        sales: block.sales.fees
      };

      if(block.sales.fees > 0) {
        chartData.push(d);
      }

    });
    
    var aboveMax = 1.1;
    this.setState({
      chart: {
        data: chartData,
        properties: {
          aboveMax: aboveMax
        }
      }
    });
  }

  salesSummaryDataFormat(x) {
    return '$' + nFormatter(x);
  };

  salesSummaryDateFormat(x) {
    return moment(x).format('M/DD');
  };

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        <div className="card gutter-bottom-fixed-4">
          <div className="card-body nopad nomargin">

            {this.props.product.product ? (
              <div>
                <div className="d-flex">
                  <div>
                    <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))}>
                      <Poster id={this.props.product.product.poster_id} w={75} className="border-right img-poster" />
                    </a>
                  </div>
                  <div className="flex-grow text-center">
                      
                      {!this.props.print ? (
                        <h6 className="mb-4">
                          <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))} className="fs-mini text-muted">
                            {this.props.product.product.short_name ? (
                              <span>{this.props.product.product.short_name}</span>
                            ) : (
                              this.props.product.product.name && (this.props.product.product.name.length > 18) ? (
                                <span>{this.props.product.product.name.slice(0,18)}...</span>
                              ) : (
                                <span>{this.props.product.product.name}</span>
                              )
                            )}
                          </a>
                        </h6>
                      ) : null}
                      
                      {(this.props.sales_metric === 'orders' &&
                        <div>
                          <h4 className="text-center gutter-top-fixed-2"><Displays a={parseInt(this.props.product.orders)} format="comma" /></h4>
                          <p className="fs-mini text-muted text-center">--</p>
                        </div>)
                      || (this.props.sales_metric === 'qty' &&
                      <div>
                        <h4 className="text-center gutter-top-fixed-2"><Displays a={parseInt(this.props.product.qty)} format="comma" /></h4>
                        <p className="fs-mini text-muted text-center">--</p>
                      </div>)
                      ||
                        <div>
                          <h4 className="text-center">$<Displays a={parseInt(this.props.product[this.props.sales_metric])} format="comma" /></h4>
                          <p className="fs-mini text-muted text-center">${parseFloat(this.props.product[this.props.sales_metric]/this.props.product.qty).toFixed(2) || 0}</p>
                        </div>
                      }
                  </div>
                </div>
                
                {this.props.print ? (
                  <div className="gutter-top-fixed-2">
                    {this.state.chart && this.state.chart.data && this.state.chart.data.length > 1 ? (
                      <div>
                        <LineChart data={this.state.chart.data} margin={{top: 0, right: 0, left: 0, bottom: 0}} height={this.props.height} width={this.props.width} >
                          <defs>
                            <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                            </linearGradient>
                          </defs>
                          <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 9}} dataKey="week" padding={{left: 10, right: 10}} />
                          <YAxis tick={{fontSize: 9}} tickSize={5} tickFormatter={this.salesSummaryDataFormat} dataKey="sales" domain={[0, dataMax => (dataMax * this.state.chart.properties.aboveMax)]} />
                          <Line name="sales" type="monotone" dataKey="sales" stroke="#82ca9d" />
                        </LineChart>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <ResponsiveContainer width='100%' aspect={this.props.chartAspectWidth/this.props.chartAspectHeight} >
                    <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                      <defs>
                        <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                        </linearGradient>
                      </defs>
                      <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 10, right: 10}} />
                      <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[dataMin => (dataMin * 0.9), dataMax => (dataMax * this.state.chart.properties.aboveMax)]} />
                      <CartesianGrid strokeDasharray="3 3"/>
                      <Tooltip />
                      <Line name="Trend" type="monotone" dataKey="cy" stroke="#82ca9d" />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              
              </div>
            ) : null}

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetProductCard));
