import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';

class Poster extends React.Component {
  
  static propTypes = {
    poster: PropTypes.object,
    id: PropTypes.string,
    w: PropTypes.number,
    h: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    responsive: PropTypes.bool
  };

  static defaultProps = {
    poster: null,
    id: '86397-1',
    w: 175,
    h: 225,
    className: '',
    style: {},
    responsive: false
  };
  
  render() {
    return (

      <>
        {this.props.poster ? (
          <Media object src={this.props.poster.large} alt="Poster" className={this.props.className} width={this.props.w} height={parseInt(this.props.w/0.647272727)} style={this.props.style} />
        ) : (
          this.props.id && this.props.id !== 'null' && this.props.id.length > 4 ? (
            <Media object src={['https://imaging.broadway.com/images/poster-178275/w',this.props.w,'/',this.props.id + '.png'].join('')} alt="Poster" className={this.props.className} width={this.props.w} height={parseInt(this.props.w/0.647272727)} style={this.props.style} />
          ) : (
            <Media object src={['https://imaging.broadway.com/images/poster-178275/w',this.props.w,'/86397-1.png'].join('')} alt="Poster" className={this.props.className} width={this.props.w} height={parseInt(this.props.w/0.647272727)} style={this.props.style} />
          )
        )}
      </>
    );
  }
}

export default Poster;

