import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Table,
  Button
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import ProductCard from './cards/ProductCard.js';
import { surl, scurl } from '../../../core/utils.js';
import { filterUpdated, changeSalesMetric, changeAffiliateSalesMetric, subSort, displaySalesMetric } from '../../../core/utils';

import moment from 'moment';

class WidgetAffiliateShows extends React.Component {

  static propTypes = {
    engagement: PropTypes.object,
    print: PropTypes.bool,
    fixed_years: PropTypes.number,
    title: PropTypes.bool,
    exclude_zero: PropTypes.bool,
    perf_date: PropTypes.bool,
    className: PropTypes.string,
    sales_metric: PropTypes.string,
    affiliate: PropTypes.bool,
    display: PropTypes.number,
    broadway: PropTypes.bool,
  };

  static defaultProps = {
    engagement: null,
    print: false,
    fixed_years: 3,
    title: true,
    exclude_zero: false,
    perf_date: false,
    className: '',
    sales_metric: 'face',
    affiliate: true,
    display: 12,
    broadway: false
  };

  constructor(props) {
    super(props);

    var display = 12;
    if(this.props.display) {
      display = this.props.display;
    };

    this.state = {
      isLoaded: false,
      display: display,
      perf_date: this.props.perf_date,
      sales_metric: this.props.sales_metric,
    }

    this._isMounted = false;
    this.loadSales = this.loadSales.bind(this);
    this.loadUrl = this.loadUrl.bind(this);
    this.viewAll = this.viewAll.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SHOWS WIDGET')) {
      this.loadSales();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSales() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/transactions/shows',
        type: 'universal'
      };

      opts.perf_date = this.state.perf_date;

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(this.props.broadway) {
              var broadway_only = [];
              for(var engagement of results.shows.dates[0].sales) {
                if(engagement.product && engagement.product.broadway) {
                  broadway_only.push(engagement);
                }
              }
              results.shows.dates[0].sales = broadway_only;
            }

            this.setState({
              isLoaded: true,
              results: results,
            }, function() {
              this.loadUrl();
            });
          }
        } else {
          console.log('handled unmount');
        } 
      }.bind(this));
    });  
  }

  loadUrl() {

    var opts = {
      path: '/transactions/export/products',
      type: 'universal'
    };

    opts.perf_date = this.state.perf_date;

    var url = surl(opts);  
    if(this._isMounted) {
      this.setState({
        url: url
      });
    } else {
      console.log('handled unmount');
    }
  }

  viewAll = () => {
      
    this.setState({
      display: 100
    });

  }

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.loadSales();
    });
  }

  changeSalesMetric = () => { 
    var change_display = '';
    if(this.props.affiliate) {
      change_display = changeAffiliateSalesMetric(this.state.sales_metric);
    } else {
      change_display = changeSalesMetric(this.state.sales_metric);
    }

    subSort(change_display,this.state.results.shows.dates[0].sales,1);

    this.setState({
      sales_metric: change_display,
      results: this.state.results
    });
  };

  render() {

    var title = <h5>Loading</h5>;
    this.state.isLoaded ? (
      title = <h5><span className="text-danger">Top Sellers</span>&nbsp;&nbsp;<small className="text-muted"><button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button> by show, by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>.{!this.props.print ? (<span> Per ticket averages below each metric.</span>) : null } From {moment(this.state.results.shows.dates[0].dates.start).format('dddd, MMMM Do YYYY')} through {moment(this.state.results.shows.dates[0].dates.end).format('dddd, MMMM Do YYYY')}.</small></h5>
    ) : (
      title = <h5><span className="text-danger">Top Sellers</span>&nbsp;&nbsp;<small className="text-muted">By show, by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>.{!this.props.print ? (<span> Per ticket averages below each metric.</span>) : null }</small></h5>
    )

    return (

      <div>

        <Widget title={title} className="widget-thin">

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div>
              {this.props.print ? (

                  <div className="gutter-top-fixed-3">
                    <Table>
                      <thead>
                        <tr>
                          <th width="40%">Show</th>
                          <th width="30%" className="text-center">Face Value</th>
                          <th width="30%" className="text-center">Average<br/>Price</th>
                        </tr>
                      </thead>
                      <tbody> 
                        {this.state.results.shows.dates[0].sales.map((product, i) =>
                          (i < this.state.display) && product.product && (product.face > 1) ? (
                            <tr key={i}>
                              <td className="text-left">
                                <span className="text-capitalize fs-mini">{product.product.short_name || product.product.name }</span>
                              </td>
                              <td className="text-center">
                                <span className="text-capitalize fs-mini">${parseInt(product.face).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                              </td>
                              <td className="text-center">
                                <span className="text-capitalize fs-mini">${parseFloat(product.face/product.qty).toFixed(2)}</span>
                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </Table>
                  </div>
                  
                ) : (
                  <div className="gutter-top-fixed-4">
                    <Row>
                        {this.state.results.shows.dates[0].sales.map((product, i) =>
                          i < this.state.display ? (
                            <Col xs={12} sm={6} md={6} lg={4} xl={3} key={i} >
                              <ProductCard print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                            </Col>
                          ) : null
                        )}
                    </Row>
                    {this.state.display < this.state.results.shows.dates[0].sales.length ? (
                      <div className="text-center">
                        <Button className="btn-success" onClick={() => this.viewAll()}><i className="fa-light fa-arrows-up-down fa-lg gutter-right-fixed-1" />View All</Button>
                      </div>
                      ) : null}
                  </div>
                )}
            </div>

          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAffiliateShows));
