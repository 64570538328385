import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { scurl, compare } from '../../../../core/utils.js';

import Select from 'react-select';

import {
  Row,
  Col,
  Input,
  InputGroup
} from 'reactstrap';

class WidgetAdminMissing extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    venues: PropTypes.array,
    grosses: PropTypes.array
  };

  static defaultProps = {
    product: null,
    venues: null,
    grosses: null
  };

  constructor(props) {
    super(props);

    var product = JSON.parse(JSON.stringify(this.props.product));
    if(product.show_name && product.show_name !== 'Unmapped') {
      product.name = product.show_name;
    } else {
      product.name = '';
    }

    var venues = [];
    for(var venue of this.props.venues) {
      venues.push({ 
        value: venue.id, 
        label: venue.name 
      });
    }

    var grosses = [];
    for(var gross of this.props.grosses) {
      grosses.push({ 
        value: gross.title, 
        label: gross.title
      });
    }

    this.state = {
      isLoaded: true,
      product: product,
      edit: false,
      valid: false,
      venues: venues,
      venueSelected: null,
      grosses: grosses,
      grossSelected: null
    };

    this._isMounted = false;
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleVenuesChange = this.handleVenuesChange.bind(this);
    this.handleGrossesChange = this.handleGrossesChange.bind(this);
  }

  save = () => { 
    this.setState({
      isLoaded: false
    }, function() {

      var valid = true;

      var url = '/products/insert?';
      url += 'name=' + this.state.product.name;
      url += '&show_code=' + this.props.product.show_code;

      if(this.state.venueSelected) {
        url += '&venue_id=' + this.state.venueSelected.value;
      } else {
        valid = false;
      }

      if(this.state.grossesSelected) {
        url += '&grosses_title=' + this.state.grossesSelected.value;
      }

      if(valid) {

        var opts = {
          path: url,
          type: 'simple'
        };

        scurl(opts, function(err, results) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              edit: false
            });
          }
        }.bind(this));
      } else {
        console.log('Not valid');
        console.log(url);
        alert('Not Valid');
      }
    });
  }

  edit = () => { 
    this.setState({
      edit: true,
      valid: false
    });
  }

  handleUpdate = (e) => { 
    this.setState({
      product: { 
        ...this.state.product, 
       [e.target.id]: e.target.value
      }
    }, function() {
      if(this.state.product.venue && this.state.product.name.length > 2) {
        this.setState({
          valid: !compare(this.state.product, this.props.product)
        });
      }
    });
  }

  handleVenuesChange = selectedOption => {

    var product = this.state.product;
    
    product.venue = {
      id: selectedOption.value,
      name: selectedOption.label
    };

    this.setState({ 
      ...this.state,
      product: product,
      venueSelected: selectedOption
    }, function() {
      if(this.state.product.venue && this.state.product.name.length > 2) {
        this.setState({
          valid: !compare(this.state.product, this.props.product)
        });
      }
    });
  };

  handleGrossesChange = selectedOption => {

    var product = this.state.product;
    if(selectedOption.value !== product.name) {
      product.grosses_name = selectedOption.value;
    }

    this.setState({ 
      ...this.state,
      product: product,
      grossSelected: selectedOption
    }, function() {
      if(this.state.product.venue && this.state.product.name.length > 2) {
        this.setState({
          valid: !compare(this.state.product, this.props.product)
        });
      }
    });
  };

  render() {

    return (


      <div className="card gutter-bottom-fixed-4">
        <div className="card-body">

          {this.props.product ? (

            <Row>
              <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                {this.props.product.show_name && this.props.product.show_name !== 'Unmapped' ? (
                  <p className="font-weight-bold pb-0 mb-0">{this.props.product.show_name}</p>
                ) : null}
                <p className="font-weight-bold pb-0 mb-0">{this.props.product.show_code}</p>

                {this.state.edit ? (
                  <Row className="mt-4">
                    <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                      <p className="fs-mini mb-0 pb-0">Name</p>
                      <InputGroup className="fs-mini mt-0 pt-0">
                        <Input id="name" bsSize="16" type="text" value={this.props.product.show_name && this.props.product.show_name !== 'Unmapped' ? this.props.product.show_name : ''} onChange={(e) => this.handleUpdate(e)} />
                      </InputGroup>
                    </Col>

                    <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                      <p className="fs-mini mb-0 pb-0">Venue</p>    
                      <Select
                        value={this.state.venueSelected}
                        onChange={this.handleVenuesChange}
                        options={this.state.venues}
                        placeholder='Select venue...'
                        isMulti={false}
                      /> 
                    </Col>

                    <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                      <p className="fs-mini mb-0 pb-0">Gross Name</p>    
                      <Select
                        value={this.state.grossSelected}
                        onChange={this.handleGrossesChange}
                        options={this.state.grosses}
                        placeholder='Select gross title...'
                        isMulti={false}
                      /> 
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-right">
                {this.state.edit ? (
                  <button className={this.state.valid ? 'btn-success fs-mini' : 'btn-default fs-mini'} onClick={() => this.save()} disabled={!this.state.valid}>INSERT</button>
                ) : (
                  <button className="header-link fs-mini mr-2" onClick={() => this.edit()}>EDIT</button>
                )}
              </Col>
            </Row>

          ) : null}

        </div>
      </div>

      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdminMissing));
