import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';

import { filterUpdated } from '../../../core/utils';

class WidgetUtilsFilter extends React.Component {

  static propTypes = {
    print: PropTypes.bool
  };

  static defaultProps = {
    print: true
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.filter
    }

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FILTER COMPONENT UPDATED')) {
      this.setState({
        filter: this.props.filter
      }, function() {
        console.log('Updated it');
      });
    }
  }

  render() {

    return (

      <Widget className="p-0 m-0">

        {this.state.filter ? (
          <>
            <h5 className="fw-normal gutter-left-fixed-1 gutter-top-fixed-2 gutter-bottom-fixed-2"><span className="text-secondary">Filter</span> <span className="fw-normal text-muted fs-mini">Use blue flyout in upper right of screen</span></h5>

            <div className="d-flex border-top gutter-top-fixed-1">
              <div className="w-50 border-right py-2 px-3">
                <p className="text-muted"><small>SHOWS</small></p>
                {this.state.filter.limit.engagements && (this.state.filter.limit.engagements.length > 0) ? (
                  this.props.filter.limit.engagements.map((engagement, i) =>
                    <p>LIMIT TO: {engagement.show}</p>
                  )
                ) : (
                  <p>All</p>
                )}

                {this.state.filter.exclude.engagements ? (
                  this.props.filter.exclude.engagements.map((engagement, i) =>
                      <p>EXCLUDE: {engagement.show}</p>
                  )
                ) : null}
              </div>
              <div className="w-50 py-2 pl-3">
                <p className="text-muted"><small>SOURCES</small></p>
                {this.state.filter.limit.sources && (this.state.filter.limit.sources.length > 0) ? (
                  this.props.filter.limit.sources.map((source, i) =>
                    <p key={i}>LIMIT: {source.label}</p>
                  )
                ) : (
                  <p>All</p>
                )}

                {this.state.filter.exclude.sources ? (
                  this.props.filter.exclude.sources.map((source, i) =>
                      <p key={i}>EXCLUDE: {source.label}</p>
                  )
                ) : null}
              </div>
            </div>

            <div className="d-flex border-top">
              <div className="w-50 border-right py-2 px-3">
                <p className="text-muted"><small>OWNERS</small></p>
                {this.state.filter.limit.owners && (this.state.filter.limit.owners.length > 0) ? (
                  this.props.filter.limit.owners.map((owner, i) =>
                    <p key={i}>LIMIT: {owner.label}</p>
                  )
                ) : (
                  <p>All</p>
                )}

                {this.state.filter.exclude.owners ? (
                  this.props.filter.exclude.owners.map((owner, i) =>
                      <p key={i}>EXCLUDE: {owner.label}</p>
                  )
                ) : null}
              </div>
              <div className="w-50 py-2 pl-3">
                <p className="text-muted"><small>DEVICES</small></p>
                {this.state.filter.limit.devices && (this.state.filter.limit.devices.length > 0) ? (
                  this.props.filter.limit.devices.map((device, i) =>
                    <p key={i}>LIMIT: {device.label}</p>
                  )
                ) : (
                  <p>All</p>
                )}

                {this.state.filter.exclude.devices ? (
                  this.props.filter.exclude.devices.map((device, i) =>
                      <p key={i}>EXCLUDE: {device.label}</p>
                  )
                ) : null}
              </div>
            </div>
          </>
        ) : (

          <div className="gutter-top-fixed-4 gutter-bottom-fixed-10">
           <h6><strong>NO FILTER</strong></h6>
          </div>

        )}
                    
      </Widget>
 
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetUtilsFilter));
