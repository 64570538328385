import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import WidgetSessions from '../../widgets/content/Sessions';
import WidgetGaSection from '../../widgets/ga/Section';
import WidgetSpacer from '../../widgets/utils/Spacer';

import Select from 'react-select';

import isScreen from '../../core/screenHelper';

class Content extends React.Component {

  constructor(props) {
    super(props);

    let items = [
      { value: '', label: '----' },
      { value: 'channel:Google', label: 'Channel: Google' },
      { value: 'channel:Microsoft', label: 'Channel: Microsoft' },
      { value: '', label: '----' },
      { value: 'type:Generic', label: 'Type: Generic' },
      { value: 'type:Shows', label: 'Type: Shows' },
      { value: 'type:International', label: 'Type: International' },
      { value: 'type:- US', label: 'Type: Domestic' },
      { value: 'type:- NY', label: 'Type: NYC' },
    ];

    let ga_filter = {
      items: items,
      isLoaded: true
    }

    this.state = {
      isLoaded: false,
      ga_filter: ga_filter,
      ga_filter_string: '',
      dropdownOpenTwo: false,
      print: false
    };

    this.toggleTwo = this.toggleTwo.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(){

      var tabSelected = 0;
      var tabs = [
        {
          id: 0,
          icon: 'fa-filter',
          name: 'Summary'
        },
        {
          id: 1,
          icon: 'fa-filter',
          name: 'Top'
        },
        {
          id: 2,
          icon: 'fa-ticket',
          name: 'Shows'
        },
        {
          id: 3,
          icon: 'fa-building',
          name: 'Venues'
        },
        {
          id: 4,
          icon: 'fa-sign',
          name: 'Guides'
        },
        {
          id: 5,
          icon: 'fa-star',
          name: 'Buzz'
        },
        {
          id: 6,
          icon: 'fa-file-text',
          name: 'Other'
        },
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  handleFilterChange = selectedOption => {

    var hasChanged = true;

    let ga_filter_string = '';
    for(var [i,selected] of selectedOption.entries()) {
      if(i > 0) {
        ga_filter_string += ',';
      }
      ga_filter_string += selected.value;
    }

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      ga_filter: {
        ...this.state.ga_filter,
        selected: selectedOption
      },
      ga_filter_string: ga_filter_string
    });
  };

  toggleTwo() {
    this.setState({
      dropdownOpenTwo: !this.state.dropdownOpenTwo,
    });
  }

  print = () => { 
    this.setState({
      print: !this.state.print
    });
  }

  render() {
  
    return (
      <div>

        <Helmet>
          <title>SH4: Site Content Reporting</title>
        </Helmet>

        <div className="menuContainer lg">

          <Row>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <Nav className="mt-4">
                <NavItem className="nopad-bottom nomargin-bottom">
                  
                    <h5 className="text-danger">Site Content</h5>
                    <ButtonGroup>
                    {this.state.tabs ? (
                      this.state.tabs.map((tab, i) =>
                        <Button key={i}
                          color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                          active={this.state.tabSelected === i}
                        ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                        <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                      )
                    ) : null}

                      <ButtonDropdown isOpen={this.state.dropdownOpenTwo} toggle={this.toggleTwo} color="default" className="mr-xs d-none" active={true}>
                        <DropdownToggle><i className={['fa-light','fa-lg','text-danger','fa-star'].join(' ')} />
                        <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">Sections</span></DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Buzz</DropdownItem>
                          <DropdownItem>Guides</DropdownItem>
                          <DropdownItem>Venues</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>Top Level</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>


                    </ButtonGroup>
                </NavItem>
              </Nav>
            </Col>
            <Col xs={12} sm={5} md={5} lg={5} xl={5} className="gutter-top-fixed-2 d-md-down-none">
              <Row className="d-none">
                <Col xs={12} sm={12} md={9} lg={9} xl={6}>
                  <h6>FILTER <span className="text-muted fs-mini">only one per category</span></h6>  
                  <Select
                    value={this.state.ga_filter.selected}
                    onChange={this.handleFilterChange}
                    options={this.state.ga_filter.items}
                    placeholder='Filter results...'
                    isMulti={true}
                  /> 
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={1} md={1} lg={1} xl={1} className="gutter-top-fixed-4 d-md-down-none">
              <button className="header-link" onClick={() => this.print()}>
                {this.state.print ? (
                  <i className={['fa-light','fa-lg','fa-print','text-success'].join(' ')} />
                ) : (
                  <i className={['fa-light','fa-lg','fa-print'].join(' ')} />
                )}
              </button>
            </Col>
          </Row>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetSessions print={this.state.print} />
            </div>
          ): null}
          
          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetGaSection section='top' name='Top Level' print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
              <WidgetGaSection section='shows' name='Show Pages' print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 3 ? (
            <div>
              <WidgetGaSection section='venues' name='Venues' print={false} />
            </div>
          ): null}
          
          {this.state.tabSelected === 4 ? (
            <div>
              <WidgetGaSection section='guides' name='Guide Articles' print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 5 ? (
            <div>
              <WidgetGaSection section='buzz' name='Buzz Articles' print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 6 ? (
            <div>
              <WidgetGaSection section='other' name='Other Pages' print={false} />
            </div>
          ): null}

          <WidgetSpacer print={this.state.print} />
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Content));
