import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { ResponsiveContainer, Line, LineChart, Label, XAxis, YAxis, ReferenceLine, Tooltip, Legend} from 'recharts';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated, nFormatter } from '../../../core/utils';
import isScreen from '../../../core/screenHelper';

class WidgetOpsisHistorical extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    timespan: PropTypes.string,
    chart_metric: PropTypes.string,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    fixed_years: PropTypes.number
  };

  static defaultProps = {
    print: false,
    timespan: 'week',
    chart_metric: 'face',
    chartAspectWidth: 3,
    chartAspectHeight: 1,
    chart: true,
    width: 1050,
    height: 200,
    fixed_years: 4
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      chart_type: 'sales',
      chart_metric: this.props.chart_metric,
      chart_timespan: 'day',
      fixed_years: this.props.fixed_years,
      chartAspectWidth: this.props.chartAspectWidth,
      chartAspectHeight: this.props.chartAspectHeight,
    }

    this._isMounted = false;
    this.loadHistory = this.loadHistory.bind(this);
    this.setChartData = this.setChartData.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.areaLabelFormat = this.areaLabelFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
    this.changeChartType = this.changeChartType.bind(this);
    this.displayChartType = this.displayChartType.bind(this);
    this.changeChartMetric = this.changeChartMetric.bind(this);
    this.displayChartMetric = this.displayChartMetric.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadHistory();

    this.setChartAspect();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'HISTORICAL WIDGET')) {
      this.loadHistory();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadHistory() {
    
    this.setState({
      isLoaded: false,
      results: [],
      chart_timespan: 'week'
    }, function() {

      var opts = {
        path: '/v2/opsis/historical',
        type: 'universal'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            }, function() {
              this.setChartData();
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  setChartData() {
    var chartData = [];

    var min = 0.5;
    var max = 1.1;

    if(this.state.results && this.state.results.weeks && this.state.results.weeks.length > 0) {

      this.state.results.weeks.forEach(function(block, i) {
        
        var d = {
          name: block.key
        };

        if(this.state.chart_type === 'baseline') {
          d.year1 = block.forecast_weight[this.state.chart_metric];
          d.average = 0;
          min = 1;
          max = 1;
        } else {
          d.year1 = block.sales[0][this.state.chart_metric];
          if(block.sales[1]) {
            d.year2 = block.sales[1][this.state.chart_metric];
          }
          if(block.sales[2]) {
            d.year3 = block.sales[2][this.state.chart_metric];
          }
          d.average = block.average[this.state.chart_metric];
        }

        chartData.push(d);
       
      }.bind(this));

      var reflines = [];

      this.setState({
        chart: {
          data: chartData,
          reflines: reflines,
          properties: {
            max: max,
            min: min
          }
        }
      });
    }
  }

  salesSummaryDataFormat(x) {
    if(this.state.chart_type === 'baseline') {
      return parseFloat(x*100).toFixed(0) + '%';
    } else {
      if(this.state.chart_metric === 'qty') {
        return nFormatter(x);
      } else {
        return '$' + nFormatter(x);
      }
    }
  };

  salesSummaryDateFormat(x) {
    if(this.state.chart_timespan === 'day') {
      return moment(x).format('M/DD');
    } else {
      if(this.state.chart_timespan === 'week') {
        return moment(x).format('M/DD');
      } else {
        return moment(x).format('MMM');
      }
    }
  };

  areaLabelFormat(x) {
    return x
  };

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: this.props.chartAspectWidth,
        chartAspectHeight: this.props.chartAspectHeight
      });
    }
  }

  changeChartMetric = () => { 
    
    var change_display = '';

    switch(this.state.chart_metric) {
      case 'qty':
        change_display = 'face';
        break;
      case 'face':
        change_display = 'fees';
        break;
      default:
        change_display = 'qty';
        break;
    }

    this.setState({
      chart_metric: change_display
    }, function() {
      this.setChartData();
    });

  };

  displayChartMetric = () => { 
    switch(this.state.chart_metric) {
      case 'qty':
        return 'Quantity';
      case 'face':
        return 'Face Value';
      case 'fees':
        return 'Service Fees';
      default:
        return '--'
    }
  };

  changeChartType = () => { 
    
    var chart_type = '';

    switch(this.state.chart_type) {
      case 'sales':
        chart_type = 'baseline';
        break;
      default:
        chart_type = 'sales';
        break;
    }

    this.setState({
      chart_type: chart_type
    }, function() {
      this.setChartData();
    });

  };

  displayChartType = () => { 
    switch(this.state.chart_type) {
      case 'sales':
        return 'Sales Data';
      case 'baseline':
        return '+/- Over Baseline';
      default:
        return '--'
    }
  };

  render() {

    var title = <h5><span className="text-danger">Historical Baseline</span> <small className="text-muted"><button className="header-link" onClick={() => this.changeChartMetric()}>{this.displayChartMetric()}</button> for 2019 and 2018 shown as <button className="header-link" onClick={() => this.changeChartType()}>{this.displayChartType()}</button></small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            
            <div className="gutter-top-fixed-2">

              <p><strong>Forecast Date: {this.state.results.weeks[0].forecast_date}</strong></p>

              {this.state.isLoaded && this.state.chart && this.state.chart.data ? (
                <div className="gutter-top-fixed-3">

                  {this.state.isLoaded && this.state.chart && this.state.chart.data ? (
                    <div className="gutter-top-fixed-3">

                      {this.props.print ? (
                        <div>
                          {this.props.chart && this.state.chart.data.length > 1 ? (
                            <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                              <defs>
                                <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                                </linearGradient>
                                <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                                </linearGradient>
                              </defs>
                              <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                              <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max) ]} />

                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year1 ? (
                                <Line name="2019" type="monotone" dataKey="year1" stroke="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 0.5 }} />
                              ) : null }
                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year2 ? (
                                <Line name="2018" type="monotone" dataKey="year2" stroke="#ccc" dot={{ stroke: '#ccc', strokeWidth: 1 }} />
                              ) : null }
                              <Line name="average" type="monotone" dataKey="average" stroke="#0087a9"  dot={false} strokeWidth={2} />
                              <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                            </LineChart>
                            
                          ) : null}
                        </div>
                      ) : (
                        <ResponsiveContainer width='100%' aspect={this.state.chartAspectWidth/this.state.chartAspectHeight} >
                          <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                              <defs>
                                <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                                </linearGradient>
                                <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                                </linearGradient>
                              </defs>
                              <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                              <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max) ]} />
                              {this.state.chart.reflines.length > 0 ? (
                                this.state.chart.reflines.map((refline, i) =>
                                  <ReferenceLine key={i} x={refline.x} isFront={true} stroke="lightgray">
                                    <Label value={refline.label} offset={refline.offset} position="top" fill="black" style={{fontSize: '0.8rem' }} />
                                  </ReferenceLine>
                                )
                              ) : null }

                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year1 ? (
                                <Line name="2019" type="monotone" dataKey="year1" stroke="#82ca9d" dot={false} />
                              ) : null }
                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year2 ? (
                                <Line name="2018" type="monotone" dataKey="year2" stroke="#0087a9" dot={false} />
                              ) : null }
                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year3 ? (
                                <Line name="2017" type="monotone" dataKey="year3" stroke="#ffc647" dot={false} />
                              ) : null }
                              <Line name="Average" type="monotone" dataKey="average" stroke="#777" dot={false} strokeWidth={4} strokeDasharray="3 3" />
                              <Legend verticalAlign="top" height={36} align="right" iconType="square" />

                              <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  ): null}
                </div>
              ): null}

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisHistorical));
