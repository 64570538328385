import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Displays from '../../../../components/Displays';

import { Row, Col } from 'reactstrap';

class OpsisGaPage extends React.Component {

  static propTypes = {
    page: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
        print: this.props.print,
        expand: false
    };
  }

  toggle = () => { 
    this.setState({
      expand: !this.state.expand
    });
  }

  render() {

    return (

        <div className="gutter-bottom-fixed-1 bg-light border">

            <div className="d-flex flex-row m-0 p-0">
                <div className="p-0">
                    {this.props.page.properties && this.props.page.properties.img ? (
                    <img src={this.props.page.properties.img} alt="thumbnail" className="img-fluid " width={200} />
                    ) : null}
                </div>
                <div className="flex-fill p-2">
                    {this.props.page.properties && this.props.page.properties.title ? (
                        <p className="fw-bold">{this.props.page.properties.title}</p>
                    ) : (
                        <p className="fw-bold">{this.props.page.path}</p>
                    )}
                </div>
            </div>

            <hr className="pt-0 mt-0" />
            <Row>
              <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Landings</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Views</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Organic</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Paid</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">GNews</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Facebook</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Twitter</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Instagram</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">Threads</p>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                    <p className="fs-mini text-muted text-center">iOS App</p>
                </Col>
            </Row>
            {this.props.page.years.map((year, i) =>
              <Row key={i}>
                <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.landings.total} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.total} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.organic} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.cpc} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.google_news} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.facebook} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.twitter} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.instagram} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.threads} format="comma" /></p>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={1} xl={1}>
                      <p className="text-center"><Displays a={year.views.app} format="comma" /></p>
                  </Col>
              </Row>
            )}

        </div>
    
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(OpsisGaPage));






