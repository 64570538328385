import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import { logger } from 'redux-logger'
import App from './components/App';
import _ from 'lodash';
import {saveState, loadState} from './core/localStorage.js';
import { createRoot } from 'react-dom/client'

import reducers from './reducers';

const persistedState = loadState();

const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(ReduxThunk,logger)
)

store.subscribe(_.throttle(() => {
  saveState(store.getState());
}, 500));

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <App />
    </Provider>
);
