import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import Widget from '../../components/Widget';
import s from './Login.module.scss';
import Cookies from 'js-cookie';
import { setFilter } from '../../actions/filter';

class Login extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    var q = new URL(window.location.href.replace('#/',''));
    var login_error = q.searchParams.get('error');

    var azure_url = 'https://api.shadow.haus/v2/users/auths/azure';
    if(window.location.href.includes('localhost')) {
      azure_url = 'http://127.0.0.1:5050/v2/users/auths/azure';
    }

    var google_url = 'https://api.shadow.haus/v2/users/auths/gmail';
    if(window.location.href.includes('localhost')) {
      google_url = 'http://127.0.0.1:5050/v2/users/auths/gmail';
    }

    if(login_error) {
      Cookies.remove("sh_token");
      localStorage.removeItem("sh_state");
      this.state = {
        google_url: google_url,
        azure_url: azure_url,
        login_error: 'Login failed'
      };
    } else {
      var token = q.searchParams.get('token');
      if(token) {
        Cookies.set('sh_token', token, { expires: 7, path: '' });
        this.props.dispatch(setFilter());
        this.props.history.push('/');
      } else {
        Cookies.remove("sh_token");
        localStorage.removeItem("sh_state");
        this.state = {
          google_url: google_url,
          azure_url: azure_url
        };
      }
    }
  }

  render() {
    return (
      <div className={s.root}>

        <Helmet>
          <title>SH4: Login</title>
        </Helmet>

        <Container>
          <Widget className={`${s.widget} mx-auto`} title={<h3 className="mt-0"><span className="text-warning">SH</span>4</h3>}>
            <p className={s.widgetLoginInfo}>
              {this.state && this.state.login_error ? (
                <h3 className="text-danger fw-bold">{this.state.login_error}</h3>
              ) : (
                <span>Please sign in to access</span>
              )}
            </p>
            <Row className="gutter-top-fixed-4">
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className="text-center">
                  {this.state && this.state.google_url ? (
                    <a href={this.state.google_url} className="btn btn-inverse btn-sm"><i className="fa-brands fa-google fa-lg"></i>&nbsp;&nbsp;Login with Google</a>
                  ) : null}
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className="text-center">
                  {this.state && this.state.azure_url ? (
                    <a href={this.state.azure_url} className="btn btn-inverse btn-sm"><i className="fa-brands fa-microsoft fa-lg"></i>&nbsp;&nbsp;Login with SSO</a>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Widget>
        </Container>
        <footer className={s.footer}>
          SH4
        </footer>
      </div>
    );
  }
}

export default withRouter(connect()(Login));

