import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import PageCard from './cards/Page.js';

import isScreen from '../../../core/screenHelper';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay } from '../../../core/utils';

class WidgetGaSection extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    section: PropTypes.string,
    name: PropTypes.string,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    ga_filter: PropTypes.string,
    ga_property: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    timespan: 'days',
    fixed_years: 1,
    ga_filter: '',
    ga_property: 'device.type'
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);

    var page_count = 32;
    var page_cols = [12,12,12,12,12];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        page_count = 20
        page_cols = [12,12,12,12,12];
      } else {
        if(isScreen('md')) {
          page_count = 6
          page_cols = [4,4,6,4,4];
        }
      }
    }

    this.state = {
      isLoaded: false,
      isError: false,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      page_count: page_count,
      page_cols: page_cols
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
    this.setDateRangeDisplay = this.setDateRangeDisplay.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'GA WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.ga_filter !== this.props.ga_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    this.setFilterText();
    this.setDateRangeDisplay();
    
    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/ga/pageviews',
        type: 'universal',
        section: this.props.section,
        years: 2
      };

      if( (this.props.section === 'buzz') || (this.props.section === 'guides') ) {
        opts.years = 1;
      }

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(this.props.ga_filter.length > 0) {
        opts.ga_filter = this.props.ga_filter;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                //console.log(this.state.results)
                //this.setChartData();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  setFilterText() {
    this.setState({
      ...this.state,
      filterText: this.props.skai_filter
    });
  };

  setDateRangeDisplay() {
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    this.setState({
      ...this.state,
      dateRangeDisplay: drd
    });
  }

  render() {

    var title = <h5><span className="text-danger">{this.props.name}</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>Content data from {this.state.dateRangeDisplay} .&nbsp;</span>) : null}Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>;
    
    return (

      <div className="page-divide">

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div className="gutter-top-fixed-2 gutter-bottom-fixed-2">

              <div className="w-100">
                <Row>
                    {this.state.results.pages.map((page, i) =>
                      i < this.state.page_count ? (
                        <Col xs={this.state.page_cols[0]} sm={this.state.page_cols[1]} md={this.state.page_cols[2]} lg={this.state.page_cols[3]} xl={this.state.page_cols[4]} key={i} className="gutter-top-fixed-2 w-100">
                            <PageCard print={this.props.print} page={page} />
                        </Col>
                      ) : null
                  )}
                </Row>
              </div>
            
            </div>
          ) : null}
        </Widget>


      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetGaSection));
