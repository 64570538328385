import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { Row, Col, Media, Button } from 'reactstrap';

import { scurl } from '../../../../core/utils.js';

class WidgetGallery extends React.Component {

  static propTypes = {
    gallery: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      style: {
        maxHeight: 128,
        maxWidth: 128
      },
      show: true,
      broken: []
    }

    this._isMounted = false;
    this.action = this.action.bind(this);
    this.handleBroken = this.handleBroken.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  action = (status) => {    
    
    var opts = {
      path: '/cms/galleries/' + this.props.gallery.id + '?action=' + status,
      type: 'simple'
    };

    scurl(opts, function(err, results) {
      if(this._isMounted) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            show:false
          });
        }
      } else {
        console.log('handled unmount');
      }
    }.bind(this));

    
  }

  handleBroken(ev){
    var broken = this.state.broken;
    broken.push(ev.target.src);
    this.setState({
      broken: broken
    }, function() {
      ev.target.src = 'https://www.harriscountyso.org/Content/images/ReportCrimes/MissingPerson1.jpg';
    });
  }

  render() {

    return (

      <div>

        {this.props.gallery && this.state && this.state.show ? (
          <div className="card gutter-bottom-fixed-4">
            <div className="card-body">
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6} xl={8} className="pl-4">
                    <h5>
                      <a href={this.props.gallery.url} dangerouslySetInnerHTML={{ __html: this.props.gallery.title }} target="_blank" rel="noopener noreferrer" /> 
                    </h5>
                    <p className="small">{moment(this.props.gallery.pub_date, 'YYYY-MM-DD').format('MMM DD, YYYY')}</p>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4} className="text-right">
                    <Button className="btn-success small" onClick={() => this.action(1)}><i className="fa-light fa-check-to-slot fa-lg" /><span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">KEEP</span></Button>
                    &nbsp;&nbsp;
                    <Button className="btn-danger text-white small" onClick={() => this.action(0)}><i className="fa-light fa-trash fa-lg" /><span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">KILL</span></Button>
                  </Col>
                </Row>

                <Row className="p-2 pt-4">
                  {this.props.gallery.photos.map((photo, i) =>
                    i < 6 ? (
                      <Col xs={12} sm={2} md={2} lg={2} xl={2} key={i} className="text-center nopad nomargin">
                        <a href={photo.imgs[photo.imgs.length-2]} target="_blank" rel="noopener noreferrer"> 
                          <Media object src={photo.imgs[photo.imgs.length-2]} style={this.state.style} onError={this.handleBroken} alt='' />
                        </a>
                      </Col>
                    ) : null
                  )}
                </Row>

                {this.state.broken && this.state.broken.length > 0 ? (
                  <div className="pt-4">
                    <p className="m-0 p-0">+{this.state.broken.length} failed</p>
                    {this.state.broken.map((img, i) => 
                      <span key={i} className="fs-sm text-muted">{img}
                        {(i < this.state.broken.length - 1) ? (
                          <span className="gutter-left-fixed-1 gutter-right-fixed-1">|</span>
                        ) : null }
                      </span>
                    )}
                  </div>
                ) : null}

            </div>
          </div>
        ) : null }
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetGallery));
