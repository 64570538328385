import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DateTime } from 'luxon/build/node/luxon';

import {
  Table
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, dateRangeDisplay } from '../../../core/utils';

import isScreen from '../../../core/screenHelper';

import { XAxis, YAxis, CartesianGrid, BarChart, Bar, LabelList } from 'recharts';

import Chart from '../../charts/Chart.js';

const renderCustomizedLabel = (props) => {
  
  const { x, y, width, height, value } = props;
  const fireOffset = 0;

  let html = <div>{value}</div>;
  if(value && value.length > 0) {
    html = <div style={{textAlign: 'left'}}>{value.toString().split(':')[0]}<br/>{value.toString().split(':')[1]}</div>;
  }

  return (
      <g>
        <foreignObject x={x + 10} y={y + 4} width={width} height={height} fill={fireOffset ? "#285A64" :"#000"} fontSize={9}>
          <div>{html}</div>
        </foreignObject>
      </g>

  );
};

class WidgetShare extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    fixed_years: PropTypes.number,
    product_id: PropTypes.number,
    ga_filter: PropTypes.string,
    ga_property: PropTypes.string,
    field: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  };

  static defaultProps = {
    print: false,
    fixed_years: 3,
    ga_filter: '',
    ga_property: 'device.type',
    className: '',
    title: 'Transaction Share',
    description: 'Data'
  };

  constructor(props) {
    super(props);

    let drd = dateRangeDisplay(this.props.filter.dates, 'MMM dd, yyyy');
    
    this.state = {
      isLoaded: false,
      isError: false,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      dateRangeDisplay: drd,
      share_chart: {
        loaded: false,
        data: [],
        properties: {
          max: 1,
          min: 1,
          col: 4,
          width: 1000,
          height: 300,
          aspect_width: 3,
          aspect_height: 1
        }
      },
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setShareChart = this.setShareChart.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'GA WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.ga_filter !== this.props.ga_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    this.setFilterText();
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/ga/transactions',
        type: 'universal',
        years: this.props.fixed_years
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(this.props.ga_filter.length > 0) {
        opts.ga_filter = this.props.ga_filter;
      }

      if(this.props.ga_property.length > 0) {
        opts.ga_property = this.props.ga_property;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                //console.log(this.state.results)
                this.setShareChart();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  setShareChart() {

    let chartData = [];

    const max = 4;
    
    this.state.results.totals.forEach(function(year, y) {
      
      let fixed = 0;
      if(isScreen('xl')) {
        fixed = 1;
      }

      let total = 0;

      let d = {
        name: DateTime.fromISO(this.state.results.dates[y].start).toFormat('yyyy')
      };

      year.properties.forEach(function(block, i) {
        if(i < max) {
          d['f'+i] = block.share;
          total += block.share;
          if(block.share > 0.05) {
            d['f'+i+'_label'] = block.name + ': ' + parseFloat(block.share*100).toFixed(fixed) + '%';
          } else {
            d['f'+i+'_label'] = '';
          }
        }
      });

      if(total < 1) {
        d['f'+max] = parseFloat(1-total);
        if(d['f'+max] > 0.1) {
          d['f'+max+'_label'] = parseFloat(parseFloat(1-total)*100).toFixed(fixed) + '%';
        }
      }

      chartData.push(d);
      
    }.bind(this));

    let wedge = [];
    for (let y = 0; y <= max; y++) {
      wedge.push(y);
    }

    this.setState({
      ...this.state,
      share_chart: {
        ...this.state.share_chart,
        loaded: true,
        data: chartData,
        bars: this.state.results.totals.length,
        show: true,
        properties: {
          ...this.state.share_chart.properties,
          wedge: wedge
        },
      }
    }, function() {
      this.setChartAspect();
      //window.addEventListener('resize', this.handleResize.bind(this));
    });
  };

  setChartAspect() {
    if(this.props.print) {
      this.setState({
        ...this.state,
        share_chart: {
          ...this.state.share_chart,
          properties: {
            ...this.state.share_chart.properties,
            width: 450,
            height: 150,
            aspect_width: 2,
            aspect_height: 1
          }
        }
      });
    } else {
      if((isScreen('xs') || isScreen('sm'))) {
        this.setState({
          ...this.state,
          share_chart: {
            ...this.state.share_chart,
            properties: {
              ...this.state.share_chart.properties,
              width: 350,
              height: 150,
              aspect_width: 2,
              aspect_height: 1
            }
          }
        });
      } else {
        if((isScreen('md') || isScreen('lg'))) {
          this.setState({
            ...this.state,
            share_chart: {
              ...this.state.share_chart,
              properties: {
                ...this.state.share_chart.properties,
                width: 350,
                height: 150,
                aspect_width: 2,
                aspect_height: 1
              }
            }
          });
        } else {
          this.setState({
            ...this.state,
            share_chart: {
              ...this.state.share_chart,
              properties: {
                ...this.state.share_chart.properties,
                width: 350,
                height: 150,
                aspect_width: 2,
                aspect_height: 1
              }
            }
          });
        }
      }
    }
  };

  setFilterText() {
    this.setState({
      ...this.state,
      filterText: this.props.ga_filter
    });
  };

  chartShareDataFormat(value) {
    return parseInt(value*100) + '%';
  };

  barColors(x, set) {

    set = set | 0;

    let collections = [];
    collections.push(["#edf2fb", "#e2eafc", "#d7e3fc", "#ccdbfd", "#c1d3fe", "#b6ccfe", "#abc4ff"]);
    collections.push(["#6b9080", "#a4c3b2", "#cce3de", "#eaf4f4", "#f6fff8" ]);

    return collections[set][x];
  };

  handleClick(data, index) {
    let year = data.activeTooltipIndex | 0;
    let results = this.state.results.totals[year].properties;

    if(this.state.share_table && this.state.share_table.year && (this.state.share_table.year === DateTime.fromISO(this.state.results.dates[year].start).toFormat('yyyy'))) {
      this.setState({
        ...this.state,
        share_table: {
          ...this.state.share_table,
          show: false
        }
      });
    } else {
      this.setState({
        ...this.state,
        share_table: {
          ...this.state.share_table,
          results: results,
          year: DateTime.fromISO(this.state.results.dates[year].start).toFormat('yyyy'),
          show: true
        }
      });
    }

  };

  render() {

    var title = <h5><span className="text-danger">{this.props.title}</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>{this.props.description} from {this.state.dateRangeDisplay} .&nbsp;</span>) : null}</small></h5>;
    if(this.props.print) {
      title = <p className="fs-mini"><span className="text-danger">{this.props.title}</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>{this.state.dateRangeDisplay}</span>) : null}</small></p>
    }
    return (

      <div className={this.props.className}>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div className="mt-3">
              {this.state.share_chart.loaded ? (
                <>
                  <Chart
                    chart ={
                      <BarChart 
                        width={this.state.share_chart.properties.width} 
                        height={this.state.share_chart.properties.height} 
                        data={this.state.share_chart.data} 
                        stackOffset="sign" 
                        margin={0} 
                        padding={0} 
                        layout="vertical"
                        onClick={this.handleClick}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" tickFormatter={this.chartShareDataFormat} fontSize={isScreen('xl') ? 10 : 9} />
                        <YAxis dataKey="name" type="category" fontSize={isScreen('xl') ? 10 : 9} padding={{top: 0, right: 0, left: 0, bottom: 0}} margin={{top: 0, right: 0, left: 0, bottom: 0}} />

                        {this.state.share_chart.properties.wedge.map((year, i) =>
                          <Bar dataKey={`f${i}`} fill={this.barColors(i,0)} stackId="stack" key={i}>
                            <LabelList
                              dataKey={`f${i}_label`}
                              position="insideLeft"
                              content={renderCustomizedLabel}
                            />
                          </Bar>
                        )}
                      </BarChart>
                    }
                    print={this.state.print}
                    chart_aspect_width={this.state.share_chart.properties.aspect_width}
                    chart_aspect_height={this.state.share_chart.properties.aspect_height}
                  />
                  {this.state.share_table && this.state.share_table.show ? (
                    <div className="mt-4">
                      <p className="fs-mini text-muted">Complete results for {this.state.share_table.year}.</p>
                      <Table className="mt-4 fs-mini">
                        <thead>
                          <tr>
                            <th width="50%">Property</th>
                            <th width="50%">Share</th>
                          </tr>
                        </thead>
                        <tbody> 
                          {this.state.share_table.results.map((result, x) =>
                            <tr key={x}>
                              <td>
                                <span>{result.name}</span>
                              </td>
                              <td>
                                <span>{parseFloat(result.share*100).toFixed(1)}%</span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    !this.state.print ? (
                      <p className="fs-mini text-muted pb-0 mb-0">click bar/year for complete details</p>
                    ) : null
                  )}
                </>
              ):null}
            </div>
          ) : null}
        </Widget>


      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetShare));
