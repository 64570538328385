import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { scurl, getUser } from '../../core/utils.js';
import isScreen from '../../core/screenHelper';
import { Helmet } from 'react-helmet';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup,
} from 'reactstrap';

import Widget from '../../components/Widget/Widget';

import Poster from '../../components/Poster';

import WidgetAffiliateSales from '../../widgets/affiliates/Timespans';
import WidgetAffiliateField from '../../widgets/affiliates/Field';

class ProductAffiliate extends React.Component {

  constructor(props) {
    super(props);

    var tabs = [
      {
        id: 0,
        icon: 'fa-circle-dollar-to-slot',
        name: 'Summary'
      },
      {
        id: 1,
        icon: 'fa-users-gear',
        name: 'Details'
      },
    ];

    this.state = {
      isLoaded: false,
      tabSelected: 0,
      tabs: tabs
    };

    this.setEngagement = this.setEngagement.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setEngagement(this.props.match.params.id);
    this.setTabs();
  };

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  setEngagement = (id) => {

    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/products/' + id,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            results: results,
          });
        }
      }.bind(this));
    }); 

  }

  setTabs = () => { 
    getUser(function(err, user) {
      if(err) {
        console.log('error retreiving user');
      } else {
        var tabs = this.state.tabs;
        this.setState({
          tabs: tabs
        });
      }
    }.bind(this));
  }

  render() {

    return (
      
      <div>

        {this.state && this.state.isLoaded ? (
          <div>
            
            {this.state.results.product ? (
              <div>

                <Helmet>
                  <title>SH4: {this.state.results.product.short_name || this.state.results.product.name}</title>
                </Helmet>

                <div className="menuContainer lg">
                  <Nav>
                    <NavItem className="nopad-bottom nomargin-bottom">
                      {this.state.results.product.poster_id ? (
                        <Poster id={this.state.results.product.poster_id} w={75} />
                      ) : (
                        <Poster w={75} />
                      )}
                    </NavItem>
                    <NavItem className="nopad-bottom nomargin-bottom gutter-left-fixed-2">
                      <h4 className="page-title">
                        {this.state.results.product.short_name || this.state.results.product.name}<br/>
                        {this.state.results.product.venue ? (
                          <small className="text-muted">{this.state.results.product.venue.name}</small>
                        ) : null}
                      </h4>
                      <ButtonGroup className="gutter-top-fixed-1">
                        {this.state.tabs.map((tab, i) =>
                          <Button key={i}
                            color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                            active={this.state.tabSelected === i}
                          ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                          <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                        )}
                      </ButtonGroup>
                    </NavItem>
                  </Nav>
                </div>

                <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

                {this.state.results.product ? (
                  <div>

                    {this.state.tabSelected === -1 ? (
                      <div className="m-4 p-4">
                        Please select tab above
                      </div>
                    ): null}

                    {this.state.tabSelected === 0 ? (
                      <div>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <WidgetAffiliateSales product_id={this.state.results.product.id} />
                          </Col>
                        </Row>
                      </div>
                    ): null}

                    {this.state.tabSelected === 1 ? (
                      <div>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <WidgetAffiliateField field="$customer.dma" title="DMA" field_header="DMA" product_id={this.state.results.product.id} refunds={0} />
                            <WidgetAffiliateField field="$customer.country" title="Country" field_header="Country" className="gutter-top-fixed-2" product_id={this.state.results.product.id} refunds={0} />
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <WidgetAffiliateField field="$wallet" title="Wallets" field_header="Wallet" className="gutter-top-fixed-2" share_to_total={true} product_id={this.state.results.product.id} refunds={0} />
                            <WidgetAffiliateField field="$quantity" title="Per Order QTY" field_header="QTY" className="gutter-top-fixed-2" product_id={this.state.results.product.id} refunds={0} />
                          </Col>
                        </Row>
                      </div>
                    ): null}
                  </div>
                ) : null}
                 </div>
              </div>
            ) : (
              this.state.error ? (
                <Widget>
                  <h5 className="page-title text-danger">
                    Show failed to load<br/>
                    <br/><small className="text-muted">{this.state.error.message}</small>
                  </h5>
                </Widget>
              ) : null
            )}
          </div>
          
        ) : (
          <p>Loading...</p>
        )}

      </div>
    );

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(ProductAffiliate));
