import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import WidgetOpsisHistorical from '../../../widgets/opsis/Historical';
import WidgetOpsisScaffold from '../../../widgets/opsis/Scaffold';
import WidgetOpsisVariances from '../../../widgets/opsis/Variances';

import isScreen from '../../../core/screenHelper';

class OpsisAdmin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(){

      var tabSelected = 0;
      var tabs = [
        {
          id: 0,
          icon: 'fa-code',
          name: 'Variances'
        },
        {
          id: 1,
          icon: 'fa-cog',
          name: 'Scaffold'
        },
        {
          id: 2,
          icon: 'fa-chart-line',
          name: 'Historical'
        }
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  selectOpsis = (e) => {
    e.preventDefault();
    this.props.history.push('/app/opsis/');
  }

  render() {
  
    return (
      <div>

        <div className="menuContainer lg">
          <Nav className="mt-4">
            <NavItem className="nopad-bottom nomargin-bottom">
              
                <h5 className="text-danger">Opsis : Admin</h5>

                {this.state.tabs ? (
                <ButtonGroup>
                  <Button color="default" className="mr-xs" onClick={((e) => this.selectOpsis(e))}><i className="fa fa-circle-arrow-left fa-light fa-lg text-danger" /></Button>
                  {this.state.tabs.map((tab, i) =>
                    <Button key={i}
                      color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                      active={this.state.tabSelected === i}
                    ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                    <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                  )}
                </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetOpsisVariances print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetOpsisScaffold print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
              <WidgetOpsisHistorical print={false} />
            </div>
          ): null} 

        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(OpsisAdmin));
