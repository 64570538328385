import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { Button } from 'reactstrap';

import { surl } from '../../../core/utils.js';

import { updateFilter } from '../../../actions/filter';

class FilterLimit extends React.Component {
  
  static propTypes = {
    changeFilterTab: PropTypes.func.isRequired,
    toggleFilter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    var devices = [
      { value: 'desktop', label: 'Desktop' },
      { value: 'mobile', label: 'Mobile' },
      { value: 'tablet', label: 'Tablet' },
    ];

    var sources = [
      { value: 'BROADWAY.COM', label: 'broadway.com' },
      { value: 'NYC.COM', label: 'NYC.com' },
      { value: 'BROADWAYBOX', label: 'BroadwayBox' },
      { value: 'TIME OUT AMERICA, LLC', label: 'Timeout NY' }
    ];

    var owners = [
      { value: 'Nederlander-Organization', label: 'Nederlander' },
      { value: 'Shubert-Organization', label: 'Shubert' },
      { value: 'Jujamcyn-Theaters', label: 'Jujamcyn' },
      { value: 'Disney-Theatrical', label: 'Disney' },
      { value: 'Ambassador-Theatre-Group', label: 'ATG' },
      { value: 'Roundabout-Theatre-Company', label: 'Roundabout' },
      { value: 'Second-Stage-Theater', label: '2nd Stage' },
      { value: 'Manhattan-Theatre-Club', label: 'MTC' },
      { value: 'Independent-Theaters', label: 'Independent' }
    ];

    var mediums = [
      { value: 'cpc', label: 'Paid Search' },
      { value: 'organic', label: 'Organic Search' },
      { value: 'email', label: 'Email' },
      { value: 'display', label: 'Display Ad' },
      { value: 'referral', label: 'Referral' },
      { value: 'banner', label: 'Banner Ad' },
      { value: 'paid-social', label: 'Paid Social' }
    ];

    var refunds = false;
    if(this.props.filter.limit.refunds) {
      refunds = this.props.filter.limit.refunds;
    }

    var underwater = false;
    if(this.props.filter.limit.underwater) {
      underwater = this.props.filter.limit.underwater;
    }

    var chargeback = false;
    if(this.props.filter.limit.chargeback) {
      chargeback = this.props.filter.limit.chargeback;
    }

    this.state = {
      hasChanged: false,
      engagements: {
        items: [],
        selected: [],
        isLoaded: false
      },
      owners: {
        items: owners,
        selected: this.props.filter.limit.owners
      },
      sources: {
        items: sources,
        selected: this.props.filter.limit.sources
      },
      devices: {
        items: devices,
        selected: this.props.filter.limit.devices
      },
      mediums: {
        items: mediums,
        selected: this.props.filter.limit.mediums
      },
      refunds: refunds,
      underwater: underwater,
      chargeback: chargeback
    };


    this.handleEngagementsChange = this.handleEngagementsChange.bind(this);
    this.handleOwnersChange = this.handleOwnersChange.bind(this);
    this.handleSourcesChange = this.handleSourcesChange.bind(this);
    this.handleDevicesChange = this.handleDevicesChange.bind(this);
    this.handleRefundChange = this.handleRefundChange.bind(this);
    this.handleUnderwaterChange = this.handleUnderwaterChange.bind(this);
    this.handleChargebackChange = this.handleChargebackChange.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.getActiveShows = this.getActiveShows.bind(this);
  }

  componentDidMount() {
    this.getActiveShows();
  }

  handleEngagementsChange = selectedOptions => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      engagements: {
        ...this.state.engagements,
        selected: selectedOptions
      }
    });
  };

  handleOwnersChange = selectedOptions => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      owners: {
        ...this.state.owners,
        selected: selectedOptions
      }
    });
  };

  handleSourcesChange = selectedOptions => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      sources: {
        ...this.state.sources,
        selected: selectedOptions
      }
    });
  };

  handleDevicesChange = selectedOptions => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      devices: {
        ...this.state.devices,
        selected: selectedOptions
      }
    });
  };

  handleMediumsChange = selectedOptions => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      mediums: {
        ...this.state.mediums,
        selected: selectedOptions
      }
    });
  };

  handleRefundChange = e => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      refunds: e.target.checked
    });
  };

  handleUnderwaterChange = e => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      underwater: e.target.checked
    });
  };

  handleChargebackChange = e => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      chargeback: e.target.checked
    });
  };

  applyChanges() {

    var filter = JSON.parse(JSON.stringify(this.props.filter));

    filter.limit.devices = [];
    for (var includedDevice of this.state.devices.selected) {
      var dupDevice = false;
      for (var dupCheckDevice of filter.limit.devices) {
        if(dupCheckDevice.id === includedDevice.value) {
          dupDevice = true;
          break;
        }
      }
      if(!dupDevice) {
        filter.limit.devices.push({
          id: includedDevice.value,
          label: includedDevice.label
        });
      }
    }

    filter.limit.owners = [];
    for (var includedOwner of this.state.owners.selected) {
      var dupOwner = false;
      for (var dupCheckOwner of filter.limit.owners) {
        if(dupCheckOwner.id === includedOwner.value) {
          dupOwner = true;
          break;
        }
      }
      if(!dupOwner) {
        filter.limit.owners.push({
          id: includedOwner.value,
          label: includedOwner.label
        });
      }
    }

    filter.limit.sources = [];
    for (var includedSource of this.state.sources.selected) {
      var dupSource = false;
      for (var dupCheckSource of filter.limit.sources) {
        if(dupCheckSource.id === includedSource.value) {
          dupSource = true;
          break;
        }
      }
      if(!dupSource) {
        filter.limit.sources.push({
          id: includedSource.value,
          label: includedSource.label
        });
      }
    }

    filter.limit.engagements = [];
    for (var includedEngagement of this.state.engagements.selected) {
      var dupEngagement = false;
      for (var dupCheckEngagement of filter.limit.engagements) {
        if(dupCheckEngagement.id === includedEngagement.value) {
          dupEngagement = true;
          break;
        }
      }
      if(!dupEngagement) {
        filter.limit.engagements.push({
          id: includedEngagement.value,
          show: includedEngagement.label,
          poster_id: includedEngagement.poster_id
        });
      }
    }

    filter.limit.mediums = [];
    for (var includedMedium of this.state.mediums.selected) {
      var dupMedium = false;
      for (var dupCheckMedium of filter.limit.mediums) {
        if(dupCheckMedium.id === includedMedium.value) {
          dupMedium = true;
          break;
        }
      }
      if(!dupMedium) {
        filter.limit.mediums.push({
          id: includedMedium.value,
          label: includedMedium.label
        });
      }
    }

    if(this.state.refunds) {
      filter.limit.refunds = true;
    } else {
      filter.limit.refunds = false;
    }

    if(this.state.underwater) {
      filter.limit.underwater = true;
    } else {
      filter.limit.underwater = false;
    }

    if(this.state.chargeback) {
      filter.limit.chargeback = true;
    } else {
      filter.limit.chargeback = false;
    }

    this.props.dispatch(updateFilter(filter));
    
    this.props.changeFilterTab(1);
    this.props.toggleFilter();
  }

  getActiveShows() {

    var opts = {
      path: '/products?broadway=1&active=1',
      type: 'simple'
    };

    //console.log(opts);

    var url = surl(opts);
    console.log(url);

    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {

          var items = [];
          var selectedItems = [];

          for(var product of result.results.products) {
            for(var limited of this.props.filter.limit.engagements) {
              if(product.id === limited.id) {
                var e = {
                  value: product.id, 
                  label: product.name,
                  poster_id: product.poster_id
                };
                selectedItems.push(e);
                break;
              }
            }
            var e2 = {
              value: product.id, 
              label: product.name,
              poster_id: product.poster_id
            };
            items.push(e2);
          }

          this.setState({
            ...this.state,
            engagements: {
              ...this.state.engagements,
              isLoaded: true,
              items: items,
              selected: selectedItems
            }
          });

        },
        (error) => {
          this.setState({
            ...this.state,
            engagements: {
              ...this.state.engagements,
              isLoaded: true,
              error: error
            }
        });
      }
    );
  }
  
  render() {
    return (

      <div>

        <p className="gutter-bottom-fixed-1 text-right">
          <Button size="sm" color={this.state.hasChanged ? 'success' : 'default'} className="mr-1" onClick={((e) => this.applyChanges(e))} disabled={!this.state.hasChanged}>Apply Changes</Button>
        </p>

        <hr/>

        <h6><strong>SHOWS</strong></h6>      
        <Select
          value={this.state.engagements.selected}
          onChange={this.handleEngagementsChange}
          options={this.state.engagements.items}
          placeholder='Select shows...'
          isMulti={true}
        /> 

        <h6 className="gutter-top-fixed-2"><strong>VENUE OWNERS</strong></h6>      
        <Select
          value={this.state.owners.selected}
          onChange={this.handleOwnersChange}
          options={this.state.owners.items}
          placeholder='Select owners...'
          isMulti={true}
        /> 

        <h6 className="gutter-top-fixed-2"><strong>SOURCES</strong></h6>      
        <Select
          value={this.state.sources.selected}
          onChange={this.handleSourcesChange}
          options={this.state.sources.items}
          placeholder='Select sources...'
          isMulti={true}
        /> 

        <h6 className="gutter-top-fixed-2"><strong>DEVICE TYPE</strong></h6>      
        <Select
          value={this.state.devices.selected}
          onChange={this.handleDevicesChange}
          options={this.state.devices.items}
          placeholder='Select devices...'
          isMulti={true}
        />

        <h6 className="gutter-top-fixed-2"><strong>MEDIUMS</strong></h6>      
        <Select
          value={this.state.mediums.selected}
          onChange={this.handleMediumsChange}
          options={this.state.mediums.items}
          placeholder='Select mediums...'
          isMulti={true}
        />

        <hr/>

        <div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id="limit_refunds" onClick={((e) => this.handleRefundChange(e))} checked={this.state.refunds} onChange={e => {}} />
          <label className="form-check-label" htmlFor="limit_refunds">
            <strong>REFUNDS ONLY</strong>
          </label>
        </div>
        <p className="small">Shows only refunded orders. Note: to exclude refunds from results, use Exclude tab above.</p>
        
        <hr/>

        <div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="limit_chargeback" onClick={((e) => this.handleChargebackChange(e))} checked={this.state.chargeback} onChange={e => {}} />
            <label className="form-check-label" htmlFor="limit_chargeback">
              <strong>CHARGEBACKS ONLY</strong>
            </label>
          </div>
          <p className="small">Includes only transactions that resulted in chargeback (excludes refunds).</p>
        </div>
        
        <hr/>

        <div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="limit_underwater" onClick={((e) => this.handleUnderwaterChange(e))} checked={this.state.underwater} onChange={e => {}} />
            <label className="form-check-label" htmlFor="limit_underwater">
              <strong>UNDERWATER ONLY</strong>
            </label>
          </div>
          <p className="small">Includes only transactions where access fees are greater than service fees (excludes refunds).</p>
        </div>

    </div>

        

    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(FilterLimit);
