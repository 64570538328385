import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { JsonEditor } from 'json-edit-react'

import { DateTime } from 'luxon';

import { Row, Col, Button } from 'reactstrap';

import { surl } from '../../../../core/utils.js';

import isScreen from '../../../../core/screenHelper';

import Cookies from 'js-cookie';

class WidgetUserCard extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    trends: PropTypes.bool
  };

  static defaultProps = {
    print: false,
    width: 880,
    height: 150,
    chartAspectWidth: 2,
    chartAspectHeight: 1,
    trends: false
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    
    var show_count = 32;
    var show_cols = [6,6,6,6,6];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        show_count = 20
        show_cols = [12,12,12,12,12];
      } else {
        if(isScreen('md')) {
          show_count = 6
          show_cols = [4,4,6,4,4];
        }
      }
    }

    this.state = {
      show_count: show_count,
      show_cols: show_cols,
      print: this.props.print,
      expand: false,
      isUpdated: false,
      user: this.props.user
    };

    this._isMounted = false;
    this.setData = this.setData.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggle = () => { 

    const section = document.querySelector('#block_' + this.props.user._id);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    this.setState({
      expand: !this.state.expand
    });
  }

  toggleDetail = (id) => { 

    const section = document.querySelector('#block_' + this.props.user._id);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    this.setState({
      expand: !this.state.expand
    });
  }

  setData = (e) => { 
    this.setState({
      user: e.newData,
      isUpdated: true
    }, function() {
      this.save();
    });
  }

  save = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var valid = true;

      var url = '/v2/admin/users/update';

      if(valid) {

        let postUrl = surl({
          path: url,
          type: 'simple'
        });

        fetch(postUrl,  {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',},
          body: JSON.stringify(this.state.user),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(updatedUser => {
          console.log('Updated User:', updatedUser);
          this.setState({
            isUpdated: false
          });
        })
        .catch(error => {
          console.error('Error:', error);
        });
      } else {
        console.log('Not valid');
        console.log(url);
      }
    });
  }

  duplicate = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var valid = true;

      var url = '/v2/admin/users/duplicate';

      if(valid) {

        let postUrl = surl({
          path: url,
          type: 'simple'
        });

        fetch(postUrl,  {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',},
          body: JSON.stringify(this.state.user),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(response => {
          window.location.reload();
        })
        .catch(error => {
          console.error('Error:', error);
        });
      } else {
        console.log('Not valid');
        console.log(url);
      }
    });
  }

  impersonate = () => { 
    let token = this.props.user.tokens[this.props.user.tokens.length-1];
    Cookies.set('sh_token', token.access_token, { expires: 7, path: '' });
    this.props.history.push('/');
    //console.log(token);
  }

  render() {

    return (

      <div className="w-100">

        <Row className={this.state.expand ? 'mt-3 bg-dark text-white' : 'mt-3'} id={'block_' + this.props.user._id}>
          <Col xs={12} sm={6} md={6} lg={4} xl={3} className="gutter-top-fixed-2">
            <Row className="m-0 p-0">
              <Col xs={3} sm={3} md={3} lg={2} xl={2} className="d-print-none">
                <h6>
                  <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => this.toggle()}>
                    <i className={'fa-light ' + (this.state.expand ? 'fa-minus' : 'fa-plus')} />
                  </Button>
                </h6>
              </Col>
              <Col xs={9} sm={9} md={9} lg={10} xl={10}>
                <h5 className={'text-capitalize ' + (this.props.print ? '' : null) + (this.state.expand ? ' fw-bold' : null)}>
                  {this.props.user.first.toUpperCase()} {this.props.user.last.toUpperCase()}
                </h5>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={2} md={2} lg={2} xl={3} className="gutter-top-fixed-2 text-center">
            {this.props.user.tokens && (this.props.user.tokens.length > 0) ? (
                <span>
                  {DateTime.fromISO(this.props.user.tokens[this.props.user.tokens.length-1].last_accessed).toISODate()} ({this.props.user.tokens.length})
                </span>
            ) : null}
          </Col>
          <Col xs={12} sm={2} md={2} lg={2} xl={3} className="gutter-top-fixed-2 text-center">
            {this.props.user.sources ? (
              <span>{this.props.user.sources[0]}</span>
            ) : null}
          </Col>
          <Col xs={12} sm={2} md={2} lg={2} xl={3} className="gutter-top-fixed-2 text-center">
            {this.props.user.email ? (
              <span className="mr-3"><i className="fa-brands fa-microsoft fa-lg"></i></span>
            ) : null}
            {this.props.user.gmail ? (
              <span><i className="fa-brands fa-google fa-lg"></i></span>
            ) : null}
          </Col>
        </Row>

        {this.state.expand ? (
          <div className="gutter-top-fixed-2">

            <Row>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-left">
                {/* <Button className={this.state.isUpdated ? 'btn-success' : 'btn-light'} onClick={() => this.save()} disabled={!this.state.isUpdated}>
                  <i className="fa-light fa-save" /> &nbsp;Update User
                </Button> */}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-right">
                <Button className="btn-light" onClick={() => this.duplicate()}>
                  <i className="fa-light fa-copy" />
                </Button>
                <Button className="btn-warning gutter-left-fixed-2" onClick={() => this.impersonate()}>
                  <i className="fa-light fa-user" />
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                <JsonEditor
                  data={ this.state.user }
                  onUpdate={(e) => this.setData(e)}
                  className="w-100 gutter-top-fixed-2"
                  theme={[
                    'default'
                  ]}
                  collapse={true}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                <div className="text-right mt-4">

                </div>
              </Col>
            </Row>
          </div>
        ) : null}

        <hr/>

      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetUserCard));
