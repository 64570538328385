export const SET_FILTER = 'SET_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';

export function setFilter() {
  return {
    type: SET_FILTER
  };
}

export function updateFilter(filter) {
  return {
    type: UPDATE_FILTER,
    filter: filter
  };
}