import React, { PureComponent }  from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Row,
  Col
} from 'reactstrap';

import { ResponsiveContainer, Line, LineChart, Label, XAxis, YAxis, ReferenceLine, Tooltip, Legend} from 'recharts';

import Widget from '../../../components/Widget';
import Displays from '../../../components/Displays';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated, defaultTimespan } from '../../../core/utils';
import isScreen from '../../../core/screenHelper';

class ChartMagic extends PureComponent {
  render() {
    const { chart, print, aspect } = this.props;

    if(print) {
      return (
        <div>{chart}</div>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" aspect={aspect}>
          {chart}
        </ResponsiveContainer>
      );
    }
  }
}
class WidgetBwConversions extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    timespan: PropTypes.string,
    chart_metric: PropTypes.string,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    fixed_years: PropTypes.number,
    experiments: PropTypes.bool,
  };

  static defaultProps = {
    print: false,
    timespan: 'day',
    chart_metric: 'overall_conv_ratio',
    chartAspectWidth: 4,
    chartAspectHeight: 1,
    chart: true,
    width: 1050,
    height: 350,
    fixed_years: 5,
    experiments: false
  };

  constructor(props) {
    super(props);

    var dorw = defaultTimespan(this.props.filter);
    if(this.props.experiments) {
      dorw = 'week';
    }

    this.state = {
      isLoaded: false,
      isError: false,
      chart_metric: this.props.chart_metric,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      chartAspectWidth: this.props.chartAspectWidth,
      chartAspectHeight: this.props.chartAspectHeight,
      print: this.props.print
    }

    this._isMounted = false;
    this.loadFunnels = this.loadFunnels.bind(this);
    this.setChartData = this.setChartData.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.areaLabelFormat = this.areaLabelFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadFunnels();

    this.setChartAspect();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'CONVERSIONS WIDGET')) {
      this.loadFunnels();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadFunnels() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/mixpanel/funnels',
        type: 'universal',
        timespans: 1,
        years: this.props.fixed_years
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      //opts.experiment_values = [0,1];

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results.totals.dates[0]) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                this.setChartData();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  setChartData() {
    var chartData = [];

    if(this.state.results && this.state.results.timespans && this.state.results.timespans.dates && this.state.results.timespans.dates.length > 0) {

      this.state.results.timespans.dates[0].data[this.state.chart_timespan].forEach(function(block, i) {
        
        var d = {
          name: block.date,
          year1: block.analysis[this.state.chart_metric]*100
        };

        if(!this.props.product_id) {
          if(!this.props.experiments && this.state.results.timespans.dates[1] && this.state.results.timespans.dates[1].data[this.state.chart_timespan][i].analysis[this.state.chart_metric]) {
            d.year2 = this.state.results.timespans.dates[1].data[this.state.chart_timespan][i].analysis[this.state.chart_metric]*100;
          }
        }

        if(this.state.results.timespans.dates[3] && this.state.results.timespans.dates[3].data[this.state.chart_timespan][i].analysis[this.state.chart_metric]) {
          d.year3 = this.state.results.timespans.dates[3].data[this.state.chart_timespan][i].analysis[this.state.chart_metric]*100;
        }

        if(this.state.results.timespans.dates[4] && this.state.results.timespans.dates[4].data[this.state.chart_timespan][i].analysis[this.state.chart_metric]) {
          d.year4 = this.state.results.timespans.dates[4].data[this.state.chart_timespan][i].analysis[this.state.chart_metric]*100;
        }

        if(this.props.experiments) {
          var exp = null;
          switch(block.date) {
            case '2022-06-26':
              exp = 9.0;
              break;
            case '2022-07-03':
              exp = 9.0;
              break;
            case '2022-07-10':
              exp = 8.9;
              break;
            case '2022-07-17':
              exp = 8.7;
              break;
            case '2022-07-24':
              exp = 8.8;
              break;
            case '2022-07-31':
              exp = 9.4;
              break;
            case '2022-08-07':
              exp = 9.1;
              break;
            case '2022-08-14':
              exp = 9.0;
              break;
            default:
                break;
          }
          if(exp) {
            d.exp = exp;
          }
        }

        chartData.push(d);
       
      }.bind(this));

      var reflines = [];

      var min = 0.9;
      var max = 1.1;

      this.setState({
        chart: {
          data: chartData,
          reflines: reflines,
          properties: {
            max: max,
            min: min
          }
        }
      });
    }
  }

  salesSummaryDataFormat(x) {
    if(this.state.metric === 'overall_conv_ratio') {
      return parseFloat(x).toFixed(1) + '%';
    } else {
      return parseFloat(x).toFixed(1) + '%';
    }
  };

  salesSummaryDateFormat(x) {
    if(this.state.chart_timespan === 'day') {
      return moment(x).format('M/DD');
    } else {
      if(this.state.chart_timespan === 'week') {
        return moment(x).format('M/DD');
      } else {
        return moment(x).format('MMM');
      }
    }
  };

  areaLabelFormat(x) {
    return x
  };

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'week') {
      change_display = 'day';
    } else {
      change_display = 'week';
    }
    this.setState({
      chart_timespan: change_display
    }, function() {
      this.setChartData();
    });
  }

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: this.props.chartAspectWidth,
        chartAspectHeight: this.props.chartAspectHeight
      });
    }
  }

  render() {

    var title = <h5><span className="text-danger">Checkout Conversions</span> <small className="text-muted">Small numbers underneath metrics represent figures for same time period 3 years ago. Chart displays conversion percentage by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'day' ? ('day') : ('week')}</button>.</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">

              <Row>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                  <p className="fs-mini text-muted text-center">Starts</p>
                  <h4 className="text-center"><Displays a={parseInt(this.state.results.totals.dates[0].starting_amount)} format="comma" /></h4>
                  {this.state.results.totals.dates[3] ? (
                    <p className="fs-mini text-muted text-center"><Displays a={parseInt(this.state.results.totals.dates[3].starting_amount)} format="comma" /></p>
                  ) : null}
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                  <p className="fs-mini text-muted text-center">Conversions</p>
                  <h4 className="text-center"><Displays a={parseInt(this.state.results.totals.dates[0].completion)} format="comma" /></h4>
                  {this.state.results.totals.dates[3] ? (
                    <p className="fs-mini text-muted text-center"><Displays a={parseInt(this.state.results.totals.dates[3].completion)} format="comma" /></p>
                  ) : null}
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                  <p className="fs-mini text-muted text-center">Conv %</p>
                  <h4 className="text-center">{parseFloat(this.state.results.totals.dates[0].overall_conv_ratio*100).toFixed(2)}%</h4>
                  {this.state.results.totals.dates[3] ? (
                    <p className="fs-mini text-muted text-center">{parseFloat(this.state.results.totals.dates[3].overall_conv_ratio*100).toFixed(2)}%</p>
                  ) : null}
                </Col>
              </Row>

              {this.state.isLoaded && this.state.chart && this.state.chart.data ? (
                <div className="gutter-top-fixed-3">

                  {this.state.isLoaded && this.state.chart && this.state.chart.data ? (
                    <div className="gutter-top-fixed-3">
                        <ChartMagic
                          chart ={
                            <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                              <defs>
                                <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                                </linearGradient>
                                <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                                </linearGradient>
                              </defs>
                              <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                              <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max) ]} />
                              {this.state.chart.reflines.length > 0 ? (
                                this.state.chart.reflines.map((refline, i) =>
                                  <ReferenceLine key={i} x={refline.x} isFront={true} stroke="lightgray">
                                    <Label value={refline.label} offset={refline.offset} position="top" fill="black" style={{fontSize: '0.8rem' }} />
                                  </ReferenceLine>
                                )
                              ) : null }

                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year1 ? (
                                <Line name="Current Year" type="monotone" dataKey="year1" stroke="#82ca9d" dot={true} />
                              ) : null }
                              {this.state.chart.data && this.props.experiments ? (
                                <Line name="Experiments" type="monotone" dataKey="exp" stroke="#000" dot={true} />
                              ) : null }
                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year2 ? (
                                <Line name="1YR Ago" type="monotone" dataKey="year2" stroke="#ccc" dot={true} />
                              ) : null }
                              {this.state.chart.data && this.state.chart.data[0] && this.state.chart.data[0].year3 ? (
                                <Line name="3YRS Ago" type="monotone" dataKey="year3" stroke="#0087a9" dot={true} />
                              ) : null }

                              <Legend verticalAlign="top" height={36} align="right" iconType="square" />

                              <Tooltip />
                            </LineChart>
                          }
                           print={this.state.print}
                           aspect={this.state.chartAspectWidth/this.state.chartAspectHeight}
                        />
                    </div>
                  ): null}
                </div>
              ): null}

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwConversions));
