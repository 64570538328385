import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import Displays from '../../../../components/Displays';

import { nFormatter } from '../../../../core/utils';
import Poster from '../../../../components/Poster';

class ProductDashboardCard extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    print: false,
    width: 300,
    height: 100,
  };

  constructor(props) {
    super(props);

    this.state = {
      chart: {
        data: []
      }
    }

    this.setChart = this.setChart.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {
    if(this.props.product.trend) {
      this.setChart();
    }
  }

  setChart() {

    var chartData = [];

    this.props.product.trend.forEach(function(block, i) {
      
      var d = {
        week: block.dates.end,
        sales: block.sales.fees
      };

      if(block.sales.fees > 0) {
        chartData.push(d);
      }

    });
    
    var aboveMax = 1.1;
    this.setState({
      chart: {
        data: chartData,
        properties: {
          aboveMax: aboveMax
        }
      }
    });
  }

  salesSummaryDataFormat(x) {
    return '$' + nFormatter(x);
  };

  salesSummaryDateFormat(x) {
    return moment(x).format('M/DD');
  };

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        <div className="card gutter-left-fixed-1">
          <div className="card-body nopad nomargin">

            {this.props.product.product ? (
              <div>
       
                  <div className="text-center">
                    <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))}>
                      <Poster id={this.props.product.product.poster_id} responsive={true} className="border img-poster img-fluid" />
                    </a>
                  </div>
                  <div className="text-center gutter-top-fixed-2">
                      {(this.props.sales_metric === 'orders' &&
                        <div>
                          <p className="text-center gutter-top-fixed-2"><Displays a={parseInt(this.props.product.orders)} format="comma" /></p>
                          <p className="fs-mini text-muted text-center">--</p>
                        </div>)
                      || (this.props.sales_metric === 'qty' &&
                      <div>
                        <p className="text-center gutter-top-fixed-2"><Displays a={parseInt(this.props.product.qty)} format="comma" /></p>
                        <p className="fs-mini text-muted text-center">--</p>
                      </div>)
                      ||
                        <div>
                          <p className="text-center"><strong>$<Displays a={parseInt(this.props.product[this.props.sales_metric])} format="comma" /></strong></p>
                          <p className="fs-mini text-muted text-center">${parseFloat(this.props.product[this.props.sales_metric]/this.props.product.qty).toFixed(2) || 0}</p>
                        </div>
                      }
                  </div>
       
              </div>
            ) : null}

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(ProductDashboardCard));
