import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Displays from '../../../../components/Displays';

class WidgetLogUser extends React.Component {

  static propTypes = {
    user: PropTypes.object
  };

  render() {

    return (

      <div>

        <div className="card gutter-bottom-fixed-4">
          <div className="card-body nopad nomargin">

            {this.props.user ? (
              <div className="text-center">
                
                <h6 className="gutter-top-fixed-1 gutter-bottom-fixed-2">
                  {this.props.user.first && this.props.user.last ? (
                    <span>{this.props.user.first} {this.props.user.last}</span>
                  ) : (
                    <span>Bad Record</span>
                  )}
                </h6>

                <div>
                  <h4 className="text-center"><Displays a={parseInt(this.props.user.records)} format="comma" /></h4>
                  {this.props.user.host ? (
                    <p className="fs-mini text-muted text-center">{this.props.user.host}</p>
                  ) : (
                    <p className="fs-mini text-muted text-center">--</p>  
                  )}
                </div>

              </div>
            ) : null}

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetLogUser));
