import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { scurl, compare, checkExists } from '../../../../core/utils.js';

import {
  Row,
  Col,
  Input,
  InputGroup
} from 'reactstrap';

class WidgetAdminVenue extends React.Component {

  static propTypes = {
    venue: PropTypes.object
  };

  static defaultProps = {
    venue: null
  };

  constructor(props) {
    super(props);

    var venue = this.props.venue;
    if(!checkExists(venue.owner)) {
      venue.owner = '';
    }

    this.state = {
      isLoaded: true,
      venue: venue,
      edit: false,
      dirty: false
    };

    this._isMounted = false;
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  save = () => { 
    this.setState({
      isLoaded: false
    }, function() {

      if(!compare(this.state.venue, this.props.venue)) {
        var valid = true;

        var url = '/products/venues/' + this.state.venue.id  + '/update?';
        url += 'id=' + this.state.venue.id;
        url += '&owner=' + this.state.venue.owner;
        url += '&broadway=' + this.state.venue.broadway;

        if(valid) {

          var opts = {
            path: url,
            type: 'simple'
          };

          scurl(opts, function(err, results) {
            if(err) {
              this.setState({
                error: {
                  json: err,
                  status: err.status || -99,
                  message: err.message || 'Error occured'
                }
              });
            } else {
              this.setState({
                edit: false
              });
            }
          }.bind(this));
        } else {
          console.log('Not valid');
          console.log(url);
        }
      } else {
        console.log('Objects are the same');
      }
    });
  }

  edit = () => { 
    this.setState({
      edit: true,
      dirty: false
    });
  }

  handleUpdate = (e) => { 
    this.setState({
      venue: { 
        ...this.state.venue, 
       [e.target.id]: e.target.value
      }
    }, function() {
      this.setState({
        dirty: !compare(this.state.venue, this.props.venue)
      });
    });
  }

  handleClick = (e) => { 
    this.setState({
      venue: { 
        ...this.state.venue, 
       [e.target.id]: e.target.checked
      }
    }, function() {
      this.setState({
        dirty: !compare(this.state.venue, this.props.venue)
      });
    });
  }

  render() {

    return (


      <div className="card gutter-bottom-fixed-4">
        <div className="card-body">

          {this.props.venue ? (

            <Row>
              <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                <p className="font-weight-bold pb-0 mb-0">{this.props.venue.name}</p>
                {this.props.venue.owner ? (
                  <p className="pt-0 mt-0 fs-mini">
                    <span>{this.props.venue.owner}</span>
                    {this.props.venue.broadway ? (
                      <span className="pl-2"><i className="fa fa-road"></i></span>
                    ) : null}
                  </p>
                ) : null}

                {this.state.edit ? (
                  <Row>
                    <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                      <p className="fs-mini mb-0 pb-0">Owner</p>
                      <InputGroup className="fs-mini mt-0 pt-0">
                        <Input id="owner" bsSize="16" type="text" value={this.state.venue.owner} onChange={(e) => this.handleUpdate(e)} />
                      </InputGroup>
                    </Col>
                    <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                      <p className="fs-mini mb-0 pb-0">&nbsp;</p>
                      <InputGroup className="fs-mini mt-0 pt-0">
                        <Input id="broadway" type="checkbox" value="" onClick={(e) => this.handleClick(e)} checked={this.state.venue.broadway} onChange={e => {}}/> Broadway
                      </InputGroup>
                    </Col>
                  </Row>
                ) : null}

              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3} className="text-right">
                {this.state.edit ? (
                  <button className={this.state.dirty ? 'btn-success fs-mini' : 'btn-default fs-mini'} onClick={() => this.save()} disabled={!this.state.dirty}>SAVE</button>
                ) : (
                  <button className="header-link fs-mini mr-2" onClick={() => this.edit()}>EDIT</button>
                )}
              </Col>
            </Row>

          ) : null}

        </div>
      </div>

      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdminVenue));
