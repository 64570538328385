import { DateTime } from 'luxon';

export const loadState = (local) => {
    try {

      local = local || false;

      const serializedState = localStorage.getItem('sh_state');
  
      if (serializedState === null) {
        return undefined;
      } else {
        var parsed = JSON.parse(serializedState);
        if(local) {
          return parsed;
        } else {
          if(!parsed.filter || !parsed.filter.updated) {
              return undefined;
          } else {
          //return parsed;

            if(DateTime.fromFormat(parsed.filter.updated, 'yyyy-LL-dd HH:mm:ss') < DateTime.now({zone: 'America/New_York'}).minus({day: 3})) {
              console.log('LS EXPIRED');
              return undefined;
            } else {
              //console.log(DateTime.fromFormat(parsed.filter.updated, 'yyyy-LL-dd HH:mm:ss').toISODate() + ' < ' + DateTime.now({zone: 'America/New_York'}).minus({day: 3}).toISODate());
              return parsed;
            }
          }
        }
      }
    } catch (err) {
      return undefined;
    }
  };
  
  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('sh_state', serializedState);
    } catch (err) {
      // die
    }
  };