import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Table, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import Displays from '../../../components/Displays';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';
import classnames from 'classnames';

class WidgetFunnelProperty extends React.Component {

  
  static propTypes = {
    print: PropTypes.bool,
    fixed_years: PropTypes.number,
    property: PropTypes.string,
    display_length: PropTypes.number,
    lookback: PropTypes.number
  };

  static defaultProps = {
    print: false,
    fixed_years: 2,
    display_length: 5,
    lookback: 30
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      fixed_years: this.props.fixed_years,
      display_length: this.props.display_length,
      print: this.props.print,
      activeTab: '1'
    }

    this._isMounted = false;
    this.loadFunnel = this.loadFunnel.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadFunnel();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FUNNEL GROUP WIDGET')) {
      this.loadFunnel();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadFunnel() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/mixpanel/funnels/property?mixpanel_property=' + this.props.property,
        type: 'universal',
        years: this.props.fixed_years,
        lookback: this.props.lookback
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  viewAll = () => { 
    this.setState({
      display_length: this.state.results.consolidated.length
    });
  }

  render() {

    var title = <h5><span className="text-danger">Conversions by {this.props.property}</span> <small className="text-muted">Begins when a customer starts a new session on the part-of-house page.</small></h5>;

    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div>

              <Nav className="gutter-top-fixed-2" tabs>
                <NavItem>
                  <NavLink
                    className={'text-white ' + classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}>
                    Current Year
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={'text-white ' + classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}>
                    Prior Year
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Table>
                    <thead>
                      <tr>
                        <th width="30%" className="text-left">Name</th>
                        <th width="25%" className="text-center">Session Starts</th>
                        <th width="25%" className="text-center">Conversion Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.results.consolidated.map((result, i) =>
                        i < this.state.display_length ? (
                          <tr key={i}>
                            <td className="pb-2 mb-0 text-left">
                              <p className="pb-0 mb-0">{result.name}</p>
                            </td>
                            <td className="pb-2 mb-0 text-center">
                              <p className="pb-1 mb-0"><Displays a={result.years[0].starts.count} format="comma" fixed={0} /></p>
                              {result.years[1] ? (
                                <p className="pb-0 mb-0"><Displays a={result.years[0].starts.count} b={result.years[1].starts.count} format="percent"/></p>
                              ) : (
                                <p className="pb-0 mb-0">--</p>
                              )}
                            </td>
                            <td className="pb-2 mb-0 text-center">
                              <p className="pb-1 mb-0">{parseFloat(result.years[0].starts.conversion_percentage*100).toFixed(1)}%</p>
                              {result.years[1] ? (
                                <p className="pb-0 mb-0"><Displays a={result.years[0].starts.conversion_percentage} b={result.years[1].starts.conversion_percentage} format="percent"/></p>
                              ) : (
                                <p className="pb-0 mb-0">--</p>
                              )}
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </Table>

                  {(this.state.display_length < this.state.results.consolidated.length) && !this.props.print ? (
                    <div className="text-center d-print-none">
                      <Button className="btn-success" onClick={() => this.viewAll()}><i className="fa-light fa-arrows-up-down fa-lg gutter-right-fixed-1" />View Additional {parseInt(this.state.results.consolidated.length-this.state.display_length)}</Button>
                    </div>
                  ) : null}
                </TabPane>
                <TabPane tabId="2">
                  <Table>
                    <thead>
                      <tr>
                        <th width="30%" className="text-left">Name</th>
                        <th width="25%" className="text-center">Session Starts</th>
                        <th width="25%" className="text-center">Conversion Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.results.years[1].results.map((result, i) =>
                        <tr key={i}>
                          <td className="pb-2 mb-0 text-left">
                            <p className="pb-0 mb-0">{result.name}</p>
                          </td>
                          <td className="pb-2 mb-0 text-center">
                            <p className="pb-1 mb-0"><Displays a={parseInt(result.funnel.starts.count)} format="comma" fixed={0} /></p>
                          </td>
                          <td className="pb-2 mb-0 text-center">
                            <p className="pb-1 mb-0">{parseFloat(result.funnel.starts.conversion_percentage*100).toFixed(1)}%</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>           
                    
            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetFunnelProperty));
