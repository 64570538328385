import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl, surl } from '../../../core/utils.js';
import { Row, Col, Button } from 'reactstrap';

class WidgetOpsisScaffold extends React.Component {

  static propTypes = {
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    var download = surl({
      path: '/v2/opsis/export',
      type: 'universal'
    });

    var download_annual = surl({
      path: '/v2/excel/opsis?annual_format=1',
      type: 'universal'
    });

    this.state = {
      isLoaded: false,
      isError: false,
      download: download,
      download_annual: download_annual
    }

    this._isMounted = false;
    this.loadSettings = this.loadSettings.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSettings();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSettings() {
    
    this.setState({
      isLoaded: false,
      results: null
    }, function() {

      var opts = {
        path: '/v2/opsis/settings',
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              settings: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  fire = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var url = '/v2/opsis/fire/annual';

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            fire: results,
          });
        }
      }.bind(this));

    });
  }

  scaffold = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var url = '/v2/opsis/fire/scaffold';

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            scaffold: results,
          });
        }
      }.bind(this));

    });
  }

  download = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var url = '/opsis/fire/scaffold';

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            scaffold: results,
          });
        }
      }.bind(this));

    });
  }

  render() {

    var title = <h5><span className="text-danger">Opsis Settings</span> <small className="text-muted">For scaffolding</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">

              <Row>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                  <span className="fw-bold">Forecast Name</span>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                  <span className="fw-bold">As Of Date</span>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                  <span className="fw-bold">Compare To</span>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                  <span className="fw-bold">Is Locked</span>
                </Col>
              </Row>

              {this.state.settings.forecast_dates.map((forecast_date, i) =>
                <Row key={i}>
                  <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                    {forecast_date.name}
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                    {forecast_date.date}
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                    {forecast_date.include ? (
                      <span>Include</span>
                    ) : (
                      <span>Exclude</span>
                    )}
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                    {forecast_date.locked ? (
                      <span>Locked</span>
                    ) : (
                      <span>Unlocked</span>
                    )}
                  </Col>
                </Row>
              )}


              {!this.state.settings.forecast_dates[this.state.settings.forecast_dates.length-1].locked ? (
                <div>
                  <hr/>
                  <Row className="gutter-top-fixed-2">
                    <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                      <a href={this.state.download_annual} target="_blank" rel="noopener noreferrer">
                        <Button className="btn-warning text-white">
                          <i className="fa-light fa-download" /> Download
                        </Button>
                      </a>
                    </Col>
                    <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                      <Button className="btn-success text-white" onClick={() => this.fire()}>
                        <i className="fa-light fa-bolt" /> Fire
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : null}



            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisScaffold));
