import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { surl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

class WidgetAffiliateExport extends React.Component {

  static propTypes = {
    perf_date: PropTypes.bool,
    className: PropTypes.string,
    refunds: PropTypes.number,
    affiliate: PropTypes.bool,
    product_id: PropTypes.number
  };

  static defaultProps = {
    perf_date: false,
    className: '',
    refunds: -1,
    affiliate: true
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      perf_date: this.props.perf_date,
      refunds: this.props.refunds
    }

    this._isMounted = false;
    this.loadUrl = this.loadUrl.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUrl();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'EXPORT WIDGET')) {
      this.loadUrl();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadUrl() {

    var opts = {
      path: '/v2/excel/transactions',
      type: 'universal'
    };

    opts.perf_date = this.state.perf_date;

    if(this.props.product_id) {
      opts.product_id = this.props.product_id;
    }

    var url = surl(opts);  
    if(this._isMounted) {
      this.setState({
        url: url,
        isLoaded: true
      });
    } else {
      console.log('handled unmount');
    }
  }

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.loadUrl();
    });
  }

  render() {

    var title = <h5><span className="text-danger">Transaction Export</span>&nbsp;&nbsp;<small>Export transactions by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>.</small></h5>;

    return (

      <div>

        <Widget title={title} className={this.props.className}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div className="gutter-top-fixed-10 gutter-bottom-fixed-10">
              {this.state.url ? (
                <h2 className="text-center">
                  <a href={this.state.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-download"></i>&nbsp;&nbsp;Download to Excel</a>
                </h2>
              ) : null}
            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAffiliateExport));
