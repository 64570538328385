import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { Button } from 'reactstrap';

import { surl } from '../../../core/utils.js';

import { updateFilter } from '../../../actions/filter';

class FilterExclude extends React.Component {
  
  static propTypes = {
    changeFilterTab: PropTypes.func.isRequired,
    toggleFilter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    var devices = [
      { value: 'desktop', label: 'Desktop' },
      { value: 'mobile', label: 'Mobile' },
      { value: 'tablet', label: 'Tablet' },
    ];

    var sources = [
      { value: 'BROADWAY.COM', label: 'broadway.com' },
      { value: 'NYC.COM', label: 'NYC.com' },
      { value: 'BROADWAYBOX', label: 'BroadwayBox' },
      { value: 'TIME OUT AMERICA, LLC', label: 'Timeout NY' }
    ];

    var refunds = false;
    if(this.props.filter.exclude.refunds) {
      refunds = this.props.filter.exclude.refunds;
    }

    this.state = {
      hasChanged: false,
      engagements: {
        items: [],
        selected: [],
        isLoaded: false
      },
      sources: {
        items: sources,
        selected: this.props.filter.exclude.sources
      },
      devices: {
        items: devices,
        selected: this.props.filter.exclude.devices
      },
      refunds: refunds
    };


    this.handleEngagementsChange = this.handleEngagementsChange.bind(this);
    this.handleSourcesChange = this.handleSourcesChange.bind(this);
    this.handleDevicesChange = this.handleDevicesChange.bind(this);
    this.handleRefundChange = this.handleRefundChange.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.getActiveShows = this.getActiveShows.bind(this);
  }

  componentDidMount() {
    this.getActiveShows();
  }

  handleEngagementsChange = selectedOptions => {
    var hasChanged = true;

    console.log(selectedOptions);

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      engagements: {
        ...this.state.engagements,
        selected: selectedOptions
      }
    });
  };

  handleSourcesChange = selectedOptions => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      sources: {
        ...this.state.sources,
        selected: selectedOptions
      }
    });
  };

  handleDevicesChange = selectedOptions => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      devices: {
        ...this.state.devices,
        selected: selectedOptions
      }
    });
  };

  handleRefundChange = e => {
    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      refunds: e.target.checked
    });
  };

  applyChanges() {

    var filter = JSON.parse(JSON.stringify(this.props.filter));

    filter.exclude.engagements = [];
    for (var includedEngagement of this.state.engagements.selected) {
      var dupEngagement = false;
      if(filter.exclude.engagements) {
        for (var dupCheckEngagement of filter.exclude.engagements) {
          if(dupCheckEngagement.slug === includedEngagement.value) {
            dupEngagement = true;
            break;
          }
        }
      }
      if(!dupEngagement) {
        filter.exclude.engagements.push({
          id: includedEngagement.value,
          show: includedEngagement.label,
          poster_id: includedEngagement.poster_id
        });
      }
    }

    if(this.state.refunds) {
      filter.exclude.refunds = true;
    } else {
      filter.exclude.refunds = false;
    }

    this.props.dispatch(updateFilter(filter));
    
    this.props.changeFilterTab(1);
    this.props.toggleFilter();
  }

  getActiveShows() {

    var opts = {
      path: '/products',
      type: 'simple'
    };

    //console.log(opts);

    var url = surl(opts);
    console.log(url);

    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {

          var items = [];
          var selectedItems = [];

          for(var engagement of result.results.products) {
            for(var excluded of this.props.filter.exclude.engagements) {
              if(engagement.slug === excluded.slug) {
                var e = {
                  value: engagement.id,
                  label: engagement.name,
                  poster_id: engagement.poster_id
                };
                selectedItems.push(e);
                break;
              }
            }
            var e2 = {
              value: engagement.id,
              label: engagement.name,
              poster_id: engagement.poster_id
            };
            items.push(e2);
          }

          this.setState({
            ...this.state,
            engagements: {
              ...this.state.engagements,
              isLoaded: true,
              items: items,
              selected: selectedItems
            }
          });

        },
        (error) => {
          this.setState({
            ...this.state,
            engagements: {
              ...this.state.engagements,
              isLoaded: true,
              error: error
            }
        });
      }
    );
  }
  
  render() {
    return (

      <div>

        <p className="gutter-bottom-fixed-1 text-right">
          <Button size="sm" color={this.state.hasChanged ? 'success' : 'default'} className="mr-1" onClick={((e) => this.applyChanges(e))} disabled={!this.state.hasChanged}>Apply Changes</Button>
        </p>

        <hr/>

        <h6><strong>SHOWS</strong></h6>      
        <Select
          value={this.state.engagements.selected}
          onChange={this.handleEngagementsChange}
          options={this.state.engagements.items}
          placeholder='Select shows...'
          isMulti={true}
        /> 

        <hr/>

        <div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id="exclude_refunds" onClick={((e) => this.handleRefundChange(e))} checked={this.state.refunds} onChange={e => {}} />
          <label className="form-check-label" htmlFor="exclude_refunds">
            <strong>EXCLUDE REFUNDS</strong>
          </label>
        </div>
        <p className="small">Includes only new orders, ignoring any refund transactions</p>
        
      </div>


    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(FilterExclude);
