import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { nFormatter, formatNumber } from '../../../../core/utils';
import Poster from '../../../../components/Poster';

class WidgetKnownProductCard extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    print: false,
    width: 300,
    height: 100,
  };

  constructor(props) {
    super(props);

    this.state = {
      chart: {
        data: []
      }
    }

    this.setChart = this.setChart.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {
    if(this.props.product.trend) {
      this.setChart();
    }
  }

  setChart() {

    var chartData = [];

    this.props.product.trend.forEach(function(block, i) {
      
      var d = {
        week: block.dates.end,
        sales: block.sales.fees
      };

      if(block.sales.fees > 0) {
        chartData.push(d);
      }

    });
    
    var aboveMax = 1.1;
    this.setState({
      chart: {
        data: chartData,
        properties: {
          aboveMax: aboveMax
        }
      }
    });
  }

  salesSummaryDataFormat(x) {
    return '$' + nFormatter(x);
  };

  salesSummaryDateFormat(x) {
    return moment(x).format('M/DD');
  };

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        <div className="card gutter-bottom-fixed-4">
          <div className="card-body nopad nomargin">

            {this.props.product.product ? (
              <div>
                <div className="d-flex">
                  <div>
                    <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))}>
                      <Poster id={this.props.product.product.poster_id} w={75} className="border-right img-poster" />
                    </a>
                  </div>
                  <div className="flex-grow text-center">
                      
                      {!this.props.print ? (
                        <h6 className="mb-4">
                          <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))} className="fs-mini text-muted">
                            {this.props.product.product.short_name ? (
                              <span>{this.props.product.product.short_name}</span>
                            ) : (
                              this.props.product.product.name && (this.props.product.product.name.length > 18) ? (
                                <span>{this.props.product.product.name.slice(0,18)}...</span>
                              ) : (
                                <span>{this.props.product.product.name}</span>
                              )
                            )}
                          </a>
                        </h6>
                      ) : null}
                      
                      <div>
                        <h5>{formatNumber('$X.XX', this.props.product.per_order.net, false)}</h5>
                        <p className="m-0 p-0 gutter-top-fixed-1 text-muted">Margin: {formatNumber('X.X%', (1-(this.props.product.per_order.fixed_cost/this.props.product.per_order.fees)), false)}</p>
                      </div>
                  </div>
                </div>
              
              </div>
            ) : null}

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetKnownProductCard));
