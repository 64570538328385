import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Table
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { surl, scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

class WidgetTestDetail extends React.Component {

  static propTypes = {
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      print: this.props.print
    }

    this._isMounted = false;
    this.loadTransactions = this.loadTransactions.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadTransactions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'MIXPANEL TEST WIDGET')) {
      this.loadTransactions();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadTransactions() {

    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/experiments/reconcile',
        type: 'universal',
        transactions: 1
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results.transactions && results.transactions.length > 0) {

              var base_opts = {
                path: '/transactions/transactions',
                type: 'universal'
              };

              var base_transaction_link = surl(base_opts);

              this.setState({
                isLoaded: true,
                results: results,
                base_transaction_link: base_transaction_link
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    })
    
  }

  render() {

    var title = <h5><span className="text-danger">Transaction Reconcilation</span> <small className="text-muted">Returns all transactions that have an experiment property for a given date range.</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">

              <p>Count: {this.state.results.transactions.length}</p>

              <Table className="gutter-top-fixed-2">
                <thead>
                  <tr>
                    <th width="33%">ORDER ID</th>
                    <th width="33%">OPTIMIZELY</th>
                    <th width="33%">MIXPANEL</th>
                  </tr>
                </thead>
                <tbody> 
                    {this.state.results.transactions ? (
                      this.state.results.transactions.map((transaction, i) =>
                        <tr key={i}>
                          <td className="pb-2 mb-0">
                            <p className="text-capitalize fs-mini">
                              <a href={[this.state.base_transaction_link,'&reservation_id=',transaction.reservation_id].join('')} target="_blank" rel="noopener noreferrer">{transaction.reservation_id}-{transaction.order_id}</a>
                            </p>
                            <p className="text-capitalize fs-mini pb-0 mb-0">{transaction.transaction_date}</p>
                          </td>
                          <td>
                            {transaction.experiments ? (
                              transaction.experiments.map((experiment, x) =>
                                typeof(experiment.optimizely_variant) !== 'undefined' ? (
                                  <p key={x}>
                                    <span className="fw-bold">{experiment.optimizely_variant}</span>&nbsp;&nbsp;&nbsp;
                                    <span className={'fs-mini ' + (experiment.optimizely_date !== transaction.transaction_date ? 'text-danger' : 'text-muted')}>{experiment.optimizely_date}</span>&nbsp;&nbsp;&nbsp;
                                    <span className="fs-mini text-muted">{experiment.optimizely_user}</span>
                                  </p>
                                ) : null
                              )
                            ) : null}
                          </td>
                          <td>
                            {transaction.experiments ? (
                              transaction.experiments.map((experiment, x) =>
                                typeof(experiment.mixpanel_variant) !== 'undefined' ? (
                                  <p key={x}>
                                    <span className="fw-bold">{experiment.mixpanel_variant}</span>&nbsp;&nbsp;&nbsp;
                                    <span className={'fs-mini ' + (experiment.mixpanel_date !== transaction.transaction_date ? 'text-danger' : 'text-muted')}>{experiment.mixpanel_date}</span>&nbsp;&nbsp;&nbsp;
                                    <span className="fs-mini text-muted"><em>{experiment.mixpanel_user.replace('Optimizely co__','')}</em></span>
                                  </p>
                                ) : null
                              )
                            ) : null}
                          </td>
                        </tr>
                      )
                    ) : null}
                </tbody>
              </Table>

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetTestDetail));
