import React from 'react';
import PropTypes from 'prop-types';

import { LineChart } from 'recharts';

import isScreen from '../../../core/screenHelper';

class WidgetUtilsSpacer extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    print: true,
    chartAspectWidth: 4,
    chartAspectHeight: 1,
    chart: true,
    width: 1050,
    height: 350,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      chartAspectWidth: this.props.chartAspectWidth,
      chartAspectHeight: this.props.chartAspectHeight,
      print: this.props.print
    }

    this._isMounted = false;
    this.setChartAspect = this.setChartAspect.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setChartAspect();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: this.props.chartAspectWidth,
        chartAspectHeight: this.props.chartAspectHeight
      });
    }
  }

  render() {

    return (

      <div>

        <LineChart width={this.props.width} height={this.props.height} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
        </LineChart>
                    
      </div>
      
    );
  }

}

export default WidgetUtilsSpacer;
