import React, { PureComponent }  from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon/build/node/luxon';

import { nFormatter, formatNumber } from '../../core/utils';

import { ResponsiveContainer, XAxis, Tooltip, CartesianGrid, BarChart, Bar } from 'recharts';

import isScreen from '../../core/screenHelper';

class ChartMagic extends PureComponent {
  render() {
    const { chart, print, aspect } = this.props;

    if(print) {
      return (
        <div className="bg-white">{chart}</div>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" aspect={aspect} className="bg-white">
          {chart}
        </ResponsiveContainer>
      );
    }
  }
}

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value } = this.props;

    return (
      <text x={x} y={y} dx={9} dy={-4} fill='#777' fontSize={9} textAnchor="middle">
        {nFormatter(value)}
      </text>
    );
  }
}

class CBar extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    data: PropTypes.array,
    chart_width: PropTypes.number,
    chart_height: PropTypes.number,
    chart_aspect_width: PropTypes.number,
    chart_aspect_height: PropTypes.number,
    labels: PropTypes.bool,
    grid: PropTypes.bool,
    keys: PropTypes.array
  };

  static defaultProps = {
    print: false,
    chart_width: 350,
    chart_height: 170,
    chart_aspect_width: 6,
    chart_aspect_height: 1,
    labels: true,
    grid: true,
    keys: ['cy','lb','y2','y3']
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
      print: this.props.print,
      chart_width: this.props.chart_width,
      chart_height: this.props.chart_height,
      chart_aspect_width: this.props.chart_aspect_width,
      chart_aspect_height: this.props.chart_aspect_height,
      labels: this.props.labels,
      grid: this.props.grid
    }

    this._isMounted = false;
    this.chartDataFormat = this.chartDataFormat.bind(this);
    this.chartDateFormat = this.chartDateFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setChartAspect();
    // this.setChart();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(this.props.timespan !== prevProps.timespan) {
      this.setChart();
    }
  }

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  chartDataFormat(value) {
    return formatNumber(this.props.format,value);
  };

  chartDateFormat(x) {
    return DateTime.fromISO(x).toFormat('MM-dd');
  };

  setChartAspect() {
    if(this.props.print) {
      this.setState({
        ...this.state,
        chart_width: 450,
        chart_height: 170,
        chart_aspect_width: 2,
        chart_aspect_height: 2
      });
    } else {
      if((isScreen('xs') || isScreen('sm'))) {
        this.setState({
          ...this.state,
          chart_width: 350,
          chart_height: 150,
          chart_aspect_width: 2,
          chart_aspect_height: 1
        });
      } else {
        if((isScreen('md') || isScreen('lg'))) {
          this.setState({
            ...this.state,
            chart_width: 350,
            chart_height: 150,
            chart_aspect_width: 2,
            chart_aspect_height: 2
          });
        } else {
          this.setState({
            ...this.state,
            chart_width: 350,
            chart_height: 150,
            chart_aspect_width: this.props.chart_aspect_width,
            chart_aspect_height: this.props.chart_aspect_height
          });
        }
      }
    }
  }

  setChart() {

    var chartData = [];

    this.props.results.timespans[this.props.timespan].forEach(function(block, i) {
      
      console.log(this.props.keys);

      var d = {
        name: block.dates.end
      }

      if(this.props.parent) {

        d[this.props.keys[0]] = block.years[0][this.props.parent][this.props.field];
        if(block.years[0].lookback) {
          d[this.props.keys[1]] = block.years[0].lookback[this.props.parent][this.props.field];
        }
        if(block.years[1]) {
          d[this.props.keys[2]] = block.years[1][this.props.parent][this.props.field];
        }

      } else {

        d[this.props.keys[0]] = block.years[0][this.props.field];
        if(block.years[0].lookback) {
          d[this.props.keys[1]] = block.years[0].lookback[this.props.field];
        }
        if(block.years[1]) {
          d[this.props.keys[2]] = block.years[1][this.props.field];
        }
        if(block.years[2]) {
          d[this.props.keys[3]] = block.years[2][this.props.field];
        }

      }
      
      chartData.push(d);

    }.bind(this));

    console.log(chartData)

    let max = 1;
    let min = 1;

    this.setState({
      ...this.state,
      chart: {
        data: chartData,
        properties: {
          max: max,
          min: min
        }
      }
    });
  }

  render() {

    return (
      <div className="bg-white">
        {this.props.data ? (
          <ChartMagic
            chart={
              <BarChart width={this.props.width/2} height={this.props.height/2} data={this.props.data}>
                <XAxis dataKey="name" tick={{fontSize: 10}} />
                {this.state.labels ? (
                  <Tooltip />
                ) : null }
                {this.state.grid ? (
                  <CartesianGrid strokeDasharray="3 3" fill="#fafafa" />
                ) : null }
                <Bar dataKey={this.props.keys[0]} fill="#82ca9d" label={this.state.labels ? (<CustomizedLabel />) : null} />
                {this.props.data[0].lb > -1 ? (
                  <Bar dataKey={this.props.keys[1]} fill="#999" label={this.state.labels ? (<CustomizedLabel />) : null} />
                ) : null}
                {this.props.data[0].y2 > -1 ? (
                  <Bar dataKey={this.props.keys[2]} fill="#0087a9" label={this.state.labels ? (<CustomizedLabel />) : null} />
                ) : null}
                {this.props.data[0].y3 > -1 ? (
                  <Bar dataKey={this.props.keys[3]} fill="#ffc647" label={this.state.labels ? (<CustomizedLabel />) : null} />
                ) : null}
              </BarChart>
            }
            print={this.props.print}
            aspect={this.state.chart_aspect_width/this.state.chart_aspect_height}
          />
        ) : null}
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(CBar));
